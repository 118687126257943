import * as rangy from 'rangy';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-selectionsaverestore';
import { Tag, TagCategory, MarkerCategory, TagCategoryType } from './types';
import { sanitize } from './string-util';
import { NEWLINE, ZWSP } from './constants';
import {
  TagDisplayMode,
  TagDisplayModeType,
  XliffUnitStateQualifierType,
  XliffUnitStateType,
} from '../../libs/types';

export const htmlToPlainText = (html: string): string => {
  const div = document.createElement('div');
  div.innerHTML = sanitize(html);
  const text = [...div.childNodes]
    .map((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent ?? '';
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const elem = node as HTMLElement;
        if (!elem.dataset) {
          return '';
        }
        if (elem.dataset.type === MarkerCategory.NewlineMarker) {
          return NEWLINE;
        }
      }
      return '';
    })
    .join('');
  return text;
};

export const createNewlineMarker = (): HTMLElement => {
  const tagName = 'span';
  const span = document.createElement(tagName);
  span.dataset.type = MarkerCategory.NewlineMarker;
  span.classList.add(MarkerCategory.NewlineMarker);
  // span.contentEditable = 'false';
  span.textContent = NEWLINE + ZWSP;
  return span;
};

export const createTagMarker = (
  value: Tag,
  tagDisplayMode: TagDisplayModeType = TagDisplayMode.ShowTagId
): HTMLElement => {
  const tagName = 'span';
  const span = document.createElement(tagName);
  span.textContent = tagDisplayMode === TagDisplayMode.ShowTagId ? value.id : value.content;
  span.dataset.id = value.id;
  span.dataset.type = value.type;
  span.dataset.content = value.content;
  span.classList.add('tag', value.type);
  span.contentEditable = 'false';

  const wrapper = document.createElement(tagName);
  wrapper.classList.add(MarkerCategory.TagWrapper);
  wrapper.dataset.type = MarkerCategory.TagWrapper;
  wrapper.append(span, ZWSP);
  return wrapper;
};

export const insertTextAtCursor = (text: string): void => {
  const sel = window.getSelection();
  if (sel && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    range.deleteContents();
    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    // Move caret to the end of the newly inserted text node
    range.setStart(textNode, textNode.length);
    range.setEnd(textNode, textNode.length);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

// TODO: たまたま動いているが、現状の想定しているツリー構造と乖離しているため修正する
/**
 * カーソル位置にノードを挿入します。
 * @param node 挿入するノード
 */
export const insertNodeAtCursor = (node: Node): void => {
  const sel = rangy.getSelection();
  const domUtil = (rangy as any).dom;
  if (sel && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    range.deleteContents();
    const editorRoot = getSegmentRoot(range.startContainer);
    // editorRoot(=TD)に含まれる、endContainerのoutermostなancestorを取得。
    const closestAncestor: HTMLElement = domUtil.getClosestAncestorIn(
      // range.startContainer,
      range.endContainer,
      editorRoot
    );
    // console.log('closest', closestAncestor);
    if (closestAncestor && isValidMarkerType(closestAncestor.dataset.type)) {
      // このルートを通ることはない？
      domUtil.insertAfter(node, closestAncestor);
      console.log('insertNodeAtCursor 1');
    } else if (closestAncestor && range.endContainer.nodeType === Node.TEXT_NODE) {
      // closestAncestorが<span react-dataroot>テキスト</span>のルート
      // 1. キャレット位置でテキストノードを分割
      // 2. 分割されたテキストノードをclosestAncestorの直後に移動
      // 3. 分割されたテキストノードの直前にnodeを追加
      const splitNode: Node = domUtil.splitDataNode(range.endContainer, range.endOffset);
      const refNode: Node = domUtil.insertAfter(splitNode.cloneNode(true), closestAncestor);
      domUtil.removeNode(splitNode);
      refNode.parentNode?.insertBefore(node, refNode);
      console.log('insertNodeAtCursor 2');
    } else {
      // range.endContainerがテキストノードでeditorRootの直下にあるルート
      // キャレット位置にそのままnodeを挿入
      range.insertNode(node);
      console.log('insertNodeAtCursor 3');
    }

    // Move caret to the end of the newly inserted text node
    range.setStartAfter(node);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

export const insertAfter = (newNode: Node, refNode: Node): Node => {
  const domUtil = (rangy as any).dom;
  return domUtil.insertAfter(newNode, refNode);
};

const tagTypes = Object.values(TagCategory);
export const isValidTagType = (type: string | null | undefined): boolean =>
  !!tagTypes.find((t) => t === type);

const markerTypes = Object.values(MarkerCategory);
export const isValidMarkerType = (type: string | null | undefined): boolean =>
  !!markerTypes.find((t) => t === type);

/**
 * 指定したノードを含むセグメントを返します。
 * @param node - 対象となるノード
 * @returns セグメントを表すノード
 */
export const getSegmentRoot = (node: Node): HTMLElement | null => {
  if (node.nodeName === 'TD') {
    return node as HTMLElement;
  }
  let parentNode = node.parentNode;
  while (parentNode && parentNode.nodeName !== 'TD') {
    parentNode = parentNode.parentNode;
  }
  return parentNode as HTMLElement;
};

/**
 * アクティブセグメントを返します。
 * @returns セグメントを表すノード
 */
export const getActiveSegment = (): HTMLElement | null => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const segmentRoot = getSegmentRoot(sel.getRangeAt(0).endContainer);
    return segmentRoot;
  }
  return null;
};

export const getActiveTransUnit = (): HTMLElement | null => {
  const activeSegment = getActiveSegment();
  if (!activeSegment) return null;
  return activeSegment.parentElement;
};

export const getPreviousTransUnit = (): HTMLElement | null => {
  const activeTransUnit = getActiveTransUnit();
  if (!activeTransUnit) return null;
  const previousTransUnit = activeTransUnit.previousElementSibling as HTMLElement;
  if (
    !previousTransUnit ||
    !previousTransUnit.dataset ||
    previousTransUnit.dataset.rowtype !== 'tu-row'
  )
    return null;
  return previousTransUnit;
};

export const getNextTransUnit = (): HTMLElement | null => {
  const activeTransUnit = getActiveTransUnit();
  if (!activeTransUnit) return null;
  const nextTransUnit = activeTransUnit.nextElementSibling as HTMLElement;
  if (!nextTransUnit || !nextTransUnit.dataset || nextTransUnit.dataset.rowtype !== 'tu-row')
    return null;
  return nextTransUnit;
};

export const getPreviousSegment = (): HTMLElement | null => {
  const cellname = getActiveCellName();
  if (!cellname) return null;

  const prevTransUnit = getPreviousTransUnit();
  if (!prevTransUnit) return null;
  const elems = [...prevTransUnit.childNodes].filter(
    (n) => n.nodeType === Node.ELEMENT_NODE
  ) as HTMLElement[];
  const targetSegment = elems.find((e) => e.dataset && e.dataset.cellname === cellname);
  return targetSegment || null;
};

export const getNextSegment = (): HTMLElement | null => {
  const cellname = getActiveCellName();
  if (!cellname) return null;

  const nextTransUnit = getNextTransUnit();
  if (!nextTransUnit) return null;
  const elems = [...nextTransUnit.childNodes].filter(
    (n) => n.nodeType === Node.ELEMENT_NODE
  ) as HTMLElement[];
  const targetSegment = elems.find((e) => e.dataset && e.dataset.cellname === cellname);
  return targetSegment || null;
};

export const getActiveCellName = (): string | null => {
  const activeSegment = getActiveSegment();
  if (!activeSegment) return null;
  return activeSegment.dataset?.cellname || null;
};

export const getCaretCharacterOffsetWithin = (element: Node): number => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }
  return 0;
};

/**
 * キャレット位置がセグメントの先頭にある場合trueを返します。
 * @returns キャレット位置がセグメントの先頭にある場合true
 */
export const atStartOfSegment = (): boolean => {
  const activeSegment = getActiveSegment();
  if (activeSegment) {
    const charPrev = getCharacterPrecedingCaret();
    const pos = getCaretCharacterOffsetWithin(activeSegment);
    if (charPrev === ZWSP && pos === 1) {
      console.log('atStart');
      return true;
    }
  }
  return false;
};

/**
 * キャレット位置がセグメントの末尾にある場合trueを返します。
 * @returns キャレット位置がセグメントの末尾にある場合true
 */
export const atEndOfSegment = (): boolean => {
  const activeSegment = getActiveSegment();
  if (activeSegment) {
    const charNext = getCharacterAfterCaret();
    const pos = getCaretCharacterOffsetWithin(activeSegment);
    const contentLen = activeSegment.textContent?.length || 0;
    if (charNext === '' && pos === contentLen) {
      console.log('atEnd');
      return true;
    }
  }
  return false;
};

export const extractTags = (html: string): Tag[] => {
  if (!html) {
    return [];
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const selector = `[data-type="${TagCategory.Begin}"], [data-type="${TagCategory.End}"], [data-type="${TagCategory.Empty}"]`;
  const spans = doc.querySelectorAll(selector);
  return ([...spans] as HTMLElement[]).map((x) => {
    if (!x.dataset) {
      throw new Error(`data attribute not found: ${x}`);
    }
    return {
      id: x.dataset.id as string,
      content: x.dataset.content as string,
      type: x.dataset.type as TagCategoryType,
    };
  });
};

export const computeTagCandidates = (srcTags: Tag[], tgtTags: Tag[]): Tag[] => {
  const tmp = tgtTags.slice();
  return srcTags.filter((st) => {
    const idx = tmp.findIndex(
      (tt) => tt.id === st.id && tt.content === st.content && tt.type === st.type
    );
    if (idx === -1) {
      return true;
    }
    tmp.splice(idx, 1);
    return false;
  });
};

export const getCharacterAfterCaret = (): string => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0).cloneRange();
    const editorRoot = getSegmentRoot(range.endContainer);
    if (editorRoot) {
      range.collapse(false);
      range.setEnd(editorRoot, editorRoot.childNodes.length);
      return range.toString().charAt(0);
    }
  }
  return '';
};

export const textNodesUnder = (node: Node): Node[] => {
  let n: Node | null;
  const textNodes: Node[] = [];
  const walk = document.createTreeWalker(node, NodeFilter.SHOW_TEXT);
  while ((n = walk.nextNode())) {
    textNodes.push(n);
  }
  return textNodes;
};

export const moveCaretBefore = (node: Node): void => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    range.setStartBefore(node);
    range.collapse();
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

export const moveCaretToStartOfSegment = (): void => {
  const sel = rangy.getSelection();
  const segment = getActiveSegment();
  if (sel && segment) {
    sel.selectAllChildren(segment);
    sel.collapseToStart();
  }
};

export const moveCaretToEndOfSegment = (): void => {
  const sel = rangy.getSelection();
  const segment = getActiveSegment();
  if (sel && segment) {
    sel.selectAllChildren(segment);
    sel.collapseToEnd();
  }
};

export const saveCurrentSelection = (tuid: string): unknown => {
  const activeTuId = getActiveTransUnitId();
  if (activeTuId !== tuid) {
    // console.log('Skipped saveCurrentSelection');
    return null;
  }
  const savedSell = (rangy as any).saveSelection();
  return savedSell;
};

export const restoreSelection = (selection: unknown): void => {
  (rangy as any).restoreSelection(selection, true);
};

export const getActiveSegmentHtml = (): string => {
  const activeSegment = getActiveSegment();
  return activeSegment?.innerHTML ?? '';
};

export const getActiveSourceHtml = (): string => {
  const activeTransUnit = getActiveTransUnit();
  if (!activeTransUnit) return '';
  const segment = activeTransUnit.querySelector('[data-celltype="source"]');
  return segment?.innerHTML ?? '';
};

export const getActiveTargetHtml = (): string => {
  const activeTransUnit = getActiveTransUnit();
  if (!activeTransUnit) return '';
  const segment = activeTransUnit.querySelector('[data-celltype="target"]');
  return segment?.innerHTML ?? '';
};

export const inSource = (): boolean => {
  const activeSegment = getActiveSegment();
  if (activeSegment) {
    return activeSegment.dataset?.celltype === 'source';
  }
  return false;
};

export const getCharacterPrecedingCaret = (): string => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0).cloneRange();
    const editorRoot = getSegmentRoot(range.endContainer);
    if (editorRoot) {
      range.collapse(true);
      range.setStart(editorRoot, 0);
      return range.toString().slice(-1);
    }
  }
  return '';
};

export const hasSelection = (): boolean => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    return range.toString().length > 0;
  }
  return false;
};

export const removeSelection = (): void => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    range.deleteContents();
  }
};

export const getSelectedPlainContent = (): string => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    return htmlToPlainText(range.toHtml());
  }
  return '';
};

export const moveCaret = (count: number, ignoreCharacters = ''): number => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount) {
    if (atStartOfSegment() && count < 0) {
      return 0;
    }
    if (atEndOfSegment() && count > 0) {
      return 0;
    }
    const moved = sel.move('character', count, {
      characterOptions: {
        ignoreCharacters,
      },
    });
    // console.log('moveCaret', moved);
    return moved;
  }
  return 0;
};

export const getCaretCordinates = (): { x: number; y: number } | null => {
  const sel = window.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    return { x: rect.x, y: rect.y };
  }
  return null;
};

const moveToSegment = (segment: HTMLElement | null): void => {
  if (!segment) return;
  const range = rangy.createRange();
  range.selectNodeContents(segment);
  range.collapse(true);
  const sel = rangy.getSelection();
  sel?.removeAllRanges();
  sel?.addRange(range);
  segment.scrollIntoView({ block: 'center' });
};

export const moveToSegmentByPosition = (position: number): void => {
  const segment = getTargetElementByPosition(position);
  moveToSegment(segment);
};

export const moveToSegmentById = (id: string): void => {
  const segment = getTargetElement(id);
  moveToSegment(segment);
};

export const moveToPreviousSegment = (): void => {
  const prevSegment = getPreviousSegment();
  moveToSegment(prevSegment);
};

export const moveToNextSegment = (): void => {
  const nextSegment = getNextSegment();
  moveToSegment(nextSegment);
};

export const getActiveTransUnitId = (): string | null => {
  const activeTransUnit = getActiveTransUnit();
  const id = activeTransUnit?.dataset.id;
  return id ?? null;
};

type TransUnitAttributes = {
  id: string;
  state: XliffUnitStateType;
  stateQualifier: XliffUnitStateQualifierType;
  isLocked: boolean;
  position: number;
};

export const getTransUnitAttributes = (unitId: string): TransUnitAttributes | null => {
  const elem = getTransUnitElement(unitId);
  if (!elem) return null;
  const id = elem.dataset.id as string;
  const position = Number(elem.dataset.position);
  const state = elem.dataset.tuState as XliffUnitStateType;
  const isLocked = elem.dataset.isLocked === 'true';
  const stateQualifier = elem.dataset.tuStateQualifier as XliffUnitStateQualifierType;

  return {
    id,
    position,
    state,
    isLocked,
    stateQualifier,
  };
};

export const getActiveTransUnitAttributes = (): TransUnitAttributes | null => {
  const tuid = getActiveTransUnitId();
  if (!tuid) return null;
  return getTransUnitAttributes(tuid);
};

export const getTransUnitElement = (unitId: string): HTMLElement | null => {
  return document.querySelector(`[data-id="${unitId}"]`);
};

export const getSourceElement = (unitId: string): HTMLElement | null => {
  return document.querySelector(`[data-id="${unitId}"] > [data-celltype="source"]`);
};

export const getTargetElement = (unitId: string): HTMLElement | null => {
  return document.querySelector(`[data-id="${unitId}"] > [data-celltype="target"]`);
};

export const getSourceElementByPosition = (position: number): HTMLElement | null => {
  return document.querySelector(`[data-position="${position}"] > [data-celltype="source"]`);
};

export const getTargetElementByPosition = (position: number): HTMLElement | null => {
  return document.querySelector(`[data-position="${position}"] > [data-celltype="target"]`);
};

export const getSegmentHtml = (unitId: string, source?: boolean): string => {
  let element;
  if (source) {
    element = getSourceElement(unitId);
  } else {
    element = getTargetElement(unitId);
  }
  return element?.innerHTML ?? '';
};
