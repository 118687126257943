import { createContext, useContext } from 'react';
import { configure } from 'axios-hooks';
import Axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import Qs from 'qs';
import { Auth } from 'aws-amplify';
import { config, STAGE } from '../config';

const axios = Axios.create({
  baseURL: config[STAGE].endpoint,
});

const isFile = (data: any): data is File =>
  data.size !== undefined && data.name !== undefined && data.type !== undefined;

const isUploadToS3 = (config: AxiosRequestConfig): boolean =>
  config.method === 'put' && !!config.url && /\.s3\./.test(config.url) && isFile(config.data);

const paramsSerializer = (params: unknown) => {
  return Qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: true,
  });
};

axios.interceptors.request.use(async (config) => {
  // s3へのPUTは Authorizationヘッダーを受け付けないらしい
  // https://stackoverflow.com/a/53031218
  if (isUploadToS3(config)) {
    delete config.headers.Authorization;
    return config;
  }
  const session = await Auth.currentSession();
  if (session) {
    config.headers.Authorization = session.getIdToken().getJwtToken();
  }

  config.paramsSerializer = paramsSerializer;
  return config;
});

configure({ axios });

const AxiosInstanceContext = createContext<AxiosInstance>(axios);

export const AxiosInstanceProvider = AxiosInstanceContext.Provider;

export const AciosInstanceConsumer = AxiosInstanceContext.Consumer;

export const useAxiosInstance = (): AxiosInstance => {
  return useContext(AxiosInstanceContext);
};
