import { useRef } from 'react';
import { UndoableEdit, UndoManager } from 'typed-undo';
import { useDebouncedCallback } from 'use-debounce';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';

class UndoableValueChange extends UndoableEdit {
  private readonly oldValue: string;
  private newValue: string;
  private readonly applyValue: (value: string) => void;

  public constructor(oldValue: string, newValue: string, applyValue: (value: string) => void) {
    super();
    this.oldValue = oldValue;
    this.newValue = newValue;
    this.applyValue = applyValue;
  }

  public undo(): void {
    this.applyValue(this.oldValue);
  }

  public redo(): void {
    this.applyValue(this.newValue);
  }

  public isSignificant(): boolean {
    return this.oldValue !== this.newValue;
  }

  public merge(edit: UndoableEdit): boolean {
    // if (edit instanceof UndoableValueChange) {
    //   this.newValue = edit.newValue;
    //   return true;
    // }
    return false;
  }
}

type UseUndoManager = {
  ({
    limit,
    applyUndo,
    initialValue,
  }: {
    limit?: number;
    applyUndo: (value: string) => void;
    initialValue: string;
  }): {
    undoManager: UndoManager;
    addUndo: DebouncedState<(newValue: string) => void>;
  };
};

export const useUndoManager: UseUndoManager = ({ limit, applyUndo, initialValue }) => {
  const undoManager = useRef(new UndoManager(limit));
  const prevValue = useRef<string>(initialValue);

  const addUndo = useDebouncedCallback(
    (newValue: string) => {
      if (prevValue.current !== newValue) {
        undoManager.current.add(new UndoableValueChange(prevValue.current, newValue, applyUndo));
        prevValue.current = newValue;
        console.log(undoManager.current);
      }
    },
    1500,
    { leading: false }
  );

  return {
    undoManager: undoManager.current,
    addUndo,
  };
};
