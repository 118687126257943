import useAxios from 'axios-hooks';
import { useCallback, useRef, useState } from 'react';
import {
  XliffJob,
  XliffUnit,
  XliffUnitStateQualifier,
  XliffUnitStateQualifierType,
  XliffUnitStateType,
} from '../types';
import { decodeQuotes } from '../../Segment/libs/string-util';
import { escapeXliff } from '../../Segment/libs/xliff-util';

export type FetchPrev = ({
  srcMasked,
  tgtMasked,
  ignoreCase,
}?: {
  srcMasked?: string;
  tgtMasked?: string;
  ignoreCase?: boolean;
}) => void;

export type FetchNext = ({
  srcMasked,
  tgtMasked,
  ignoreCase,
}?: {
  srcMasked?: string;
  tgtMasked?: string;
  ignoreCase?: boolean;
}) => void;

type UseXliffUnits = {
  ({ job, limit }: { job?: XliffJob; limit: number }): {
    transUnits: XliffUnit[];
    totalUnitsCount: number;
    isLoadingPrev: boolean;
    isLoadingNext: boolean;
    isLoadingTotalCount: boolean;
    fetchPrev: FetchPrev;
    fetchNext: FetchNext;
    fetchCount: FetchNext;
    hasPrev: boolean;
    hasNext: boolean;
    resetFetchData: () => void;
    resetDep: number;
    fetchNeighboringUnits: (id?: string, position?: number) => Promise<boolean>;
    isFetchingNeighboringUnits: boolean;
  };
};

type RawTransUnit = {
  _id: string;
  srcXliff: string;
  srcXliffNormalized: string;
  tgtXliff: string;
  srcLang: string;
  tgtLang: string;
  state: XliffUnitStateType;
  stateQualifier: XliffUnitStateQualifierType;
  translate?: 'yes' | 'no';
  level: string;
  properties?: {
    series_code: string | null;
    item_name: string | null;
    category_code: string | null;
    brand_code: string | null;
  };
};

const uniqById = (transUnits: XliffUnit[]) => {
  const ids: string[] = [];
  const newUnits = [];
  for (const tu of transUnits) {
    if (!ids.includes(tu._id)) {
      newUnits.push(tu);
      ids.push(tu._id);
    }
  }
  return newUnits;
};

const ownerGroupFA = [
  'a690d540-0a16-11eb-b6e5-47749b96b2de', // DEV
  'a44ec770-0b69-11eb-91c3-65bb78745678', // STG
  'aaa3d500-09e0-11eb-a9ac-515ace5d60c3', // PROD
  'FA',
];

const isLocked = (tu: RawTransUnit, job: XliffJob) => {
  // OnwerGroupがFAの場合は全てLock解除
  if (ownerGroupFA.includes(job.project.ownerGroupId)) return false;

  return tu.translate !== 'yes' || tu.stateQualifier === XliffUnitStateQualifier.XNonTranslatable;
};

const transformResponse = (
  transUnits: RawTransUnit[] | undefined,
  skip: number,
  job: XliffJob
): XliffUnit[] => {
  if (!transUnits) return [];
  return transUnits.map((tu, idx) => ({
    position: skip + idx + 1,
    _id: tu._id,
    // この時点で<>&をエスケープしておかないとMutationObserverの挙動がおかしくなる・・・？
    source: {
      text: decodeQuotes(escapeXliff(tu.srcXliff)),
      textNormalized: decodeQuotes(escapeXliff(tu.srcXliffNormalized)),
      langCode: tu.srcLang,
    },
    target: {
      text: decodeQuotes(escapeXliff(tu.tgtXliff)),
      langCode: tu.tgtLang,
    },
    state: tu.state,
    stateQualifier: tu.stateQualifier,
    translate: tu.translate === 'yes',
    isLocked: isLocked(tu, job),
    level: tu.level,
    properties: {
      seriesCode: tu.properties?.series_code ?? '',
      itemName: tu.properties?.item_name ?? '',
      categoryCode: tu.properties?.category_code ?? '',
      brandCode: tu.properties?.brand_code ?? '',
    },
  }));
};

const MAX_UNITS = 200;

export const useXliffUnits: UseXliffUnits = ({ job, limit }) => {
  const firstPosition = useRef(0);
  const lastPosition = useRef(0);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);
  const [transUnits, setTransUnits] = useState<XliffUnit[]>([]);
  const [isLoadingPrev, setIsLoadingPrev] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [totalUnitsCount, setTotalUnitsCount] = useState(0);
  const resetDep = useRef(0);

  const resetFetchData = useCallback(() => {
    setHasNext(true);
    setHasPrev(false);
    setIsLoadingNext(false);
    setIsLoadingPrev(false);
    firstPosition.current = 0;
    lastPosition.current = 0;
    setTransUnits([]);
    firstPosition.current = 0;
    lastPosition.current = 0;
    resetDep.current++;
  }, []);

  const [, executeFetchUnits] = useAxios<RawTransUnit[]>(
    {
      url: `units`,
    },
    { manual: true }
  );

  const [{ loading: isLoadingTotalCount }, executeFetchTotalCount] = useAxios<{ count: number }>(
    {
      url: `units/count`,
    },
    {
      manual: true,
    }
  );

  const [{ loading: isFetchingNeighboringUnits }, executeFetchNeighboringUnits] = useAxios<{
    units: RawTransUnit[];
    startOffset: number;
    hasNext: boolean;
  }>(
    {
      url: 'units/neighbors',
    },
    { manual: true }
  );

  const fetchPrev = useCallback<FetchPrev>(
    ({ srcMasked, tgtMasked, ignoreCase } = {}) => {
      if (!job) return;
      if (firstPosition.current > 0) {
        firstPosition.current -= limit;
        firstPosition.current = Math.max(firstPosition.current, 0);
      } else {
        setHasPrev(false);
        return;
      }

      const run = async () => {
        const firstPos = firstPosition.current;
        setIsLoadingPrev(true);
        let result: { data: RawTransUnit[] } = {
          data: [],
        };
        try {
          result = await executeFetchUnits({
            params: {
              jobId: job._id,
              srcMasked,
              tgtMasked,
              options: {
                limit,
                skip: firstPos,
                ignoreCase,
              },
            },
          });
        } catch (error) {
          console.log(error);
        }
        const { data } = result;

        // 多めに取得する場合があるので、ここで_idでユニークしておく
        setTransUnits((prevUnits) => {
          const newUnits = uniqById([...transformResponse(data, firstPos, job), ...prevUnits]);
          if (newUnits.length > MAX_UNITS) {
            newUnits.splice(MAX_UNITS);
          }
          if (newUnits.length > 0) {
            firstPosition.current = newUnits[0].position - 1;
            lastPosition.current = newUnits[newUnits.length - 1].position;
          }
          return newUnits;
        });

        setHasNext(true);
        setIsLoadingPrev(false);
      };
      run();
    },
    // hasPrevはdependenciesに入れずにすむように実装する
    [executeFetchUnits, job, limit]
  );

  const fetchNext = useCallback<FetchNext>(
    ({ srcMasked, tgtMasked, ignoreCase } = {}) => {
      if (!job) return;
      const run = async () => {
        const lastPos = lastPosition.current;
        setIsLoadingNext(true);
        let result: { data: RawTransUnit[] } = {
          data: [],
        };
        try {
          result = await executeFetchUnits({
            params: {
              jobId: job._id,
              srcMasked,
              tgtMasked,
              options: {
                limit,
                skip: lastPos,
                ignoreCase,
              },
            },
          });
        } catch (error) {
          console.log(error);
        }

        const { data } = result;

        setTransUnits((prevUnits) => {
          // 多めに取得する場合があるので、ここで_idでユニークしておく
          const newUnits = uniqById([...prevUnits, ...transformResponse(data, lastPos, job)]);
          if (data.length === limit) {
            setHasNext(true);
            if (newUnits.length > MAX_UNITS) {
              newUnits.splice(0, data.length);
              setHasPrev(true);
            }
          } else {
            setHasNext(false);
          }
          if (newUnits.length > 0) {
            // positionはtransformResponse内で設定した連番 (serverから返された値ではない)
            lastPosition.current = newUnits[newUnits.length - 1].position;
            firstPosition.current = newUnits[0].position - 1;
          }
          return newUnits;
        });
        setIsLoadingNext(false);
      };
      run();
    },
    [executeFetchUnits, job, limit]
  );

  const fetchNeighboringUnits = useCallback(
    async (id?: string, position?: number) => {
      if (!transUnits || !transUnits.length || !job || (!id && !position)) {
        return false;
      }
      if (id && transUnits.find((tu) => tu._id === id)) {
        return true;
      }
      if (position && transUnits.find((tu) => tu.position === position)) {
        return true;
      }
      setIsLoadingNext(true);
      try {
        const { data } = await executeFetchNeighboringUnits({
          params: {
            jobId: job._id,
            unitId: id,
            unitPosition: position,
          },
        });

        setHasNext(data.hasNext);
        setHasPrev(data.startOffset > 0);
        setTransUnits(() => {
          const newUnits = transformResponse(data.units, data.startOffset, job);
          if (newUnits.length > 0) {
            lastPosition.current = newUnits[newUnits.length - 1].position;
            firstPosition.current = newUnits[0].position - 1;
          }
          return newUnits;
        });
        return true;
      } catch (error) {
        console.log(error);
        return false;
      } finally {
        setIsLoadingNext(false);
      }
    },
    [executeFetchNeighboringUnits, job, transUnits]
  );

  const fetchCount = useCallback<FetchNext>(
    ({ srcMasked, tgtMasked, ignoreCase } = {}) => {
      if (!job) return;
      const run = async () => {
        try {
          // const countData = await executeFetchCount();
          const totalCountData = await executeFetchTotalCount({
            params: {
              jobId: job._id,
              srcMasked,
              tgtMasked,
              options: {
                ignoreCase,
              },
            },
          });
          setTotalUnitsCount(totalCountData.data.count);
        } catch (error) {
          console.log(error);
          setTotalUnitsCount(0);
        }
      };
      run();
    },
    [executeFetchTotalCount, job]
  );

  return {
    transUnits,
    totalUnitsCount,
    isLoadingPrev,
    isLoadingNext,
    isLoadingTotalCount,
    fetchPrev,
    fetchNext,
    fetchCount,
    hasPrev,
    hasNext,
    resetFetchData,
    resetDep: resetDep.current,
    fetchNeighboringUnits,
    isFetchingNeighboringUnits,
  };
};
