import { createContext, useMemo, useReducer, useContext, useRef } from 'react';

export type SearchConditions = {
  [key: string]: any;
};
export const initialState: SearchConditions = {};

export type Action = { type: 'UPDATE'; payload: SearchConditions } | { type: 'RESET' };

export const reducer = (state: SearchConditions, action: Action): SearchConditions => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'RESET': {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export type UpdateSearchConditions = (payload: SearchConditions) => void;
export type ResetSearchConditions = () => void;
type ContextType = {
  searchConditions: SearchConditions;
  updateSearchConditions: UpdateSearchConditions;
  resetSearchConditions: ResetSearchConditions;
  resetDep: boolean;
};

export const SearchConditionsContext = createContext<ContextType | null>(null);

type Props = {
  intialSearchConditions?: SearchConditions;
};

export const SearchConditionsContextProvider: React.FC<Props> = ({
  intialSearchConditions = initialState,
  ...otherProps
}) => {
  const [searchConditions, dispatch] = useReducer(reducer, intialSearchConditions);
  const resetRef = useRef(true);

  const updateSearchConditions: UpdateSearchConditions = (payload) => {
    resetRef.current = false;
    dispatch({ type: 'UPDATE', payload });
  };
  const resetSearchConditions: ResetSearchConditions = () => {
    dispatch({ type: 'RESET' });
    resetRef.current = true;
  };

  const value = useMemo(
    () => ({
      searchConditions,
      resetDep: resetRef.current,
      updateSearchConditions,
      resetSearchConditions,
    }),
    [searchConditions]
  );

  return <SearchConditionsContext.Provider value={value} {...otherProps} />;
};

export const useSearchConditions = (): ContextType => {
  const context = useContext(SearchConditionsContext);

  if (typeof context === 'undefined') {
    throw new Error('useSearchConditions must be used within SearchConditionsContext');
  }

  return context as ContextType;
};
