import { useCallback } from 'react';
import { createContext, useMemo, useReducer, useContext } from 'react';
import { EditorOptions, TagDisplayMode } from '../types';

export const initialState: EditorOptions = {
  isReadOnly: false,
  isSourceEditable: false,
  tagDisplayMode: TagDisplayMode.ShowTagId,
};

export type Action = { type: 'UPDATE'; payload: Partial<EditorOptions> } | { type: 'RESET' };

export const reducer = (state: EditorOptions, action: Action): EditorOptions => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'RESET': {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export type UpdateEditorOptions = (payload: Partial<EditorOptions>) => void;
export type ResetEditorOptions = () => void;
type ContextType = {
  editorOptions: EditorOptions;
  updateEditorOptions: UpdateEditorOptions;
  resetEditorOptions: ResetEditorOptions;
};

export const EditorOptionsContext = createContext<ContextType | null>(null);

type Props = {
  intialEditorOptions?: EditorOptions;
};

export const EditorOptionsContextProvider: React.FC<Props> = ({
  intialEditorOptions = initialState,
  ...otherProps
}) => {
  const [editorOptions, dispatch] = useReducer(reducer, intialEditorOptions);

  const updateEditorOptions: UpdateEditorOptions = useCallback(
    (payload) => dispatch({ type: 'UPDATE', payload }),
    []
  );
  const resetEditorOptions: ResetEditorOptions = useCallback(() => dispatch({ type: 'RESET' }), []);

  const value = useMemo(
    () => ({
      editorOptions,
      updateEditorOptions,
      resetEditorOptions,
    }),
    [editorOptions, resetEditorOptions, updateEditorOptions]
  );

  return <EditorOptionsContext.Provider value={value} {...otherProps} />;
};

export const useEditorOptions = (): ContextType => {
  const context = useContext(EditorOptionsContext);

  if (typeof context === 'undefined') {
    throw new Error('useEditorOptions must be used within EditorOptionsContext');
  }

  return context as ContextType;
};
