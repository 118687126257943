import { createContext, useMemo, useReducer, useContext } from 'react';
import { FieldDefinition } from '../types';

export const initialState: FieldDefinition[] = [];

export type Action = { type: 'UPDATE'; payload: FieldDefinition[] } | { type: 'RESET' };

export const reducer = (state: FieldDefinition[], action: Action): FieldDefinition[] => {
  switch (action.type) {
    case 'UPDATE': {
      return [...state, ...action.payload];
    }
    case 'RESET': {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
};

type UpdateFieldDefinitions = (payload: FieldDefinition[]) => void;
type ResetFieldDefinitions = () => void;
type ContextType = {
  fieldDefinitions: FieldDefinition[];
  updateFieldDefinitions: UpdateFieldDefinitions;
  resetFieldDefinitions: ResetFieldDefinitions;
};

export const FieldDefinitionsContext = createContext<ContextType | null>(null);

type Props = {
  initialFieldDefinitions?: FieldDefinition[];
};

export const FieldDefinitionsContextProvider: React.FC<Props> = ({
  initialFieldDefinitions = initialState,
  ...otherProps
}) => {
  const [fieldDefinitions, dispatch] = useReducer(reducer, initialFieldDefinitions);

  const updateFieldDefinitions: UpdateFieldDefinitions = (payload) =>
    dispatch({ type: 'UPDATE', payload });
  const resetFieldDefinitions: ResetFieldDefinitions = () => dispatch({ type: 'RESET' });

  const value = useMemo(
    () => ({
      fieldDefinitions,
      updateFieldDefinitions,
      resetFieldDefinitions,
    }),
    [fieldDefinitions]
  );

  return <FieldDefinitionsContext.Provider value={value} {...otherProps} />;
};

export const useFieldDefinitions = (): ContextType => {
  const context = useContext(FieldDefinitionsContext);

  if (typeof context === 'undefined') {
    throw new Error('useFieldDefinitions must be used within FieldDefnitionsContext');
  }

  return context as ContextType;
};
