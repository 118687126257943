import React from 'react';
import './StatusBar.css';

type StatusBarItem = {
  label: string;
  value: string;
};

export type ContainerProps = {
  items?: StatusBarItem[];
};

export type Props = ContainerProps;

export const Component: React.FC<Props> = ({ items = [] }) => {
  return (
    <div className="status-bar menubar-base">
      {items.map((item, i) => (
        <div className="status-bar-item" key={i}>
          <span className="status-bar-item-label">{item.label}</span>:&nbsp;
          <span>{item.value}</span>
        </div>
      ))}
    </div>
  );
};

const Container: React.FC<ContainerProps> = ({ items }) => {
  return <Component items={items} />;
};

Container.displayName = 'StatusBar';
export default Container;
