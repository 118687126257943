import { XliffUnit, XliffUnitState } from '../libs/types';

export const StateIcon = (tu: XliffUnit): React.ReactElement => {
  let iconClass;
  const state = tu.state;
  switch (state) {
    case XliffUnitState.Translated:
      iconClass = 'pi-check';
      break;
    default:
      iconClass = 'pi-file';
      break;
  }
  return <i className={`pi ${iconClass}`}></i>;
};

export const LockedIcon = (tu: XliffUnit): React.ReactElement | null => {
  return tu.isLocked ? <i className="pi pi-lock"></i> : null;
};

export const RepetitionIcon = (tu: XliffUnit): React.ReactElement | null => {
  // if (tu.repetitions && tu.repetitions.length > 0) {
  //   return <i className="pi pi-replay"></i>;
  // }
  // if (tu.repetitionOf) {
  //   return <i className="pi pi-replay"></i>;
  // }
  return null;
};
