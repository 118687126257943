import useAxios from 'axios-hooks';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useRef } from 'react';
import { diffChars } from 'diff';
import { Messages } from '../libs/messages';
import { EventId, TMEntry, XliffUnit } from '../libs/types';
import CatMetadata from '../CatMetadata';

import './TMLookupPanel.css';
import { useMemo } from 'react';
import { useCatResults } from '../libs/hooks/useCatResults';
import { useEditorEvent } from '../libs/hooks/useEditorEvent';
import { useState } from 'react';
import { extractMaskId, maskRegex, simplifyMasks } from '../Segment/libs/string-util';
import { unxliffizePh } from '../Segment/libs/xliff-util';
import { useCategoryOptions } from '../libs/hooks/useCategoryOptions';

const toRuns = (maskedString: string) => {
  const runs = maskedString.split(maskRegex).map((substr, idx) => {
    if (maskRegex.test(substr)) {
      return (
        <span className="tag tag-empty" key={idx}>
          {extractMaskId(substr)}
        </span>
      );
    }
    return substr;
  });
  return runs;
};

export type ContainerProps = {
  activeTransUnit?: XliffUnit | null;
  categoryCode?: string;
  ownerGroupId?: string;
  isExpanded: boolean;
};

export type Props = Omit<ContainerProps, 'category' | 'ownerGroupId'> & {
  tmEntries?: TMEntry[];
  loading: boolean;
};
export const Component: React.FC<Props> = ({ activeTransUnit, tmEntries, loading, isExpanded }) => {
  const [currentTMEntryIdx, setCurrentTMEntryIdx] = useState(0);
  const { catResults, updateCatResults } = useCatResults();
  const { editorEvent, raiseEditorEvent } = useEditorEvent();
  const tmLookupGridRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    // 矢印キーで検索結果の選択を上下に移動したとき、常に表示されるようにスクロールして調整
    if (!tmLookupGridRef.current) return;
    const selectedRow = tmLookupGridRef.current.querySelector(
      `tr[data-id="${catResults.selectedTMEntry?._id}"]`
    );
    selectedRow?.scrollIntoView({ block: 'nearest' });
  }, [catResults.selectedTMEntry]);

  useEffect(() => {
    // アクティブTUが移動したらインデックスリセット
    setCurrentTMEntryIdx(0);
  }, [activeTransUnit?._id]);

  useEffect(() => {
    if (!tmEntries) return;
    // インデックスの変更に応じてTMエントリを選択
    updateCatResults({ selectedTMEntry: tmEntries[currentTMEntryIdx] });
  }, [currentTMEntryIdx, tmEntries, updateCatResults]);

  useEffect(() => {
    // AppMenuBarまたはキーボードショートカットからのイベントを処理
    if (!editorEvent || !tmEntries) return;
    if (editorEvent.eventId === EventId.SelectPrevMatch) {
      // インデックスをデクリメント
      setCurrentTMEntryIdx((prev) => {
        if (prev === 0) return prev;
        return prev - 1;
      });
    } else if (editorEvent.eventId === EventId.SelectNextMatch) {
      // インデックスをインクリメント
      setCurrentTMEntryIdx((prev) => {
        if (prev === tmEntries.length - 1) return prev;
        return prev + 1;
      });
    }
  }, [editorEvent, tmEntries]);

  const changes = useMemo(() => {
    if (!activeTransUnit || !catResults.selectedTMEntry || loading) return [];
    const tmxMasked = catResults.selectedTMEntry.srcMasked ?? '';
    const xliffMasked = unxliffizePh(activeTransUnit.source.textNormalized ?? '');
    const tmxSimplified = simplifyMasks(tmxMasked);
    const xliffSimplified = simplifyMasks(xliffMasked);
    return diffChars(tmxSimplified, xliffSimplified);
  }, [activeTransUnit, catResults.selectedTMEntry, loading]);

  const handleTMEntryClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!tmEntries) return;
    const elem = e.target as HTMLElement;
    const tmEntryId = elem.closest('tr')?.getAttribute('data-id');
    if (tmEntryId) {
      const foundIdx = tmEntries.findIndex((e) => e._id === tmEntryId);
      setCurrentTMEntryIdx(foundIdx >= 0 ? foundIdx : 0);
    }
  };

  const handleTMEntryDoubleClick = () => {
    raiseEditorEvent({
      eventId: EventId.ApplySelectedMatch,
      payload: null,
    });
  };

  if (!activeTransUnit) {
    return null;
  }
  const matchScoreBgColorClasses: { [key: string]: string } = {
    '101': 'tm-lookup-match-score-perfect',
    '100': 'tm-lookup-match-score-exact',
  };

  return (
    <Splitter
      style={isExpanded ? undefined : { display: 'none' }}
      className="tm-lookup-panel panel-base"
      layout="vertical"
      gutterSize={6}
    >
      <SplitterPanel className="tm-lookup-grid-wrapper">
        {/* 
          ローディング中: ProgressSpinnerとメッセージを表示
          tmEntriesがnull/undefined: 空のdivを表示
          tmEntriesが0件: メッセージを表示
          上記以外 (tmEntriesが1件以上): 検索結果をtableに表示
         */}
        {loading ? (
          <div className="tm-lookup-panel-progress-msg">
            <ProgressSpinner />
            {Messages.SearchingTMs}
          </div>
        ) : !tmEntries ? (
          <div className="tm-lookup-panel-progress-msg">{Messages.NoMatchesFound}</div>
        ) : tmEntries.length === 0 ? (
          <div className="tm-lookup-panel-progress-msg">{Messages.NoMatchesFound}</div>
        ) : (
          <table ref={tmLookupGridRef} className="tm-lookup-grid">
            <tbody>
              {tmEntries.map((tmEntry, index) => {
                const selectedClass =
                  tmEntry._id === catResults.selectedTMEntry?._id
                    ? 'tm-lookup-selected-tm-entry'
                    : '';
                const srcRuns = toRuns(tmEntry.srcMasked);
                const tgtRuns = toRuns(tmEntry.tgtMasked);
                return (
                  <tr
                    key={tmEntry._id}
                    className={selectedClass}
                    data-id={tmEntry._id}
                    onClick={handleTMEntryClick}
                    onDoubleClick={handleTMEntryDoubleClick}
                  >
                    <th className={`tm-lookup-no`}>{index + 1}</th>
                    <td className="tm-lookup-src-tmx">{srcRuns}</td>
                    <td
                      className={`tm-lookup-match-score ${
                        matchScoreBgColorClasses[tmEntry.matchScore] ??
                        'tm-lookup-match-score-fuzzy'
                      }`}
                    >
                      {tmEntry.score}%
                    </td>
                    <td className="tm-lookup-tgt-tmx">{tgtRuns}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </SplitterPanel>
      <SplitterPanel className="tm-lookup-details p-flex-column">
        <div className="tm-lookup-diff">
          {/* <p>{catResults.selectedTMEntry?.srcTmx}</p>
          <hr /> */}
          <p>
            {changes.map((part, idx) => {
              if (part.added)
                return (
                  <span key={idx} className="tm-lookup-diff-added">
                    {part.value}
                  </span>
                );
              if (part.removed)
                return (
                  <span key={idx} className="tm-lookup-diff-removed">
                    {part.value}
                  </span>
                );
              return part.value;
            })}
          </p>
        </div>
        <CatMetadata tmEntry={catResults.selectedTMEntry} showPenalty={true} />
      </SplitterPanel>
    </Splitter>
  );
};

const Container: React.FC<ContainerProps> = ({
  activeTransUnit,
  ownerGroupId,
  categoryCode,
  isExpanded,
}) => {
  const categoryCode_ = activeTransUnit?.properties?.categoryCode ?? categoryCode ?? '';
  const { hasCategoryMapping, findCategoryMappingByCode, categoryOptions } = useCategoryOptions({
    ownerGroupId,
    lazyLoad: false,
    srcLang: activeTransUnit?.source.langCode,
    tgtLang: activeTransUnit?.target.langCode,
  });
  const [{ data: rawTmEntries, loading }, executePost] = useAxios<TMEntry[]>(
    {
      url: 'tmEntries/search',
      method: 'post',
    },
    {
      manual: true,
    }
  );

  const [tmEntries, setTmEntries] = useState<TMEntry[]>();

  useEffect(() => {
    if (isExpanded) {
      // ownerGroupId/srcLang/tgtLangに合致するレコードがカテゴリマッピング表に含まれる場合(hasCategoryMapping)、
      // categoryCodeからindexを検索しcategoryとして使用する。
      // そうでなければcategoryCode先頭1文字文字を使用する。
      let category: string;
      if (hasCategoryMapping) {
        const cm = findCategoryMappingByCode(categoryCode_);
        category = cm ? cm.index : '';
      } else {
        category = categoryCode_[0] ?? '';
      }
      const data = {
        unit: {
          srcXliff: activeTransUnit?.source.text,
          srcXliffNormalized: activeTransUnit?.source.textNormalized,
          level: activeTransUnit?.level,
          srcLang: activeTransUnit?.source.langCode,
          tgtLang: activeTransUnit?.target.langCode,
        },

        category,
        ownerGroupId: ownerGroupId ?? undefined,
      };
      executePost(
        { data },
        {
          useCache: true,
        }
      );
    }
  }, [
    activeTransUnit?.level,
    activeTransUnit?.source.langCode,
    activeTransUnit?.source.text,
    activeTransUnit?.source.textNormalized,
    activeTransUnit?.target.langCode,
    categoryCode_,
    executePost,
    findCategoryMappingByCode,
    hasCategoryMapping,
    isExpanded,
    ownerGroupId,
  ]);

  useEffect(() => {
    if (isExpanded) {
      const entries = rawTmEntries?.map<TMEntry>((entry) => {
        const option = categoryOptions.find((option) => option.value === entry.properties.category);
        const categoryLabel = option?.label ?? entry.properties.category;
        return {
          ...entry,
          properties: {
            ...entry.properties,
            categoryLabel,
          },
        };
      });
      setTmEntries(entries);
    }
  }, [categoryOptions, isExpanded, rawTmEntries]);

  return (
    <Component
      activeTransUnit={activeTransUnit}
      tmEntries={tmEntries}
      loading={loading}
      isExpanded={isExpanded}
    />
  );
};

Container.displayName = 'TMLookupPanel';
export default Container;
