import React, {
  ClipboardEventHandler,
  CompositionEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useUndoManager } from '../../libs/useUndoManager';
import ContentEditable from 'react-contenteditable';
import { getCharacterAfterCaret, hasSelection, moveCaret } from './libs/editor-util';
import { selectTagWrappers } from './libs/tag-wrapper-util';
import { SegmentNormalizer } from './libs/segment-normalizer';

import { SegmentKeyboardEventHandler } from './libs/segment-keyboard-event-handler';
import { ZWSP } from './libs/constants';
import './Segment.css';
import {
  ChangeSegmentStateEventPayload,
  EditorCommandManager,
  EventId,
  UndoEventPayload,
} from '../libs/types';
import { useEditorEvent } from '../libs/hooks/useEditorEvent';
import { htmlToXliff, xliffToHtml } from './libs/xliff-util';
import { useXliffUnitSelection } from '../libs/hooks/useXliffUnitSelection';
import { useCatResults } from '../libs/hooks/useCatResults';
import { tmxToHtml } from './libs/tmx-util';

const normalizeSegment = (tuid: string, thisSegment: HTMLTableCellElement | null): void => {
  if (thisSegment) {
    const normalizer = new SegmentNormalizer(tuid, thisSegment);
    normalizer.run();
  }
};

export type Props = {
  position: number;
  tuid: string;
  isSource: boolean;
  isLocked: boolean;
  langCode: string;
  xml: string;
  commandManager: EditorCommandManager;
  onUpdateSegment: (xml: string, langCode: string) => void;
  onCommitSegment: (xml: string, langCode: string) => void;
  transUnitElRef: React.MutableRefObject<HTMLTableRowElement | null>;
  onSelectSegment?: (tuid: string, element: HTMLElement | null) => void;
  onBlurSegment?: (tuid: string, element: HTMLElement | null) => void;
};

export const XliffSegment: React.FC<Props> = ({
  position,
  tuid,
  isSource,
  isLocked,
  langCode,
  xml,
  commandManager: initialCommandManager,
  onUpdateSegment,
  onCommitSegment,
  transUnitElRef,
  onSelectSegment,
  onBlurSegment,
}) => {
  const { editorEvent, clearEditorEvent } = useEditorEvent();
  const segmentHtml = useRef('');
  const commandManager = useRef<EditorCommandManager>(initialCommandManager);
  const segmentElRef = useRef<HTMLTableCellElement | null>(null);
  const flags = useRef({
    changeBlocked: false,
    undoBlocked: false,
    isCompositionEnded: true,
    isDirty: false,
    isReadOnly: false,
  });
  const keyboardEventHandler = useRef(new SegmentKeyboardEventHandler(commandManager.current));
  const { unitSelection } = useXliffUnitSelection();
  const { catResults } = useCatResults();

  const isSelectedUnit = useMemo(() => {
    if (!unitSelection.activeUnit) return false;
    return unitSelection.activeUnit._id === tuid;
  }, [tuid, unitSelection.activeUnit]);

  const [, setRerenderState] = useState<unknown>();
  const rerender = () => {
    setRerenderState({});
  };

  const sendToServerOnUnload = useCallback(
    async (e?: BeforeUnloadEvent) => {
      if (flags.current.isDirty && !flags.current.isReadOnly) {
        if (e) {
          e.preventDefault();
          e.returnValue = '';
        }
        // segmentElRefはアンマウント済みなので使えない
        const tmx = htmlToXliff(segmentHtml.current);
        onCommitSegment(tmx, langCode);
        flags.current.isDirty = false;
      }
    },
    [langCode, onCommitSegment]
  );

  useEffect(() => {
    // ブラウザのタブが閉じられる前に呼ばれる
    window.addEventListener('beforeunload', sendToServerOnUnload);
    return () => {
      // console.log('segment unmount');
      // このセグメントDOMのアンマウント時に呼ばれる
      sendToServerOnUnload();
      window.removeEventListener('beforeunload', sendToServerOnUnload);
    };
  }, [sendToServerOnUnload]);

  useEffect(() => {
    segmentHtml.current = xliffToHtml(xml, {
      tagDisplayMode: initialCommandManager.editorOptions.tagDisplayMode,
      includeNewlines: false,
    });
    commandManager.current = initialCommandManager;
    keyboardEventHandler.current = new SegmentKeyboardEventHandler(commandManager.current);

    const isReadOnly = () => {
      return (
        commandManager.current.editorOptions.isReadOnly ||
        (isSource && !commandManager.current.editorOptions.isSourceEditable) ||
        isLocked
      );
    };
    flags.current.isReadOnly = isReadOnly();

    rerender();
  }, [initialCommandManager, isLocked, isSource, xml]);

  const applyUndo = (newXliff: string) => {
    // console.log('applyUndo');
    // 現在のtagDisplayModeを渡したxliffToHtmlを再度通す必要がある。
    const newHtml = xliffToHtml(newXliff, {
      tagDisplayMode: commandManager.current.editorOptions.tagDisplayMode,
      includeNewlines: false
    });
    flags.current.isDirty = true;
    segmentHtml.current = newHtml;
    // updateXliff(newXliff);
    onUpdateSegment(newXliff, langCode);
    rerender();
  };

  const { undoManager, addUndo } = useUndoManager({
    applyUndo,
    initialValue: xml,
  });

  const undo = useCallback(
    (redo?: boolean) => {
      // pending中のaddUndoがあれば発火させる
      addUndo.flush();
      if (redo) {
        if (undoManager.canRedo()) {
          flags.current.undoBlocked = true;
          undoManager.redo();
        }
      } else {
        if (undoManager.canUndo()) {
          flags.current.undoBlocked = true;
          undoManager.undo();
        }
      }
    },
    [addUndo, undoManager]
  );

  useEffect(() => {
    if (!segmentElRef.current) return;

    // セグメントのDOM変更を監視するmutationObserver
    const mutationObserver = new MutationObserver((mutations) => {
      if (flags.current.isReadOnly) {
        flags.current.isCompositionEnded = true;
        rerender();
        return;
      }
      if (flags.current.changeBlocked) {
        flags.current.changeBlocked = false;
        return;
      }
      if (flags.current.undoBlocked) {
        flags.current.undoBlocked = false;
        return;
      }
      if (flags.current.isCompositionEnded) {
        // changeBlocked = trueにしておかないと、normalizeSegmentで無限ループが発生する。
        flags.current.changeBlocked = true;
        // console.log(`${isSource ? 'SRC' : 'TGT'} CHANGED:`, position);
        // DOMの構造を正規化
        // normalizeSegment(tuid, isSource);
        normalizeSegment(tuid, segmentElRef.current);
        // const newHtml = getSegmentHtml(tuid, isSource);
        const newHtml = segmentElRef.current?.innerHTML ?? '';
        const newXml = htmlToXliff(newHtml);
        const oldXml = htmlToXliff(segmentHtml.current);
        flags.current.isDirty = !flags.current.isDirty ? oldXml !== newXml : flags.current.isDirty;
        segmentHtml.current = newHtml;
        onUpdateSegment(newXml, langCode);
        addUndo(newXml);
      }
    });
    // console.log('mutationObserver initialized');
    flags.current.changeBlocked = true;
    mutationObserver.observe(segmentElRef.current, {
      // attributes: true,
      childList: true,
      characterData: true,
      characterDataOldValue: true,
      subtree: true,
    });

    return () => {
      // console.log('mutationObserver disconnected');
      mutationObserver?.disconnect();
    };
  }, [addUndo, langCode, onUpdateSegment, tuid]);

  useEffect(() => {
    if (!editorEvent) return;
    if (editorEvent.eventId === EventId.Undo) {
      const payload = editorEvent.payload as UndoEventPayload;
      if (payload.tuid === tuid) {
        undo();
      }
    } else if (editorEvent.eventId === EventId.Redo) {
      const payload = editorEvent.payload as UndoEventPayload;
      if (payload.tuid === tuid) {
        undo(true);
      }
    } else if (editorEvent.eventId === EventId.ChangeSegmentState) {
      const payload = editorEvent.payload as ChangeSegmentStateEventPayload;
      if (payload.tuid === tuid && !isSource) {
        // XliffUnit.tsxのhandleChangeSegmentState()でサーバ送信処理するので
        // sendToServerOnUnload()が呼ばれないようにisDirtyをfalseにしておく。
        flags.current.isDirty = false;
      }
    } else if (
      editorEvent.eventId === EventId.ApplySelectedMatch ||
      editorEvent.eventId === EventId.ApplySelectedConcordanceSearchResult
    ) {
      const { selectedTMEntry, selectedConcordanceSearchEntry } = catResults;
      const tmEntry =
        editorEvent.eventId === EventId.ApplySelectedMatch
          ? selectedTMEntry
          : selectedConcordanceSearchEntry;
      if (isSelectedUnit && tmEntry && !isSource && !flags.current.isReadOnly) {
        clearEditorEvent();
        // とりあえずプレインテキストを適用する
        const newText = tmxToHtml(tmEntry.tgtTmx, { includeTags: false });
        flags.current.isDirty = true;
        segmentHtml.current = newText;
        onUpdateSegment(newText, langCode);
        rerender();
        segmentElRef.current?.focus();
      }
    }
  }, [
    catResults,
    clearEditorEvent,
    editorEvent,
    isSelectedUnit,
    isSource,
    langCode,
    onUpdateSegment,
    tuid,
    undo,
  ]);

  const handleCompositionStart: CompositionEventHandler<HTMLDivElement> = (e) => {
    flags.current.isCompositionEnded = false;
  };

  const handleCompositionUpdate: CompositionEventHandler<HTMLDivElement> = (e) => {
    flags.current.isCompositionEnded = false;
  };

  const handleCompositionEnd: CompositionEventHandler<HTMLDivElement> = (e) => {
    flags.current.isCompositionEnded = true;
    // normalizeSegment(tuid, isSource);
    normalizeSegment(tuid, segmentElRef.current);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTableDataCellElement> = async (e) => {
    await keyboardEventHandler.current.handleKeyDown(e, flags.current.isReadOnly);
  };

  const handleBlur: FocusEventHandler<HTMLElement> = useCallback(
    (e) => {
      sendToServerOnUnload();
      if (onBlurSegment) onBlurSegment(tuid, segmentElRef.current);
    },
    [onBlurSegment, sendToServerOnUnload, tuid]
  );

  const handleSelect: ReactEventHandler<HTMLElement> = (e) => {
    if (flags.current.isCompositionEnded) {
      selectTagWrappers();
      const nextChar = getCharacterAfterCaret();
      if (nextChar === ZWSP && !hasSelection()) {
        moveCaret(1);
      }
      if (onSelectSegment) onSelectSegment(tuid, segmentElRef.current);
    }
  };

  const handlePaste: ClipboardEventHandler<HTMLElement> = async (e) => {
    if (flags.current.isReadOnly) {
      e.preventDefault();
      return;
    }
    await commandManager.current.paste(e);
  };

  const handleCopy: ClipboardEventHandler<HTMLElement> = async (e) => {
    await commandManager.current.copy(e);
  };

  const handleCut: ClipboardEventHandler<HTMLElement> = async (e) => {
    if (flags.current.isReadOnly) {
      e.preventDefault();
      return;
    }
    await commandManager.current.cut(e);
  };

  const handleDragEvent: React.DragEventHandler<HTMLDivElement> = (e) => {
    if (flags.current.isReadOnly) {
      e.preventDefault();
      return;
    }
  };

  return (
    <ContentEditable
      className="editor-cell"
      data-celltype={isSource ? 'source' : 'target'}
      data-cellname={langCode}
      html={segmentHtml.current}
      tagName="td"
      innerRef={segmentElRef}
      onCompositionStart={handleCompositionStart}
      onCompositionUpdate={handleCompositionUpdate}
      onCompositionEnd={handleCompositionEnd}
      onKeyDown={handleKeyDown}
      onSelect={handleSelect}
      onBlur={handleBlur}
      onPaste={handlePaste}
      onCopy={handleCopy}
      onCut={handleCut}
      onDragEnter={handleDragEvent}
      onDragLeave={handleDragEvent}
      onDragOver={handleDragEvent}
      onDrop={handleDragEvent}
    />
  );
};
