import * as rangy from 'rangy';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-selectionsaverestore';
import { insertAfter } from './editor-util';
import { MarkerCategory } from './types';
import { NEWLINE, ZWSP } from './constants';

/**
 * 現在の選択終了点がnewline-markerに含まれる場合、そのnewline-markerを返します。
 * @returns newline-marker
 */
export const getParentNewlineMarker = (): HTMLElement | null => {
  const sel = rangy.getSelection();
  let newlineMarker: HTMLElement | null = null;
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    // キャレット位置がnewline-markerの中にあるケースは以下の2つ
    // 1. range.endContainerがnewline-marker
    // 2. range.endcontainer.parentElementがnewline-marker
    const endContainer = range.endContainer as HTMLElement;
    const parent = range.endContainer.parentElement;
    if (endContainer.dataset && endContainer.dataset.type === MarkerCategory.NewlineMarker) {
      newlineMarker = endContainer;
    } else if (parent?.dataset && parent.dataset.type === MarkerCategory.NewlineMarker) {
      newlineMarker = parent;
    }
  }
  return newlineMarker;
};

/**
 * 指定したノードに含まれるnewline-markerを返します
 * @param node - newline-markerを探索する親ノード
 * @returns newline-markerの配列
 */
export const newlineMarkersUnder = (node: Node): HTMLElement[] => {
  let n: Node | null;
  const nodes: Node[] = [];
  const walk = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT, function (
    node: Node
  ): number {
    const elem = node as HTMLElement;
    if (elem.dataset?.type === MarkerCategory.NewlineMarker) {
      return NodeFilter.FILTER_ACCEPT;
    }
    return NodeFilter.FILTER_SKIP;
  } as any);
  while ((n = walk.nextNode())) {
    nodes.push(n);
  }
  return nodes as HTMLElement[];
};

/**
 * newline-markerに含まれるテキストノードのうち、NEWLINE + ZWSP以外を削除し、削除した文字列を返します。
 * @param tagWrapper - 対象のtag-wrapper
 * @returns 削除した文字列
 */
export const ensureNewlineMarkerHasOnlyZWSP = (newlineMarker: Node): string => {
  const children = [...newlineMarker.childNodes] as HTMLElement[];
  const text = children
    .map((textNode) => {
      const text = textNode.textContent || '';
      newlineMarker.removeChild(textNode);
      return text;
    })
    .join('');
  newlineMarker.appendChild(document.createTextNode(NEWLINE + ZWSP));
  return text.replace(/[\r\n\u200B]/g, '');
};

/**
 * newline-markerの中に入り込んだ入力文字列をnewline-markerの直後に移動させます。
 *
 * 例:
 *
 * \<newline-marker>ZWSP[文字列]\</newline-marker>
 *
 * ↓
 *
 *  \<newline-marker>ZWSP\</newline-marker>[文字列]
 */
export const moveTypedTextOutOfNewlineMarker = (): void => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    const newlineMarker = getParentNewlineMarker();
    if (newlineMarker) {
      const typedText = ensureNewlineMarkerHasOnlyZWSP(newlineMarker);
      if (typedText) {
        const newText = document.createTextNode(typedText);
        insertAfter(newText, newlineMarker);
        range.selectNodeContents(newText);
        range.collapse();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }
};
