import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { XliffJob } from '../components/libs/types';
import XliffEditor from '../components/XliffEditor';
import { paths } from './paths';

type Props = {
  onInitializeJob: React.Dispatch<React.SetStateAction<XliffJob | null | undefined>>;
  onLogOut: () => void;
};

export const Routes: React.FC<Props> = ({ onInitializeJob, onLogOut }) => {
  return (
    <Switch>
      <Route
        exact
        path={paths.jobEditor(':id')}
        render={(props) => (
          <XliffEditor {...props} onInitializeJob={onInitializeJob} onLogOut={onLogOut} />
        )}
      />
    </Switch>
  );
};
