import { format } from 'date-fns';
import { TMEntry } from '../libs/types';

import './CatMetadata.css';

export type Props = {
  tmEntry?: TMEntry | null;
  showPenalty: boolean;
};

const Component: React.FC<Props> = ({ tmEntry, showPenalty }) => {
  const formatPenaltyReasons = (penaltyReasons: { [key: string]: unknown }[]) => {
    const displayNames: { [key: string]: string } = {
      ownerGroup: 'Owner Group',
      category: 'Category',
      confidence: 'Confidence',
      multipleTranslations: 'Multiple Translations',
    };
    const reasons = penaltyReasons
      .map((x) => Object.keys(x)[0])
      .map((key) => displayNames[key] ?? key)
      .join(', ');
    return reasons;
  };

  return (
    <div className="cat-metadata">
      <dl>
        {showPenalty && (
          <>
            <dt>Penalty:</dt>
            <dd>{tmEntry?.penalty ?? <>&nbsp;</>}</dd>
            <dt>Penalty Reasons:</dt>
            <dd>
              {formatPenaltyReasons(tmEntry?.penaltyReasons ?? [])}
              <>&nbsp;</>
            </dd>
          </>
        )}
        <dt>Owner Group:</dt>
        <dd>{tmEntry?.ownerGroup?.name || <>&nbsp;</>}</dd>
        <dt>Vendor:</dt>
        <dd>{tmEntry?.vendor?.name || <>&nbsp;</>}</dd>
        <dt>Confidence:</dt>
        <dd>{tmEntry?.confidence || <>&nbsp;</>}</dd>
        <dt>Category:</dt>
        <dd>{tmEntry?.properties.categoryLabel || <>&nbsp;</>}</dd>
        <dt>Imported from:</dt>
        <dd>{tmEntry?.properties.tmName || <>&nbsp;</>}</dd>
        <dt>Type:</dt>
        <dd>{tmEntry?.properties.tmType || <>&nbsp;</>}</dd>
        <dt>Created at:</dt>
        <dd>
          {tmEntry?.createdAt
            ? format(new Date(tmEntry.createdAt), 'yyyy-MM-dd HH:mm:ss')
            : '' || <>&nbsp;</>}
        </dd>
        <dt>Created by:</dt>
        <dd>{tmEntry?.createdBy || <>&nbsp;</>}</dd>
        <dt>Updated at:</dt>
        <dd>
          {tmEntry?.updatedAt
            ? format(new Date(tmEntry.updatedAt), 'yyyy-MM-dd HH:mm:ss')
            : '' || <>&nbsp;</>}
        </dd>
        <dt>Updated by:</dt>
        <dd>{tmEntry?.updatedBy || <>&nbsp;</>}</dd>
      </dl>
    </div>
  );
};

Component.displayName = 'CatMetadata';
export default Component;
