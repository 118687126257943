import { useCallback } from 'react';
import { createContext, useMemo, useReducer, useContext } from 'react';
import { XliffUnitSelection } from '../types';

export const initialState: XliffUnitSelection = {
  activeUnit: null,
  selectedUnits: [],
};

export type Action = { type: 'UPDATE'; payload: Partial<XliffUnitSelection> } | { type: 'RESET' };

// 現在のstateとpayloadを比較して更新の必要が無ければパフォーマンスのため再レンダリングを避ける
const skipUpdate = (state: XliffUnitSelection, payload: Partial<XliffUnitSelection>) => {
  // activeUnitに変更がある場合は更新
  if (payload.activeUnit !== undefined) {
    if (state.activeUnit?._id !== payload.activeUnit?._id) return false;
  }

  // selectedUnitsに変更がある場合は更新
  if (payload.selectedUnits !== undefined) {
    const ids1 = state.selectedUnits.map((x) => x._id);
    const ids2 = payload.selectedUnits.map((x) => x._id);
    return ids1.length === ids2.length && ids1.every((id1) => ids2.includes(id1));
  }
  return true;
};

export const reducer = (state: XliffUnitSelection, action: Action): XliffUnitSelection => {
  switch (action.type) {
    case 'UPDATE': {
      if (skipUpdate(state, action.payload)) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'RESET': {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export type UpdateUnitSelection = (payload: Partial<XliffUnitSelection>) => void;
export type ResetUnitSelection = () => void;

type ContextType = {
  unitSelection: XliffUnitSelection;
  updateUnitSelection: UpdateUnitSelection;
  resetUnitSelection: ResetUnitSelection;
};

export const XliffUnitSelectionContext = createContext<ContextType | null>(null);

export const XliffUnitSelectionContextProvider: React.FC = (props) => {
  const [unitSelection, dispatch] = useReducer(reducer, initialState);

  const updateUnitSelection: UpdateUnitSelection = useCallback(
    (payload) => dispatch({ type: 'UPDATE', payload }),
    []
  );
  const resetUnitSelection: ResetUnitSelection = useCallback(() => dispatch({ type: 'RESET' }), []);

  const value = useMemo(
    () => ({
      unitSelection,
      updateUnitSelection,
      resetUnitSelection,
    }),
    [resetUnitSelection, unitSelection, updateUnitSelection]
  );

  return <XliffUnitSelectionContext.Provider value={value} {...props} />;
};

export const useXliffUnitSelection = (): ContextType => {
  const context = useContext(XliffUnitSelectionContext);

  if (typeof context === 'undefined') {
    throw new Error('useXliffUnitSelection must be used within XliffUnitSelectionContext');
  }

  return context as ContextType;
};
