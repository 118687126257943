import useAxios from 'axios-hooks';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import _ from 'lodash';

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Messages } from '../libs/messages';
import { EventId, OwnerGroup, TMEntry, XliffJob, XliffUnit } from '../libs/types';

import './ConcordanceSearchPanel.css';
import { useCatResults } from '../libs/hooks/useCatResults';
import { useEditorEvent } from '../libs/hooks/useEditorEvent';
import { NumberField } from '../NumberField';
import { ComboBox } from '../ComboBox';
import { TextField } from '../TextField';
import CatMetadata from '../CatMetadata';
import { useCallback } from 'react';
import { extractMaskId, maskRegex } from '../Segment/libs/string-util';
import { config, STAGE } from '../../config';
import { useCategoryOptions } from '../libs/hooks/useCategoryOptions';

const toRuns = (maskedString: string, highlightRegExp: RegExp | null) => {
  const runs = maskedString
    .split(maskRegex)
    .map((substr, idx) => {
      if (maskRegex.test(substr)) {
        return (
          <span className="tag tag-empty" key={idx}>
            {extractMaskId(substr)}
          </span>
        );
      }
      if (highlightRegExp && highlightRegExp) {
        return substr.split(highlightRegExp).map((substr2, idx2) => {
          if (highlightRegExp.test(substr2)) {
            return (
              <span key={`${idx}-${idx2}`} className="concordance-search-highlighted">
                {substr2}
              </span>
            );
          }
          return substr2;
        });
      }
      return substr;
    })
    .flat();
  return runs;
};

const toUrl = (formData: FormData, encode = true) => {
  const queryParams = [];
  if (formData.srcLang) {
    queryParams.push(`srcLang=${formData.srcLang}`);
  }
  if (formData.tgtLang) {
    queryParams.push(`tgtLang=${formData.tgtLang}`);
  }
  if (formData.srcTmx) {
    queryParams.push(`[srcTmx][$regex]=${formData.srcTmx}`);
  }
  if (formData.tgtTmx) {
    queryParams.push(`[tgtTmx][$regex]=${formData.tgtTmx}`);
  }
  if (formData.confidence != null) {
    queryParams.push(`confidence=${formData.confidence}`);
  }
  if (formData.ownerGroupId) {
    queryParams.push(`ownerGroupId=${formData.ownerGroupId}`);
  }
  if (formData.category) {
    queryParams.push(`[properties.category]=${formData.category}`);
  }
  if (formData.tmName) {
    queryParams.push(`[properties.tmName][$regex]=${formData.tmName}`);
  }
  const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  const url = `${config[STAGE].mtdUrl}/?#/tm-entries${query}`;
  return encode ? encodeURI(url) : url;
};

export type ContainerProps = {
  activeTransUnit?: XliffUnit | null;
  job?: XliffJob;
  isExpanded: boolean;
};

export type Props = Omit<ContainerProps, 'category' | 'ownerGroupId'> & {
  tmEntries?: TMEntry[];
  loading: boolean;
  setFormData: Dispatch<SetStateAction<FormData>>;
  formData: FormData;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  ownerGroups: OwnerGroup[];
  categoryOptions: { value: string; label: string }[];
  formError?: { [key in keyof FormData]: string };
  handleClearButtonClick: () => void;
  highlightRegExp?: {
    src: RegExp | null;
    tgt: RegExp | null;
  };
};
export const Component: React.FC<Props> = ({
  activeTransUnit,
  tmEntries,
  loading,
  setFormData,
  formData,
  handleSubmit,
  ownerGroups,
  categoryOptions,
  isExpanded,
  formError,
  highlightRegExp,
  handleClearButtonClick,
}) => {
  const [currentTMEntryIdx, setCurrentTMEntryIdx] = useState(0);
  const { catResults, updateCatResults } = useCatResults();
  const { editorEvent, raiseEditorEvent } = useEditorEvent();
  const concordanceSearchGridRef = useRef<HTMLTableElement>(null);
  const [isAdvancedSearchCollapsed, setIsAdvancedSearchCollapsed] = useState(true);
  const [srcTextField, setSrcTextField] = useState<HTMLInputElement>();
  const srcTextFieldRef = useCallback((node) => setSrcTextField(node), []);
  const searchFormRef = useRef<HTMLFormElement>(null);

  const ownerGroupOptions = ownerGroups.map((x) => ({ label: x.name, value: x._id }));

  useEffect(() => {
    // パネルが開いたらSourceテキストフィールドにフォーカス
    if (srcTextField && isExpanded) {
      srcTextField.focus();
    }
  }, [isExpanded, srcTextField]);

  useEffect(() => {
    // 矢印キーで検索結果の選択を上下に移動したとき、常に表示されるようにスクロールして調整
    if (!concordanceSearchGridRef.current) return;
    const selectedRow = concordanceSearchGridRef.current.querySelector(
      `tr[data-id="${catResults.selectedConcordanceSearchEntry?._id}"]`
    );
    selectedRow?.scrollIntoView({ block: 'nearest' });
  }, [catResults.selectedConcordanceSearchEntry]);

  useEffect(() => {
    // アクティブTUが移動したらインデックスリセット
    setCurrentTMEntryIdx(0);
  }, [activeTransUnit?._id]);

  useEffect(() => {
    if (!tmEntries) return;
    // インデックスの変更に応じてTMエントリを選択
    updateCatResults({ selectedConcordanceSearchEntry: tmEntries[currentTMEntryIdx] });
  }, [currentTMEntryIdx, tmEntries, updateCatResults]);

  useEffect(() => {
    // AppMenuBarまたはキーボードショートカットからのイベントを処理
    if (!editorEvent || !tmEntries) return;
    if (editorEvent.eventId === EventId.SelectPrevConcordanceSearchResult) {
      // インデックスをデクリメント
      setCurrentTMEntryIdx((prev) => {
        if (prev === 0) return prev;
        return prev - 1;
      });
    } else if (editorEvent.eventId === EventId.SelectNextConcordanceSearchResult) {
      // インデックスをインクリメント
      setCurrentTMEntryIdx((prev) => {
        if (prev === tmEntries.length - 1) return prev;
        return prev + 1;
      });
    }
  }, [editorEvent, tmEntries]);

  const handleTMEntryClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!tmEntries) return;
    const elem = e.target as HTMLElement;
    const tmEntryId = elem.closest('tr')?.getAttribute('data-id');
    if (tmEntryId) {
      const foundIdx = tmEntries.findIndex((e) => e._id === tmEntryId);
      setCurrentTMEntryIdx(foundIdx >= 0 ? foundIdx : 0);
    }
  };

  const handleTMEntryDoubleClick = () => {
    raiseEditorEvent({
      eventId: EventId.ApplySelectedConcordanceSearchResult,
      payload: null,
    });
  };

  if (!activeTransUnit) {
    return null;
  }

  return (
    <Splitter
      style={isExpanded ? undefined : { display: 'none' }}
      className="concordance-search-panel panel-base"
      layout="vertical"
      gutterSize={6}
    >
      <SplitterPanel
        className="concordance-search-grid-wrapper"
        style={{ scrollPaddingTop: `${searchFormRef.current?.clientHeight}px` }}
      >
        <form
          ref={searchFormRef}
          onSubmit={handleSubmit}
          className="concordance-search-form panel-base"
        >
          <div className="p-fluid p-formgrid p-grid concordance-search-basic-search">
            <TextField
              inputRef={srcTextFieldRef}
              id="concordance-search-src-tmx"
              pFieldClassName="p-col-12"
              className={`p-inputtext-sm ${formError?.srcTmx ? 'p-invalid' : ''}`}
              placeholder="Source"
              value={formData.srcTmx ?? ''}
              helpText={formError?.srcTmx}
              helpTextClassName="p-error"
              onChange={(e) => setFormData((prev) => ({ ...prev, srcTmx: e.target.value }))}
            />
            <TextField
              id="concordance-search-tgt-tmx"
              pFieldClassName="p-col-12"
              className={`p-inputtext-sm ${formError?.tgtTmx ? 'p-invalid' : ''}`}
              placeholder="Target"
              value={formData.tgtTmx ?? ''}
              helpText={formError?.tgtTmx}
              helpTextClassName="p-error"
              onChange={(e) => setFormData((prev) => ({ ...prev, tgtTmx: e.target.value }))}
            />
            {/* <Button onClick={(e) => handleSubmit()} label="Search" /> */}
            <div className="p-col-12 p-d-flex">
              <Button type="submit" label="Search" className="p-mr-3" />
              <Button
                type="button"
                icon="pi pi-external-link"
                className="p-ml-auto p-button-secondary p-mr-3"
                tooltip="Link to TM Entry List"
                tooltipOptions={{ position: 'left' }}
                onClick={() => {
                  window.open(toUrl(formData), '_blank');
                }}
              />
              <Button
                type="button"
                icon="pi pi-trash"
                className="p-ml-auto p-button-secondary"
                tooltip="Clear Source and Target"
                tooltipOptions={{ position: 'left' }}
                onClick={() => {
                  handleClearButtonClick();
                  srcTextField?.focus();
                }}
              />
            </div>
          </div>
          <Panel
            className="concordance-search-advanced-search"
            header="Advanced search"
            toggleable
            collapsed={isAdvancedSearchCollapsed}
            onToggle={(e) => setIsAdvancedSearchCollapsed(e.value)}
          >
            <div className="p-fluid p-formgrid p-grid">
              <NumberField
                id="concordance-search-confidence"
                className="concordance-search-advanced-field p-inputtext-sm"
                pFieldClassName="p-col-6"
                placeholder="Confidence"
                min={0}
                max={100}
                size={10}
                value={formData.confidence}
                onValueChange={(e) => setFormData((prev) => ({ ...prev, confidence: e.value }))}
              />
              <ComboBox
                className="concordance-search-advanced-field p-inputtext-sm"
                pFieldClassName="p-col-6"
                id="concordance-search-owner-group"
                placeholder="Owner group"
                showClear
                value={formData.ownerGroupId}
                options={ownerGroupOptions}
                onChange={(e) => setFormData((prev) => ({ ...prev, ownerGroupId: e.value ?? '' }))}
              />
              <ComboBox
                className="concordance-search-advanced-field p-inputtext-sm"
                pFieldClassName="p-col-6"
                id="concordance-search-category"
                placeholder="Category"
                showClear
                filter
                resetFilterOnHide
                value={formData.category}
                options={categoryOptions}
                onChange={(e) => setFormData((prev) => ({ ...prev, category: e.value ?? null }))}
              />
              <TextField
                className="concordance-search-advanced-field p-inputtext-sm"
                pFieldClassName="p-col-6"
                id="concordance-search-imported-from"
                placeholder="Imported from"
                value={formData.tmName ?? ''}
                onChange={(e) => setFormData((prev) => ({ ...prev, tmName: e.target.value }))}
              />
            </div>
          </Panel>
        </form>

        {/* 
          ローディング中: ProgressSpinnerとメッセージを表示
          tmEntriesがnull/undefined: 空のdivを表示
          tmEntriesが0件: メッセージを表示
          上記以外 (tmEntriesが1件以上): 検索結果をtableに表示
         */}
        {loading ? (
          <div className="concordance-search-panel-progress-msg">
            <ProgressSpinner />
            {Messages.SearchingTMs}
          </div>
        ) : !tmEntries ? (
          <div className="concordance-search-panel-progress-msg"></div>
        ) : tmEntries.length === 0 ? (
          <div className="concordance-search-panel-progress-msg">{Messages.NoMatchesFound}</div>
        ) : (
          <table ref={concordanceSearchGridRef} className="concordance-search-grid">
            <tbody>
              {tmEntries.map((tmEntry, index) => {
                const selectedClass =
                  tmEntry._id === catResults.selectedConcordanceSearchEntry?._id
                    ? 'concordance-search-selected-tm-entry'
                    : '';

                const srcRuns = toRuns(
                  tmEntry.srcMasked,
                  highlightRegExp ? highlightRegExp.src : null
                );
                const tgtRuns = toRuns(
                  tmEntry.tgtMasked,
                  highlightRegExp ? highlightRegExp.tgt : null
                );

                return (
                  <tr
                    key={tmEntry._id}
                    className={selectedClass}
                    data-id={tmEntry._id}
                    onClick={handleTMEntryClick}
                    onDoubleClick={handleTMEntryDoubleClick}
                  >
                    <th className={`concordance-search-no`}>{index + 1}</th>
                    <td className="concordance-search-src-tmx">{srcRuns}</td>
                    <td className="concordance-search-tgt-tmx">{tgtRuns}</td>
                    <td className={`concordance-search-active`}>{tmEntry.active ? '✓' : ''}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </SplitterPanel>
      <SplitterPanel className="concordance-search-details p-flex-column">
        <CatMetadata tmEntry={catResults.selectedConcordanceSearchEntry} showPenalty={false} />
      </SplitterPanel>
    </Splitter>
  );
};

type FormData = {
  srcTmx?: string;
  tgtTmx?: string;
  confidence?: number;
  ownerGroupId?: string;
  category?: string | null;
  tmName?: string;
  srcLang?: string;
  tgtLang?: string;
};

const Container: React.FC<ContainerProps> = ({ activeTransUnit, job, isExpanded }) => {
  const categoryCode =
    activeTransUnit?.properties?.categoryCode ?? job?.properties?.category_code ?? '';
  const ownerGroupId = job?.project?.ownerGroupId ?? undefined;
  const srcLang = job?.srcLang ?? undefined;
  const tgtLang = job?.tgtLang ?? undefined;

  const {
    hasCategoryMapping,
    loadCategoryMappings,
    categoryOptions,
    findCategoryMappingByCode,
  } = useCategoryOptions({
    ownerGroupId,
    srcLang,
    tgtLang,
    lazyLoad: true,
  });

  const [formData, setFormData] = useState<FormData>({
    srcLang,
    tgtLang,
    ownerGroupId,
    category: undefined,
  });

  const [formError, setFormError] = useState<{ [key in keyof FormData]: string }>();
  const [highlightRegExp, setHighlightRegExp] = useState<{
    src: RegExp | null;
    tgt: RegExp | null;
  }>();
  const [{ data: rawTmEntries, loading }, executePost] = useAxios<TMEntry[]>(
    {
      url: 'tmEntries/concordanceSearch',
      method: 'post',
    },
    {
      manual: true,
    }
  );
  const [tmEntries, setTmEntries] = useState<TMEntry[]>();

  const [{ data: ownerGroups }, executeGetOwnerGroups] = useAxios<OwnerGroup[]>(
    {
      url: 'ownerGroups',
      method: 'get',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (isExpanded) {
      const entries = rawTmEntries?.map<TMEntry>((entry) => {
        const option = categoryOptions.find((option) => option.value === entry.properties.category);
        const categoryLabel = option?.label ?? entry.properties.category;
        return {
          ...entry,
          properties: {
            ...entry.properties,
            categoryLabel,
          },
        };
      });
      setTmEntries(entries);
    }
  }, [categoryOptions, isExpanded, rawTmEntries]);

  useEffect(() => {
    if (isExpanded) {
      executeGetOwnerGroups(undefined, { useCache: true });
      loadCategoryMappings(undefined, { useCache: true });

      setFormData((prev) => {
        let category;
        if (prev.category === null) {
          // Advanced searchパネルのカテゴリフィールドで×ボタンをクリックされた場合、prev.category は null
          category = null;
        } else if (prev.category != null) {
          // Advanced searchパネルのカテゴリフィールドで何らかのカテゴリが選択されている場合 ("カテゴリなし" = 空文字 を含む)
          category = prev.category;
        } else if (categoryCode) {
          // このパネルを開いたときの初期値
          category = categoryCode[0];
        } else {
          // ここに来ることはないはず
          category = undefined;
        }
        return {
          ...prev,
          srcLang: prev.srcLang ?? job?.srcLang ?? undefined,
          tgtLang: prev.tgtLang ?? job?.tgtLang ?? undefined,
          ownerGroupId: prev.ownerGroupId ?? job?.project?.ownerGroupId ?? undefined,
          category,
        };
      });
    }
  }, [
    categoryCode,
    executeGetOwnerGroups,
    isExpanded,
    job?.project?.ownerGroupId,
    job?.srcLang,
    job?.tgtLang,
    loadCategoryMappings,
  ]);

  useEffect(() => {
    // ownerGroupId/srcLang/tgtLangに合致するレコードがカテゴリマッピング表に含まれる場合(hasCategoryMapping)、
    // categoryCodeからindexを検索しcategoryとして使用する。
    if (hasCategoryMapping) {
      const cm = findCategoryMappingByCode(categoryCode);
      setFormData((prev) => ({
        ...prev,
        category: cm ? cm.index : undefined,
      }));
    }
  }, [categoryCode, findCategoryMappingByCode, hasCategoryMapping]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formData.srcTmx && !formData.tgtTmx) {
      setFormError((prev) => ({
        ...prev,
        srcTmx: !formData.srcTmx ? 'Source or Target is required.' : '',
        tgtTmx: !formData.tgtTmx ? 'Source or Target is required.' : '',
      }));
    } else {
      setFormError(undefined);
      setHighlightRegExp({
        src: formData.srcTmx ? new RegExp(`(${_.escapeRegExp(formData.srcTmx)})`) : null,
        tgt: formData.tgtTmx ? new RegExp(`(${_.escapeRegExp(formData.tgtTmx)})`) : null,
      });
      executePost(
        {
          data: {
            srcTmx: formData.srcTmx || undefined,
            tgtTmx: formData.tgtTmx || undefined,
            confidence: formData.confidence ?? undefined,
            ownerGroupId: formData.ownerGroupId || undefined,
            srcLang: formData.srcLang ?? undefined,
            tgtLang: formData.tgtLang ?? undefined,
            properties: {
              category: formData.category ?? undefined,
              tmName: formData.tmName || undefined,
            },
            options: {
              limit: 100,
              sort: {
                createdAt: -1,
              },
            },
          },
        },
        {
          useCache: false,
        }
      );
    }
  };

  const handleClearButtonClick = () => {
    setFormData((prev) => ({ ...prev, srcTmx: '', tgtTmx: '' }));
    setFormError(undefined);
  };

  return (
    <Component
      activeTransUnit={activeTransUnit}
      tmEntries={tmEntries}
      loading={loading}
      setFormData={setFormData}
      formData={formData}
      handleSubmit={handleSubmit}
      ownerGroups={ownerGroups ?? []}
      categoryOptions={categoryOptions}
      isExpanded={isExpanded}
      formError={formError}
      handleClearButtonClick={handleClearButtonClick}
      highlightRegExp={highlightRegExp}
    />
  );
};

Container.displayName = 'ConcordanceSearchPanel';
export default Container;
