type Values<T> = T[keyof T];

export const TagDisplayMode = {
  ShowTagId: 'show-tag-id',
  ShowFullTag: 'show-full-tag',
} as const;

export type TagDisplayModeType = Values<typeof TagDisplayMode>;

export type XmlToHtmlOptions = {
  /**
   * ZWSPを先頭に付加します。
   */
  prependZWSP: boolean;
  /**
   * XLIFFのインラインタグも含めてHTML化します。
   */
  includeTags: boolean;
  /**
   * 改行文字列も含めてHTML化します。
   */
  includeNewlines: boolean;
  /**
   * タグの表示方法を決定します。
   */
  tagDisplayMode: TagDisplayModeType;
};

export type XmlToHtml = {
  /**
   * XML文字列をHTML文字列に変換します。
   * @param {String} xml - XML文字列
   * @param {XmlToHtmlOptions} options - オプション
   * @returns {String} - HTML文字列
   */
  (xml: string, options?: Partial<XmlToHtmlOptions>): string;
};

export type HtmlToXml = {
  (html: string): string;
};

export type EditorOptions = {
  isReadOnly: boolean;
  isSourceEditable: boolean;
  tagDisplayMode: TagDisplayModeType;
};

export type EditorEvent<T> = {
  eventId: EventIdType;
  payload: T;
};

export type PropagateSegmentEventPayload = {
  propagateFrom: string;
  source: string;
  target: string;
  state: XliffUnitStateType;
};

export type ChangeSegmentStateEventPayload = {
  tuid: string;
  state: XliffUnitStateType;
};

export type UpdateEditorOptionsEventPayload = {
  editorOptions: EditorOptions;
};

export type UndoEventPayload = {
  tuid: string;
};

export type ConfirmAllSegmentsEventPayload = {
  projectId: string;
  jobId: string;
};

export type DeactivateTransUnitEventPayload = {
  activeTuid: string;
};

export const EventId = {
  UpdateEditorOptions: 'update-editor-options',
  PropagateSegmentUpdate: 'propagate-segment-update',
  Undo: 'undo',
  Redo: 'redo',
  ChangeSegmentState: 'change-segment-state',
  ConfirmAllSegments: 'confirm-all-segments',
  DeactivateTransUnit: 'deacitvate-trans-unit',
  ToggleTMLookupPanelVisibility: 'toggle-tm-lookup-panel-visibility',
  ToggleConcordanceSearchPanelVisibility: 'toggle-concordance-search-panel-visibility',
  ToggleDocumentSearchPanelVisibility: 'toggle-document-search-panel-visibility',
  SelectPrevMatch: 'select-prev-match',
  SelectNextMatch: 'select-next-match',
  ApplySelectedMatch: 'apply-selected-match',
  SelectPrevConcordanceSearchResult: 'select-prev-concordance-search-result',
  SelectNextConcordanceSearchResult: 'select-next-concordance-search-result',
  ApplySelectedConcordanceSearchResult: 'apply-selected-concordance-search-result',
  ShowGoToSegmentDialog: 'show-go-to-segment-dialog',
} as const;

export type EventIdType = Values<typeof EventId>;

export type ConfirmAllSegmentsResponse = {
  jobId: string;
  jobName: string;
  batch: {
    _id: string;
    resourceId: string;
    createdAt: string;
    name: string;
  };
};

export interface EditorCommandManager {
  copy(e?: React.ClipboardEvent): Promise<void>;
  cut(e?: React.ClipboardEvent): Promise<void>;
  paste(e?: React.ClipboardEvent): Promise<void>;
  insertTag(): void;
  toggleTagMode(): void;
  moveToPreviousSegment(): void;
  moveToNextSegment(): void;
  undo(): void;
  redo(): void;
  confirmSegment(): void;
  confirmAllSegments(projectId: string, jobId: string): void;
  toggleTMLookupPanelVisibility(): void;
  toggleConcordanceSearchPanelVisibility(): void;
  toggleDocumentSearchPanelVisibility(): void;
  selectPrevMatch(): void;
  selectNextMatch(): void;
  applySelectedMatch(): void;
  selectPrevConcordanceSearchResult(): void;
  selectNextConcordanceSearchResult(): void;
  applySelectedConcordanceSearchResult(): void;
  showGoToSegmentDialog(): void;
  readonly editorOptions: EditorOptions;
}

export const XliffUnitState = {
  Final: 'final',
  NeedsAdaptation: 'needs-adaptation',
  NeedsL10n: 'needs-l10n',
  NeedsReviewAdaptation: 'needs-review-adaptation',
  NeedsReviewL10n: 'needs-review-l10n',
  NeedsReviewTranslation: 'needs-review-translation',
  NeedsTranslation: 'needs-translation',
  New: 'new',
  SignedOff: 'signed-off',
  Translated: 'translated',
} as const;

export type XliffUnitStateType = Values<typeof XliffUnitState>;

export const XliffUnitStateQualifier = {
  ExactMatch: 'exact-match',
  FuzzyMatch: 'fuzzy-match',
  IdMatch: 'id-match',
  LeveragedGlossary: 'leveraged-glossary',
  LeveragedInherited: 'leveraged-inherited',
  LeveragedMt: 'leveraged-mt',
  LeveragedRepository: 'leveraged-repository',
  LeveragedTm: 'leveraged-tm',
  MtSuggestion: 'mt-suggestion',
  RejectedGrammar: 'rejected-grammar',
  RejectedInaccurate: 'rejected-inaccurate',
  RejectedLength: 'rejected-length',
  RejectedSpelling: 'rejected-spelling',
  TmSuggestion: 'tm-suggestion',
  XPerfectMatch: 'x-perfect-match',
  XNoMatch: 'x-no-match',
  XNonTranslatable: 'x-non-translatable',
  XRepetition: 'x-repetition',
} as const;

export type XliffUnitStateQualifierType = Values<typeof XliffUnitStateQualifier>;

export type XliffSegment = {
  text: string;
  // TM照合画面のDIFFにはこちらを表示する
  textNormalized?: string;
  langCode: string;
};

export type XliffUnit = {
  position: number;
  _id: string;
  source: XliffSegment;
  target: XliffSegment;
  state: XliffUnitStateType;
  stateQualifier: XliffUnitStateQualifierType;
  translate: boolean;
  isLocked: boolean; // translate === 'no' または stateQualifier === 'x-non-translatable'
  properties?: { [key: string]: any };
  level: string;
};

export type TmxSegment = {
  text: string;
  langCode: string;
};

export type TmxUnit = {
  position: number;
  _id: string;
  source: TmxSegment;
  targets: TmxSegment[];
  properties?: { [key: string]: any };
};

export type XliffJob = {
  _id: string;
  name: string;
  srcLang: string;
  tgtLang: string;
  createdAt: Date;
  updatedAt: Date;
  projectId: string;
  project: XliffProject;
  properties: {
    anken_id: string;
    category_code: string;
    brand_code: string;
    from_subsidiary_code: string;
    to_subsidiary_code: string;
  };
};

export type OwnerGroup = {
  _id: string;
  name: string;
};

export type XliffProject = {
  _id: string;
  name: string;
  unitsPerXliff?: number;
  ownerGroupId: string;
  ownerGroup?: OwnerGroup;
};

export const FieldCategory = {
  Text: 'text',
  Segment: 'segment',
  Number: 'number',
  Select: 'select',
};

export type FieldCategoryType = Values<typeof FieldCategory>;

export type GroupDefinition = {
  name: string;
  label: string;
  position: number;
};

export type TextDefinition = {
  type: 'text';
  usePlaceholderAsLabel?: boolean;
  helpText?: string;
  columnWidth?: string;
  fontSize?: string;
};

export type SegmentDefinition = {
  type: 'segment';
  usePlaceholderAsLabel?: boolean;
  helpText?: string;
  isSource: boolean;
  langCode?: string; // 使ってない
};

export type NumberDefinition = {
  type: 'number';
  min?: number;
  max?: number;
  step?: number;
  mode?: 'decimal' | 'currency';
  usePlaceholderAsLabel?: boolean;
  helpText?: string;
  columnWidth?: string;
};

export type SelectDefinition<T> = {
  type: 'select';
  options?: T[];
  optionLabel: string;
  editable?: boolean;
  usePlaceholderAsLabel?: boolean;
  helpText?: string;
  showClear?: boolean;
  columnWidth?: string;
};

export type FieldDefinition<T = unknown> = {
  field: string;
  label: string;
  groupName?: string;
  isReadonlyCell?: boolean;
  definition: TextDefinition | SegmentDefinition | NumberDefinition | SelectDefinition<T>;
};

export const XliffDefaultPropertyName = {
  State: 'state',
  StateQualifier: 'stateQualifier',
  IsLocked: 'isLocked',
} as const;

export type XliffUnitSelection = {
  activeUnit: XliffUnit | null;
  selectedUnits: XliffUnit[];
};

export type CatResults = {
  selectedTMEntry: TMEntry | null;
  selectedConcordanceSearchEntry: TMEntry | null;
};

export type Vendor = {
  _id: string;
  name: string;
};

export type TMEntry = {
  _id: string;
  srcLang: string;
  tgtLang: string;
  srcTmx: string;
  tgtTmx: string;
  srcMasked: string;
  tgtMasked: string;
  level: string;
  vendorId: string;
  ownerGroupId: string;
  confidence: number;
  properties: {
    category: string;
    categoryLabel?: string;
    tmName: string;
    tmType: string;
  };
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  matchScore: number;
  score: number;
  ownerGroup?: OwnerGroup;
  vendor?: Vendor;
  job?: XliffJob;
  penalty?: number;
  penaltyReasons: { [key: string]: unknown }[];
  active: boolean;
};

export type PanelSizes = [number, number];

export type CategoryMapping = {
  _id: string;
  categoryCodes: string[];
  createdAt: string;
  index: string;
  name: string;
  ownerGroupId: string;
  srcLang: string;
  tgtLang: string;
  updatedAt: string;
};
