import platform from 'platform';

export const isFirefox = (): boolean => platform.name === 'Firefox';
export const isChrome = (): boolean => platform.name === 'Chrome';
export const isEdge = (): boolean => platform.name === 'Microsoft Edge';
export const isSafari = (): boolean => platform.name === 'Safari';

export const isMac = (): boolean => (platform.os ? platform.os.toString().includes('OS X') : false);
export const isWin = (): boolean =>
  platform.os ? platform.os.toString().includes('Windows') : false;
