import { Button } from 'primereact/button';
import { EditorCommandManager } from '../libs/types';
import { PanelVisibility } from '../XliffEditor/usePanelVisibility';

import './CatPanelToolbar.css';

export type ContainerProps = {
  commandManager: EditorCommandManager | null;
  panelVisibility: PanelVisibility;
};

export type Props = ContainerProps;

export const Component: React.FC<Props> = ({ panelVisibility, commandManager }) => {
  const activeClassNames = 'p-button-rounded p-button-raised p-button-primary';
  const inactiveClassNames = 'p-button-rounded p-button-raised p-button-secondary';

  return (
    <div className="cat-panel-toolbar menubar-base">
      <ul className="cat-panel-toolbar-list">
        <li>
          <Button
            icon="pi pi-search"
            tooltip="TM Matches"
            tooltipOptions={{ position: 'left' }}
            className={
              panelVisibility.tmLookupPanel.isActive ? activeClassNames : inactiveClassNames
            }
            onClick={() => commandManager?.toggleTMLookupPanelVisibility()}
          />
        </li>
        <li>
          <Button
            icon="pi pi-search-plus"
            tooltip="Concordance Search"
            tooltipOptions={{ position: 'left' }}
            className={
              panelVisibility.concordanceSearchPanel.isActive
                ? activeClassNames
                : inactiveClassNames
            }
            onClick={() => commandManager?.toggleConcordanceSearchPanelVisibility()}
          />
        </li>
        <li>
          <Button
            icon="pi pi-filter"
            tooltip="Document Search"
            tooltipOptions={{ position: 'left' }}
            className={
              panelVisibility.documentSearchPanel.isActive ? activeClassNames : inactiveClassNames
            }
            onClick={() => commandManager?.toggleDocumentSearchPanelVisibility()}
          />
        </li>
      </ul>
    </div>
  );
};

const Container: React.FC<ContainerProps> = ({ commandManager, panelVisibility }) => {
  return <Component commandManager={commandManager} panelVisibility={panelVisibility} />;
};

Container.displayName = 'CatPanelToolbar';
export default Container;
