import { HtmlToXml, XmlToHtml, TagDisplayMode } from '../../libs/types';
import { NEWLINE, ZWSP } from './constants';
import { createTagMarker, createNewlineMarker } from './editor-util';
import { escapeHtmlEntities, sanitize } from './string-util';
import { MarkerCategory, TagCategory, TagCategoryType } from './types';

export const htmlToTmx: HtmlToXml = (html) => {
  const div = document.createElement('div');
  div.innerHTML = sanitize(html);
  // テキストノードは<>&をエスケープ、newline-markerは改行文字、tag-wrapperはタグ文字列に変換する
  const tmx = [...div.childNodes]
    .map((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return escapeHtmlEntities(node.textContent ?? '');
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const elem = node as HTMLElement;
        if (!elem.dataset) {
          return '';
        }
        if (elem.dataset.type === MarkerCategory.NewlineMarker) {
          return NEWLINE;
        }
        if (elem.dataset.type !== MarkerCategory.TagWrapper) {
          return '';
        }
        const tag = elem.firstElementChild as HTMLElement;
        if (!tag) {
          return '';
        }
        const tagContent = tag.dataset ? tag.dataset.content ?? '' : '';
        return tagContent;
      }
      return '';
    })
    .join('');
  return tmx;
};

export const ph_regex = /<ph[^<>]*\/>/;
export const ph_paired_regex = /<ph[^<>]*>.*?<\/ph>/;

export const it_regex = /<it[^<>]*\/>/;
export const it_paired_regex = /<it[^<>]*>.*?<\/it>/;

export const bpt_regex = /<bpt[^<>]*\/>/;
export const bpt_paired_regex = /<bpt[^<>]*>.*?<\/bpt>/;

export const ept_regex = /<ept[^<>]*\/>/;
export const ept_paired_regex = /<ept[^<>]*>.*?<\/ept>/;

export const splitRegex = /(<ph[^<>]*\/>)|(<it[^<>]*\/>)|(<[be]pt[^<>]*\/>)|(\n)|(<ph[^<>]*>.*?<\/ph>)|(<it[^<>]*>.*?<\/it>)|(<[be]pt[^<>]*>.*?<\/[be]pt>)/g;

export const isTagString = (value: string): boolean =>
  ph_regex.test(value) ||
  it_regex.test(value) ||
  bpt_regex.test(value) ||
  ept_regex.test(value) ||
  ph_paired_regex.test(value) ||
  it_paired_regex.test(value) ||
  bpt_paired_regex.test(value) ||
  ept_paired_regex.test(value);

const createTag = (id: string, content: string, type: TagCategoryType) => {
  return {
    id,
    content,
    type,
  };
};

const extractTagId = (tagString: string): string => {
  const id = tagString.match(/<(.+?)[\s/]/);
  if (!id) throw new Error('Invalid tag name');
  return id[1];
};

export const tmxToHtml: XmlToHtml = (
  tmx: string,
  {
    prependZWSP = true,
    includeTags = true,
    includeNewlines = true,
    tagDisplayMode = TagDisplayMode.ShowTagId,
  } = {}
): string => {
  const sanitizedTmx = sanitize(tmx);
  const substrings = sanitizedTmx.split(splitRegex).filter((s) => s);

  // TMXはXLIFFと異なり、インラインタグにid属性がないので、phやbptなどのタグ名をidとして使用する。
  const runs = substrings.map((substr, idx) => {
    if (
      ph_regex.test(substr) ||
      it_regex.test(substr) ||
      bpt_regex.test(substr) ||
      ept_regex.test(substr) ||
      ph_paired_regex.test(substr) ||
      it_paired_regex.test(substr) ||
      bpt_paired_regex.test(substr) ||
      ept_paired_regex.test(substr)
    ) {
      if (!includeTags) return '';
      const tagId = extractTagId(substr);
      const tag = createTag(tagId, substr, TagCategory.Empty);
      const marker = createTagMarker(tag, tagDisplayMode);
      return `${marker.outerHTML}`;
    }
    if (substr === '\n') {
      if (!includeNewlines) return '';
      const marker = createNewlineMarker();
      return `${marker.outerHTML}`;
    }
    return substr;
  });
  const html = runs.join('').replace(/\u200B{2,}/g, '\u200B');
  const zwsp = prependZWSP ? ZWSP : '';
  return `${zwsp}${html}`;
};

export const escapeTmx = (tmx: string): string => {
  const sanitizedTmx = sanitize(tmx);
  const substrings = sanitizedTmx.split(splitRegex).filter((s) => s);
  const escapedTmx = substrings
    .map((substr) => {
      if (
        ph_regex.test(substr) ||
        it_regex.test(substr) ||
        bpt_regex.test(substr) ||
        ept_regex.test(substr) ||
        ph_paired_regex.test(substr) ||
        it_paired_regex.test(substr) ||
        bpt_paired_regex.test(substr) ||
        ept_paired_regex.test(substr)
      ) {
        return substr;
      }
      return escapeHtmlEntities(substr);
    })
    .join('');
  return escapedTmx;
};
