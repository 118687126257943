import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import { confirmDialog } from 'primereact/confirmdialog';
import { Menu } from 'primereact/menu';
import { isMac } from '../libs/platform-util';
import { EditorCommandManager, XliffJob } from '../libs/types';

import './AppMenuBar.css';
import { PanelVisibility } from '../XliffEditor/usePanelVisibility';
import { LoginUser, useLoginUser } from '../../libs/useLoginUser';
import ReleaseNotes from '../ReleaseNotes';

const ctrlKey = isMac() ? '⌘' : 'Ctrl';
const shiftKey = isMac() ? '⇧' : 'Shift';
const optionKey = isMac() ? '⌥' : 'Alt';
const ctrlSymbol = isMac() ? '⌃' : 'Ctrl';

const appVersion = process.env.REACT_APP_VERSION || 'unknown version';

type ContainerProps = {
  job?: XliffJob;
  commandManager: EditorCommandManager | null;
  panelVisibility: PanelVisibility;
  onLogOut: () => void;
};

type Props = ContainerProps & {
  loginUser: LoginUser | null;
};

export const Component: React.FC<Props> = ({
  job,
  commandManager,
  panelVisibility,
  loginUser,
  onLogOut,
}) => {
  const [releaseNotesVisible, setReleaseNotesVisible] = useState(false);
  const handleHideReleaseNotes = () => setReleaseNotesVisible(false);

  const menu = useRef<Menu>(null);
  let menubarItems: MenuItem[];
  if (!commandManager) {
    menubarItems = [];
  } else {
    menubarItems = [
      {
        label: 'Edit',
        className: 'edit-menu-list',
        disabled: commandManager.editorOptions.isReadOnly,
        items: [
          {
            label: `Undo (${ctrlKey}+Z)`,
            command: (e) => {
              commandManager.undo();
            },
          },
          {
            label: `Redo (${ctrlKey}+${shiftKey}+Z)`,
            command: (e) => {
              commandManager.redo();
            },
          },
          {
            separator: true,
          },
          {
            label: `Cut (${ctrlKey}+X)`,
            command: async (e) => {
              await commandManager.cut();
            },
          },
          {
            label: `Copy (${ctrlKey}+C)`,
            command: async (e) => {
              await commandManager.copy();
            },
          },
          {
            label: `Paste (${ctrlKey}+V)`,
            command: async (e) => {
              await commandManager.paste();
            },
          },
          {
            separator: true,
          },
          {
            label: `Confirm segment (${ctrlKey}+Enter)`,
            command: async () => {
              await commandManager.confirmSegment();
              commandManager.moveToNextSegment();
            },
          },
          {
            separator: true,
          },
          {
            label: `Select previous match (${optionKey}+Up)`,
            command: async () => {
              if (panelVisibility.tmLookupPanel.isExpanded) {
                commandManager.selectPrevMatch();
              } else if (panelVisibility.concordanceSearchPanel.isExpanded) {
                commandManager.selectPrevConcordanceSearchResult();
              }
            },
          },
          {
            label: `Select next match (${optionKey}+Down)`,
            command: async () => {
              if (panelVisibility.tmLookupPanel.isExpanded) {
                commandManager.selectNextMatch();
              } else if (panelVisibility.concordanceSearchPanel.isExpanded) {
                commandManager.selectNextConcordanceSearchResult();
              }
            },
          },
          {
            label: `Apply selected match (${optionKey}+,)`,
            command: async () => {
              if (panelVisibility.tmLookupPanel.isExpanded) {
                commandManager.applySelectedMatch();
              } else if (panelVisibility.concordanceSearchPanel.isExpanded) {
                commandManager.applySelectedConcordanceSearchResult();
              }
            },
          },
          {
            separator: true,
          },
          {
            label: `Insert tag (${ctrlSymbol}+,)`,
            command: (e) => {
              commandManager.insertTag();
            },
          },
        ],
      },
      {
        label: 'View',
        className: 'view-menu-list',
        items: [
          {
            label: 'Expand/Collapse tags (F1)',
            command: () => {
              commandManager.toggleTagMode();
            },
          },
          {
            label: 'Show TM Matches panel (F2)',
            command: () => {
              commandManager.toggleTMLookupPanelVisibility();
            },
          },
          {
            label: 'Show Concordance Search panel (F3)',
            command: () => {
              commandManager.toggleConcordanceSearchPanelVisibility();
            },
          },
          {
            label: `Show Document Search panel (F4 or ${ctrlKey}+F)`,
            command: () => {
              commandManager.toggleDocumentSearchPanelVisibility();
            },
          },
        ],
      },
      {
        label: 'Navigation',
        className: 'navigation-menu-list',
        disabled: commandManager.editorOptions.isReadOnly,
        items: [
          {
            label: `Go to previous segment (${ctrlKey}+Up)`,
            command: () => {
              commandManager.moveToPreviousSegment();
            },
          },
          {
            label: `Go to next segment (${ctrlKey}+Down)`,
            command: () => {
              commandManager.moveToNextSegment();
            },
          },
          {
            label: `Go to segment... (${ctrlKey}+G)`,
            command: () => {
              commandManager.showGoToSegmentDialog();
            },
          },
        ],
      },
      {
        label: 'Job',
        className: 'job-menu-list',
        disabled: commandManager.editorOptions.isReadOnly,
        items: [
          {
            label: `Confirm all segments`,
            command: async () => {
              if (job?.projectId && job?._id) {
                confirmDialog({
                  header: 'Confirm all segments',
                  icon: 'pi pi-info-circle',
                  message:
                    'DB will be updated with the current translations. All the segments in this job will be set to "translated". \n' +
                    'A translation kit will be automatically recreated.',
                  accept: () => {
                    commandManager.confirmAllSegments(job.projectId, job._id);
                  },
                });
              } else {
                console.log('Confirm all segments: missing projectId or jobId');
              }
            },
            disabled: !job?.projectId || !job?._id,
          },
        ],
      },
      {
        label: 'Help',
        className: 'help-menu-list',
        items: [
          {
            label: 'Release notes',
            command: () => {
              setReleaseNotesVisible(true);
            },
          },
        ],
      },
    ];
  }
  const userMenuItems: MenuItem[] = [
    {
      label: 'Log out',
      command: async () => {
        await onLogOut();
      },
    },
  ];

  const end = (
    <>
      <Menu model={userMenuItems} popup ref={menu} id="user-menu" />
      <Button
        label={loginUser?.username ?? ''}
        onClick={(event) => menu.current?.toggle(event)}
        aria-controls="user-menu"
        aria-haspopup
        className="p-button-text p-button-plain"
        icon="pi pi-chevron-down"
        iconPos="right"
      />
    </>
  );
  return (
    <>
      <StyledMenubar className="menubar-base app-menubar" end={end} model={menubarItems} />
      {releaseNotesVisible && (
        <ReleaseNotes onHide={handleHideReleaseNotes} appVersion={`${appVersion}`} />
      )}
    </>
  );
};

const Container: React.FC<ContainerProps> = (props) => {
  const loginUser = useLoginUser();
  return <Component loginUser={loginUser} {...props} />;
};

const StyledMenubar = styled(Menubar)`
  flex-grow: 1;
`;

Container.displayName = 'AppMenuBar';

export default Container;
