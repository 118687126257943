import TransUnit from '../XliffUnit';
import { FieldDefinition, XliffDefaultPropertyName, XliffJob, XliffUnit } from '../libs/types';
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';

import './XliffGrid.css';
import { isSegmentDefinition, sortFieldDefinitions } from '../libs/common-util';
import { useFieldDefinitions } from '../libs/hooks/useFieldDefinitions';
import { useGroupDefinitions } from '../libs/hooks/useGroupDefinitions';
import { FetchNext, FetchPrev } from '../libs/hooks/useXliffUnits';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Messages } from '../libs/messages';
import { isFirefox } from '../libs/platform-util';

export type ContainerProps = {
  fetchPrevTransUnits: FetchPrev;
  fetchNextTransUnits: FetchNext;
  hasPrevTransUnits: boolean;
  hasNextTransUnits: boolean;
  isLoadingPrevTransUnits: boolean;
  isLoadingNextTransUnits: boolean;
  isFetchingNeighboringUnits: boolean;
  job?: XliffJob;
  transUnits: XliffUnit[];
};

export type Props = Omit<
  ContainerProps,
  'fetchPrevTransUnits' | 'fetchNextTransUnits' | 'hasPrevTransUnits' | 'hasNextTransUnits'
> & {
  prevRef: React.MutableRefObject<HTMLTableRowElement | null>;
  nextRef: React.MutableRefObject<HTMLTableRowElement | null>;
  groupedFieldDefs: { [key: string]: FieldDefinition<unknown>[] };
};

const Header: React.FC<{
  job?: XliffJob;
  groupedFieldDefs: { [key: string]: FieldDefinition<unknown>[] };
}> = ({ job, groupedFieldDefs }) => {
  if (!groupedFieldDefs)
    return (
      <thead>
        <tr></tr>
      </thead>
    );

  const positionColumn = (
    <th
      key="position"
      className="generic-col"
      data-coltype="position"
      style={{ width: '100px' }}
    ></th>
  );
  const otherColumns = Object.keys(groupedFieldDefs).map((gropuName) => {
    const columns = groupedFieldDefs[gropuName].map((fieldDef) => {
      if (isSegmentDefinition(fieldDef.definition)) {
        const { isSource } = fieldDef.definition;
        if (isSource) {
          return (
            <th key={fieldDef.field} className="source-col" data-coltype="source">
              {job?.srcLang}
            </th>
          );
        } else {
          return (
            <th key={fieldDef.field} className="target-col" data-coltype="target">
              {job?.tgtLang}
            </th>
          );
        }
      } else {
        const fieldName = fieldDef.field;
        if (fieldName === XliffDefaultPropertyName.State) {
          return (
            <th
              key={fieldName}
              className="generic-col"
              data-coltype="state"
              style={{ width: fieldDef.definition.columnWidth }}
            >
              {fieldDef.label}
            </th>
          );
        }
        if (fieldName === XliffDefaultPropertyName.StateQualifier) {
          return (
            <th
              key={fieldName}
              className="generic-col"
              data-coltype="state-qualifier"
              style={{ width: fieldDef.definition.columnWidth }}
            >
              {fieldDef.label}
            </th>
          );
        }
        if (fieldName === XliffDefaultPropertyName.IsLocked) {
          return (
            <th
              key={fieldName}
              className="generic-col"
              data-coltype="locked"
              style={{ width: fieldDef.definition.columnWidth }}
            >
              {fieldDef.label}
            </th>
          );
        }
        return (
          <th
            className="generic-col"
            data-coltype="custom"
            key={fieldName}
            style={{ width: fieldDef.definition.columnWidth }}
          >
            {fieldDef.label}
          </th>
        );
      }
    });
    return columns.filter((c): c is JSX.Element => c != null);
  });
  return (
    <thead className="xliff-grid-header">
      <tr>{[positionColumn, ...otherColumns.flat()]}</tr>
    </thead>
  );
};

const Content: React.FC<{
  prevRef: React.MutableRefObject<HTMLTableRowElement | null>;
  nextRef: React.MutableRefObject<HTMLTableRowElement | null>;
  transUnits: XliffUnit[];
  isLoadingPrevTransUnits: boolean;
  isLoadingNextTransUnits: boolean;
  isFetchingNeighboringUnits: boolean;
}> = ({
  prevRef,
  nextRef,
  transUnits,
  isLoadingPrevTransUnits,
  isLoadingNextTransUnits,
  isFetchingNeighboringUnits,
  ...otherProps
}) => {
  const [columnsCount, setColumnsCount] = useState(0);
  useEffect(() => {
    const columns = document.querySelectorAll('.xliff-grid-header > tr > th');
    setColumnsCount(columns.length);
  }, []);

  return (
    <tbody>
      <tr data-rowtype="load-marker-prev" ref={prevRef}></tr>
      <tr style={isLoadingPrevTransUnits ? { visibility: 'visible' } : { display: 'none' }}>
        <td className="loading-prev-msg-cell" colSpan={columnsCount}>
          <ProgressSpinner />
          {Messages.LoadingPrevContent}
        </td>
      </tr>
      {!isFetchingNeighboringUnits &&
        transUnits.map((unit) => {
          return <TransUnit key={unit._id} transUnit={unit} {...otherProps} />;
        })}
      <tr style={isLoadingNextTransUnits ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <td className="loading-next-msg-cell" colSpan={columnsCount}>
          <ProgressSpinner />
          {Messages.LoadingNextContent}
        </td>
      </tr>
      <tr data-rowtype="load-marker-next" ref={nextRef}></tr>
    </tbody>
  );
};

export const Component: React.FC<Props> = ({
  job,
  prevRef,
  nextRef,
  transUnits,
  groupedFieldDefs,
  isLoadingPrevTransUnits,
  isLoadingNextTransUnits,
  ...otherProps
}) => {
  return (
    <div className="xliff-grid-wrapper">
      <table className="xliff-grid">
        <Header job={job} groupedFieldDefs={groupedFieldDefs} />
        <Content
          prevRef={prevRef}
          nextRef={nextRef}
          transUnits={transUnits}
          isLoadingPrevTransUnits={isLoadingPrevTransUnits}
          isLoadingNextTransUnits={isLoadingNextTransUnits}
          {...otherProps}
        />
      </table>
    </div>
  );
};

const Container: React.FC<ContainerProps> = ({
  fetchPrevTransUnits,
  fetchNextTransUnits,
  hasPrevTransUnits,
  hasNextTransUnits,
  isLoadingPrevTransUnits,
  isLoadingNextTransUnits,
  transUnits,
  ...otherProps
}) => {
  const prevRef = useRef<HTMLTableRowElement | null>(null);
  const nextRef = useRef<HTMLTableRowElement | null>(null);
  const firstRow = useRef<HTMLTableRowElement | null>(null);
  const lastRow = useRef<HTMLTableRowElement | null>(null);
  const { fieldDefinitions } = useFieldDefinitions();
  const { groupDefinitions } = useGroupDefinitions();
  const groupedFieldDefs = useMemo(() => {
    return sortFieldDefinitions(fieldDefinitions, groupDefinitions);
  }, [fieldDefinitions, groupDefinitions]);

  useEffect(() => {
    if (!isLoadingPrevTransUnits) {
      // previous transunitsの取得完了後、取得前の最初の行にスクロール
      firstRow.current?.scrollIntoView(true);
    }
  }, [isLoadingPrevTransUnits]);

  useEffect(() => {
    // Firefoxの場合のみnext transunitsの取得後、スクロール調整が必要
    if (!isLoadingNextTransUnits && transUnits.length > 0 && isFirefox()) {
      lastRow.current?.scrollIntoView(false);
    }
  }, [isLoadingNextTransUnits, transUnits.length]);

  const loadPrev: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        hasPrevTransUnits &&
        !isLoadingPrevTransUnits &&
        !isLoadingNextTransUnits
      ) {
        // previous transunits取得前に、現在の最初の行を取得しておき、後でこの行にスクロールする
        firstRow.current = prevRef.current?.nextElementSibling
          ?.nextElementSibling as HTMLTableRowElement;
        fetchPrevTransUnits();
      }
    },
    [fetchPrevTransUnits, hasPrevTransUnits, isLoadingNextTransUnits, isLoadingPrevTransUnits]
  );

  const loadNext: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        hasNextTransUnits &&
        !isLoadingPrevTransUnits &&
        !isLoadingNextTransUnits
      ) {
        lastRow.current = nextRef.current?.previousElementSibling
          ?.previousElementSibling as HTMLTableRowElement;
        fetchNextTransUnits();
      }
    },
    [fetchNextTransUnits, hasNextTransUnits, isLoadingNextTransUnits, isLoadingPrevTransUnits]
  );

  useEffect(() => {
    if (!prevRef.current) return;
    const observingEl = prevRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(loadPrev, options);
    observer.observe(observingEl);

    return () => {
      observer.unobserve(observingEl);
    };
  }, [loadPrev]);

  useEffect(() => {
    if (!nextRef.current) return;
    const observingEl = nextRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(loadNext, options);
    observer.observe(observingEl);

    return () => {
      observer.unobserve(observingEl);
    };
  }, [loadNext]);

  return (
    <Component
      prevRef={prevRef}
      nextRef={nextRef}
      groupedFieldDefs={groupedFieldDefs}
      isLoadingPrevTransUnits={isLoadingPrevTransUnits}
      isLoadingNextTransUnits={isLoadingNextTransUnits}
      transUnits={transUnits}
      {...otherProps}
    />
  );
};

Container.displayName = 'XliffGrid';
export default Container;
