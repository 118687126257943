import * as KeyCode from 'keycode-js';
import React from 'react';
import {
  atEndOfSegment,
  atStartOfSegment,
  getCharacterAfterCaret,
  getCharacterPrecedingCaret,
  hasSelection,
  moveCaret,
  moveCaretBefore,
  moveCaretToEndOfSegment,
  moveCaretToStartOfSegment,
  removeSelection,
} from './editor-util';
import { getParentNewlineMarker } from './newline-marker-util';
import { getParentTagWrapper, removeSelectedTagWrappers } from './tag-wrapper-util';
import { isFirefox, isMac } from '../../libs/platform-util';
import { ZWSP, NEWLINE } from './constants';
import { EditorCommandManager } from '../../libs/types';

// TODO: XliffEditor/useKeyboardEventHandler.ts同様にhooksにする？
export class SegmentKeyboardEventHandler {
  constructor(private _commandManager: EditorCommandManager) {}

  private handleKeyDownCommon(e: React.KeyboardEvent<HTMLTableDataCellElement>) {
    switch (e.key) {
      case KeyCode.VALUE_ENTER: {
        e.preventDefault();
        break;
      }
      case KeyCode.VALUE_F1: {
        // XliffEditor/index.tsxでハンドリング
        e.preventDefault();
        break;
      }
      case KeyCode.VALUE_F2: {
        // XliffEditor/index.tsxでハンドリング
        e.preventDefault();
        break;
      }
      case KeyCode.VALUE_F3: {
        // XliffEditor/index.tsxでハンドリング
        e.preventDefault();
        break;
      }
      case KeyCode.VALUE_LEFT: {
        if (atStartOfSegment()) {
          e.preventDefault();
          break;
        }
        const prevChar = getCharacterPrecedingCaret();
        if (prevChar === ZWSP) {
          moveCaret(-1);
        }
        const tagWrapper = getParentTagWrapper();
        if (tagWrapper) {
          moveCaretBefore(tagWrapper);
        }
        break;
      }
      case KeyCode.VALUE_RIGHT: {
        const nextChar = getCharacterAfterCaret();
        if (nextChar === ZWSP) {
          moveCaret(1);
        }
        break;
      }
      case KeyCode.VALUE_UP: {
        if (atStartOfSegment()) {
          e.preventDefault();
          this._commandManager.moveToPreviousSegment();
        }
        break;
      }
      case KeyCode.VALUE_DOWN: {
        if (atEndOfSegment()) {
          e.preventDefault();
          this._commandManager.moveToNextSegment();
        }
        break;
      }
      case KeyCode.VALUE_DELETE: {
        e.preventDefault();
        document.execCommand('forwardDelete');
        removeSelectedTagWrappers();
        break;
      }
      case KeyCode.VALUE_BACK_SPACE: {
        if (atStartOfSegment()) {
          console.log('111');
          e.preventDefault();
          break;
        }
        if (hasSelection()) {
          console.log('222');
          removeSelection();
          removeSelectedTagWrappers();
          e.preventDefault();
          break;
        }

        if (isFirefox()) {
          const tagWrapper = getParentTagWrapper();
          if (tagWrapper) {
            tagWrapper.parentElement?.removeChild(tagWrapper);
            e.preventDefault();
          }
        }
        const prevChar = getCharacterPrecedingCaret();
        if (prevChar === ZWSP) {
          console.log('333');
          moveCaret(-1);
          const tagWrapper = getParentTagWrapper();
          if (tagWrapper) {
            tagWrapper.parentElement?.removeChild(tagWrapper);
            console.log('tagWrapper deleted');
          }
          // win chrome、mac firefox
          const newlineMarker = getParentNewlineMarker();
          if (newlineMarker) {
            newlineMarker.parentElement?.removeChild(newlineMarker);
            console.log('newlineMarker deleted');
          }
          e.preventDefault();
        } else if (prevChar === NEWLINE) {
          console.log('444');
          const newlineMarker = getParentNewlineMarker();
          if (newlineMarker) {
            newlineMarker.parentElement?.removeChild(newlineMarker);
            console.log('newlineMarker deleted');
          }
          e.preventDefault();
        }
        break;
      }
    }
  }

  private isAllowedKeyInReadOnly = (e: React.KeyboardEvent<HTMLTableDataCellElement>): boolean => {
    if (
      [
        KeyCode.VALUE_UP,
        KeyCode.VALUE_DOWN,
        KeyCode.VALUE_LEFT,
        KeyCode.VALUE_RIGHT,
        KeyCode.VALUE_F1,
        KeyCode.VALUE_TAB,
      ].includes(e.key)
    ) {
      return true;
    }
    if (isMac()) {
      if (e.metaKey && e.altKey) {
        // dev tools
        switch (e.code) {
          case KeyCode.CODE_I: {
            return true;
          }
        }
      } else if (e.metaKey) {
        switch (e.key) {
          case KeyCode.VALUE_C:
          case KeyCode.VALUE_R:
          case KeyCode.VALUE_A: {
            return true;
          }
        }
      }
    } else {
      if (e.ctrlKey && e.shiftKey) {
        // dev tools
        switch (e.code) {
          case KeyCode.CODE_I: {
            return true;
          }
        }
      } else if (e.ctrlKey) {
        switch (e.key) {
          case KeyCode.VALUE_C:
          case KeyCode.VALUE_R:
          case KeyCode.VALUE_A: {
            return true;
          }
        }
      } else {
        switch (e.key) {
          case KeyCode.VALUE_F5: {
            return true;
          }
        }
      }
    }

    return false;
  };

  private async handleKeyDownMac(
    e: React.KeyboardEvent<HTMLTableDataCellElement>,
    isReadOnly: boolean
  ) {
    if (isReadOnly) {
      if (!this.isAllowedKeyInReadOnly(e)) {
        e.preventDefault();
        return;
      }
    }

    if (e.shiftKey && e.metaKey) {
      switch (e.key) {
        case KeyCode.VALUE_Z: {
          e.preventDefault();
          this._commandManager.redo();
          break;
        }
      }
    } else if (e.shiftKey) {
      switch (e.key) {
        case KeyCode.VALUE_ENTER: {
          e.preventDefault();
          // const newlineMarker = createNewlineMarker();
          // insertNodeAtCursor(newlineMarker);
          break;
        }
      }
    } else if (e.ctrlKey) {
      switch (e.key) {
        case KeyCode.VALUE_COMMA: {
          e.preventDefault();
          this._commandManager.insertTag();
          break;
        }
        case KeyCode.VALUE_A:
        case KeyCode.VALUE_B:
        case KeyCode.VALUE_H:
        case KeyCode.VALUE_E:
        case KeyCode.VALUE_K:
        case KeyCode.VALUE_F:
        case KeyCode.VALUE_U: {
          e.preventDefault();
          break;
        }
      }
    } else if (e.altKey) {
      switch (e.code) {
        case KeyCode.CODE_UP: {
          // XliffEditor/index.tsxでハンドリング
          e.preventDefault();
          break;
        }
        case KeyCode.CODE_DOWN: {
          // XliffEditor/index.tsxでハンドリング
          e.preventDefault();
          break;
        }
        case KeyCode.CODE_COMMA: {
          // XliffEditor/index.tsxでハンドリング
          e.preventDefault();
          break;
        }
      }
    } else if (e.metaKey) {
      switch (e.key) {
        case KeyCode.VALUE_UP: {
          e.preventDefault();
          this._commandManager.moveToPreviousSegment();
          break;
        }
        case KeyCode.VALUE_DOWN: {
          e.preventDefault();
          this._commandManager.moveToNextSegment();
          break;
        }
        case KeyCode.VALUE_B:
        case KeyCode.VALUE_I: {
          e.preventDefault();
          break;
        }
        case KeyCode.VALUE_Z: {
          e.preventDefault();
          this._commandManager.undo();
          break;
        }
        case KeyCode.VALUE_ENTER: {
          e.preventDefault();
          this._commandManager.confirmSegment();
          // this._commandManager.moveToNextSegment();
          break;
        }
      }
    } else {
      switch (e.key) {
        case KeyCode.VALUE_HOME: {
          // Winの場合はネイティブのHomeキーを使用する
          // WinとMacで若干動作が異なる
          // Win: キャレットよりも前に改行がある場合は改行にジャンプ。改行がなければセグメントの先頭にジャンプ
          // Mac: セグメントの先頭にジャンプ
          e.preventDefault();
          moveCaretToStartOfSegment();
          break;
        }
        case KeyCode.VALUE_END: {
          // Winの場合はネイティブのEndキーを使用する
          // WinとMacで若干動作が異なる
          // Win: キャレットよりも後に改行がある場合は改行にジャンプ。改行がなければセグメントの末尾にジャンプ
          // Mac: セグメントの末尾にジャンプ
          e.preventDefault();
          moveCaretToEndOfSegment();
          break;
        }
        default: {
          this.handleKeyDownCommon(e);
        }
      }
    }
  }

  private async handleKeyDownWin(
    e: React.KeyboardEvent<HTMLTableDataCellElement>,
    isReadOnly: boolean
  ) {
    if (isReadOnly) {
      if (!this.isAllowedKeyInReadOnly(e)) {
        e.preventDefault();
        return;
      }
    }

    if (e.shiftKey && e.ctrlKey) {
      switch (e.key.toLocaleLowerCase()) {
        case KeyCode.VALUE_Z: {
          e.preventDefault();
          this._commandManager.redo();
          break;
        }
      }
    } else if (e.shiftKey) {
      switch (e.key) {
        case KeyCode.VALUE_ENTER: {
          e.preventDefault();
          // const newlineMarker = createNewlineMarker();
          // insertNodeAtCursor(newlineMarker);
          break;
        }
      }
    } else if (e.ctrlKey) {
      switch (e.key) {
        case KeyCode.VALUE_UP: {
          e.preventDefault();
          this._commandManager.moveToPreviousSegment();
          break;
        }
        case KeyCode.VALUE_DOWN: {
          e.preventDefault();
          this._commandManager.moveToNextSegment();
          break;
        }
        case KeyCode.VALUE_B:
        case KeyCode.VALUE_I:
        case KeyCode.VALUE_U: {
          e.preventDefault();
          break;
        }
        case KeyCode.VALUE_Z: {
          e.preventDefault();
          this._commandManager.undo();
          break;
        }
        case KeyCode.VALUE_ENTER: {
          e.preventDefault();
          this._commandManager.confirmSegment();
          // this._commandManager.moveToNextSegment();
          break;
        }
        case KeyCode.VALUE_COMMA: {
          e.preventDefault();
          this._commandManager.insertTag();
          break;
        }
      }
    } else if (e.altKey) {
      switch (e.code) {
        case KeyCode.CODE_UP: {
          // XliffEditor/index.tsxでハンドリング
          e.preventDefault();
          break;
        }
        case KeyCode.CODE_DOWN: {
          // XliffEditor/index.tsxでハンドリング
          e.preventDefault();
          break;
        }
        case KeyCode.CODE_COMMA: {
          // XliffEditor/index.tsxでハンドリング
          e.preventDefault();
          break;
        }
      }
    } else {
      this.handleKeyDownCommon(e);
    }
  }

  async handleKeyDown(
    e: React.KeyboardEvent<HTMLTableDataCellElement>,
    isReadOnly: boolean
  ): Promise<void> {
    if (isMac()) {
      await this.handleKeyDownMac(e, isReadOnly);
    } else {
      await this.handleKeyDownWin(e, isReadOnly);
    }
  }
}
