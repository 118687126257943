import { createContext, useMemo, useReducer, useContext } from 'react';
import { GroupDefinition } from '../types';

export const initialState: GroupDefinition[] = [];

export type Action = { type: 'UPDATE'; payload: GroupDefinition[] } | { type: 'RESET' };

export const reducer = (state: GroupDefinition[], action: Action): GroupDefinition[] => {
  switch (action.type) {
    case 'UPDATE': {
      return [...state, ...action.payload];
    }
    case 'RESET': {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
};

type UpdateGroupDefinitions = (payload: GroupDefinition[]) => void;
type ResetGroupDefinitions = () => void;
type ContextType = {
  groupDefinitions: GroupDefinition[];
  updateGroupDefinitions: UpdateGroupDefinitions;
  resetGroupDefinitions: ResetGroupDefinitions;
};

export const GroupDefinitionsContext = createContext<ContextType | null>(null);

type Props = {
  initialGroupDefinitions?: GroupDefinition[];
};

export const GroupDefinitionsContextProvider: React.FC<Props> = ({
  initialGroupDefinitions = initialState,
  ...otherProps
}) => {
  const [groupDefinitions, dispatch] = useReducer(reducer, initialGroupDefinitions);

  const updateGroupDefinitions: UpdateGroupDefinitions = (payload) =>
    dispatch({ type: 'UPDATE', payload });
  const resetGroupDefinitions: ResetGroupDefinitions = () => dispatch({ type: 'RESET' });

  const value = useMemo(
    () => ({
      groupDefinitions,
      updateGroupDefinitions,
      resetGroupDefinitions,
    }),
    [groupDefinitions]
  );

  return <GroupDefinitionsContext.Provider value={value} {...otherProps} />;
};

export const useGroupDefinitions = (): ContextType => {
  const context = useContext(GroupDefinitionsContext);

  if (typeof context === 'undefined') {
    throw new Error('useGroupDefinitions must be used within GroupDefnitionsContext');
  }

  return context as ContextType;
};
