import { useCallback } from 'react';
import { createContext, useMemo, useReducer, useContext } from 'react';
import { EditorEvent } from '../types';

export type Action = { type: 'UPDATE'; event: EditorEvent<unknown> } | { type: 'RESET' };

export const reducer = (
  state: EditorEvent<unknown> | null,
  action: Action
): EditorEvent<unknown> | null => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.event,
      };
    }
    case 'RESET': {
      return null;
    }
    default: {
      return state;
    }
  }
};

export type RaiseEditorEvent = (event: EditorEvent<unknown>) => void;
export type ClearEditorEvent = () => void;
type ContextType = {
  editorEvent: EditorEvent<unknown> | null;
  raiseEditorEvent: RaiseEditorEvent;
  clearEditorEvent: ClearEditorEvent;
};

export const EditorEventContext = createContext<ContextType | null>(null);

export const EditorEventContextProvider: React.FC = (props) => {
  const [editorEvent, dispatch] = useReducer(reducer, null);

  const raiseEditorEvent: RaiseEditorEvent = useCallback(
    (event) => dispatch({ type: 'UPDATE', event }),
    []
  );
  const clearEditorEvent: ClearEditorEvent = useCallback(() => dispatch({ type: 'RESET' }), []);

  const value = useMemo(
    () => ({
      editorEvent,
      raiseEditorEvent,
      clearEditorEvent,
    }),
    [clearEditorEvent, editorEvent, raiseEditorEvent]
  );

  return <EditorEventContext.Provider value={value} {...props} />;
};

export const useEditorEvent = (): ContextType => {
  const context = useContext(EditorEventContext);

  if (typeof context === 'undefined') {
    throw new Error('useEditorEvent must be used within EditorEventContext');
  }

  return context as ContextType;
};
