import { rest } from 'msw';

// const units = Array.from({ length: 320 }, (_, i) => i).map((i) => ({
//   _id: `${i + 1}`,
//   srcXliff: `${i + 1}`,
//   tgtXliff: `${i + 1}`,
//   srcLang: 'ja',
//   tgtLang: 'en',
//   state: 'new',
//   stateQualifier: 'mt-suggestion',
//   translate: 'yes',
// }));

const units = [
  {
    _id: 'u1',
    srcXliff:
      'メロン<ph ctype="x-span" id="span-1">&lt;span class="m_sp10"&gt;</ph>トマト<ph ctype="x-/span" id="/span-2">&lt;/span&gt;</ph>ミカン',
    tgtXliff: '',
    srcLang: 'ja',
    tgtLang: 'en',
    state: 'new',
    stateQualifier: 'mt-suggestion',
    translate: 'yes',
  },
  {
    _id: 'u2',
    srcXliff:
      'メロン<ph ctype="x-span" id="span-1">&lt;span class="m_sp10"&gt;</ph><ph ctype="x-/span" id="/span-2">&lt;/span&gt;</ph>',
    tgtXliff: '',
    srcLang: 'ja',
    tgtLang: 'en',
    state: 'new',
    stateQualifier: 'mt-suggestion',
    translate: 'yes',
  },
  {
    _id: 'u3',
    srcXliff:
      '<ph ctype="x-span" id="span-1">&lt;span class="m_sp10"&gt;</ph><ph ctype="x-/span" id="/span-2">&lt;/span&gt;</ph>ミカン',
    tgtXliff: '',
    srcLang: 'ja',
    tgtLang: 'en',
    state: 'new',
    stateQualifier: 'mt-suggestion',
    translate: 'yes',
  },
];

export const handlers = [
  rest.patch(/\/units\/.+$/, (req, res, ctx) => {
    console.log('[mock]', req);
    return res(ctx.json({}));
  }),

  rest.get(/\/units$/, (req, res, ctx) => {
    console.log('[mock]', req);
    const skip = parseInt(req.url.searchParams.get('options[skip]'));
    const limit = parseInt(req.url.searchParams.get('options[limit]'));
    console.log('[mock server]', 'skip:', skip, 'limit:', limit);
    const subset = units.slice(skip, skip + limit);
    return res(ctx.json(subset));
  }),

  rest.get(/\/units\/count$/, (req, res, ctx) => {
    console.log('[mock]', req);
    return res(ctx.json({ count: units.length }));
  }),

  rest.get(/\/jobs\/.+$/, (req, res, ctx) => {
    console.log('[mock]', req);
    return res(
      ctx.json({
        _id: 'j1',
        projectId: 'p1',
        name: 'test.tsv',
        srcLang: 'ja-JP',
        tgtLang: 'en-US',
        createdAt: '2021-06-24T09:49:48.844Z',
        updatedAt: '2021-06-30T07:11:00.918Z',
        properties: {
          anken_id: 'ECSI01202106079719_TEST',
          category_code: 'M1107000000_TEST',
          brand_code: 'MSM1_TEST',
          from_subsidiary_code: 'MJP_TEST',
          from_language_code: 'JPN_TEST',
          to_subsidiary_code: 'COM_TEST',
        },
        project: {
          _id: 'p1',
          name: '2021-06-24_01',
          ownerGroupId: 'og1',
          unitsPerXliff: 1000,
          ownerGroup: {
            _id: 'og1',
            name: 'CISTATE OWNER GROUP',
          },
        },
      })
    );
  }),
];
