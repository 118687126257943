import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TextField } from '../../TextField';
import { Dispatch, SetStateAction, useState } from 'react';

export type ContainerProps = {
  onSubmit: (formData: { tuPosition: number }) => void;
  onHide: () => void;
  startPosition: number;
  endPosition: number;
};

export type Props = ContainerProps & {
  formData: FormData;
  setFormData: Dispatch<SetStateAction<FormData>>;
};

export type FormData = {
  tuPosition?: string;
};

export const Component: React.FC<Props> = ({
  onSubmit,
  onHide,
  formData,
  setFormData,
  startPosition,
  endPosition,
}) => {
  const canClick = Boolean(
    formData.tuPosition &&
      /^[1-9][0-9]*$/.test(formData.tuPosition) &&
      parseInt(formData.tuPosition) <= endPosition
  );
  const renderFooter = () => {
    return (
      <div>
        <Button label="Close" className="p-button-secondary" onClick={() => onHide()} />
        <Button
          style={{ margin: 0 }}
          type="submit"
          form="go-to-segment-form"
          label="OK"
          disabled={!canClick}
        />
      </div>
    );
  };

  return (
    <Dialog
      header="Go To Segment"
      visible={true}
      style={{ width: '30vw' }}
      footer={renderFooter()}
      onHide={onHide}
      resizable={false}
    >
      <form
        className="p-fluid p-formgrid p-grid"
        id="go-to-segment-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.tuPosition) {
            const tuPosition = parseInt(formData.tuPosition);
            if (tuPosition <= endPosition) {
              onSubmit({ tuPosition });
            }
          }
        }}
      >
        <TextField
          id="tuPosition"
          placeholder={`Segment # (${startPosition} - ${endPosition})`}
          usePlaceholderAsLabel={true}
          required
          autoFocus
          pFieldClassName="p-col-12"
          value={formData.tuPosition}
          pattern={'^[1-9][0-9]*$'}
          title='Please type a number greater than "0".'
          onChange={(e) => {
            setFormData((prevData) => {
              return {
                ...prevData,
                tuPosition: e.target.value,
              };
            });
          }}
        />
      </form>
    </Dialog>
  );
};

const Container: React.FC<ContainerProps> = ({ onSubmit, onHide, startPosition, endPosition }) => {
  const [formData, setFormData] = useState<FormData>({
    tuPosition: '',
  });
  return (
    <Component
      onSubmit={onSubmit}
      onHide={onHide}
      formData={formData}
      setFormData={setFormData}
      startPosition={startPosition}
      endPosition={endPosition}
    />
  );
};

Container.displayName = 'GoToSegmentDialog';
export default Container;
