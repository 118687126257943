import _ from 'lodash';
import {
  FieldCategory,
  FieldCategoryType,
  FieldDefinition,
  GroupDefinition,
  NumberDefinition,
  SegmentDefinition,
  SelectDefinition,
  TextDefinition,
} from '../libs/types';

export const sortFieldDefinitions = (
  fieldDefinitions: FieldDefinition[],
  groupDefinitions: GroupDefinition[]
): { [key: string]: FieldDefinition<unknown>[] } => {
  const defaultFields = fieldDefinitions.filter((x) => !x.groupName);
  const groupedFields = _.orderBy(
    fieldDefinitions.filter((x) => x.groupName),
    (o) => {
      const group = groupDefinitions.find((g) => g.name === o.groupName);
      return group?.position ?? 0;
    },
    'asc'
  );

  const sortedArray = [...defaultFields, ...groupedFields];
  const result = sortedArray.reduce<{ [key: string]: FieldDefinition[] }>((acc, current) => {
    const groupName = current.groupName ?? 'default';
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(current);
    return acc;
  }, {});
  return result;
};

export const isTextDefinition = (def: { type: FieldCategoryType }): def is TextDefinition =>
  def.type === FieldCategory.Text;

export const isSegmentDefinition = (def: { type: FieldCategoryType }): def is SegmentDefinition =>
  def.type === FieldCategory.Segment;

export const isNumberDefinition = (def: { type: FieldCategoryType }): def is NumberDefinition =>
  def.type === FieldCategory.Number;

export const isSelectDefinition = <T>(def: {
  type: FieldCategoryType;
}): def is SelectDefinition<T> => def.type === FieldCategory.Select;

export const dotNotationToObject = (data: {
  [key: string]: unknown;
}): { [key: string]: unknown } => {
  return Object.keys(data).reduce<{ properties?: any }>((acc, key) => {
    if (/^properties\./.test(key)) {
      return {
        ...acc,
        properties: {
          ...(acc.properties ?? null),
          [key.replace(/^properties\./, '')]: data[key] ?? null,
        },
      };
    }
    return {
      ...acc,
      [key]: data[key] ?? null,
    };
  }, {});
};

export const sleep = (ms: number): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, ms));
