import {
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAxiosInstance } from '../../libs/useAxiosInstance';
import { isSegmentDefinition, sortFieldDefinitions } from '../libs/common-util';
import { useXliffEditorCommandManager } from '../libs/hooks/useEditorCommandManager';
import { useEditorEvent } from '../libs/hooks/useEditorEvent';
import { useFieldDefinitions } from '../libs/hooks/useFieldDefinitions';
import { useGroupDefinitions } from '../libs/hooks/useGroupDefinitions';
import { useXliffUnitSelection } from '../libs/hooks/useXliffUnitSelection';
import {
  XliffUnit,
  EditorCommandManager,
  PropagateSegmentEventPayload,
  EventId,
  ChangeSegmentStateEventPayload,
  FieldDefinition,
  XliffDefaultPropertyName,
  DeactivateTransUnitEventPayload,
  TextDefinition,
} from '../libs/types';
import { XliffEditorCommandManager } from '../libs/xliff-editor-command-manager';
import { moveToNextSegment } from '../Segment/libs/editor-util';
import { XliffSegment } from '../Segment/XliffSegment';
import { LockedIcon, StateIcon } from './Icons';

import './XliffUnit.css';

export type ContainerProps = {
  transUnit: XliffUnit;
};

export type Props = ContainerProps & {
  onUpdateSource: (xml: string, langCode: string) => void;
  onUpdateTarget: (xml: string, langCode: string) => void;
  onCommitSource: (xml: string, langCode: string) => void;
  onCommitTarget: (xml: string, langCode: string) => void;
  commandManager: EditorCommandManager;
  groupedFieldDefs: { [key: string]: FieldDefinition<unknown>[] };
  onSelectSource?: (tuid: string, element: HTMLElement | null) => void;
  onSelectTarget?: (tuid: string, element: HTMLElement | null) => void;
  onBlurSource?: (tuid: string, element: HTMLElement | null) => void;
  onBlurTarget?: (tuid: string, element: HTMLElement | null) => void;
  transUnitElRef: MutableRefObject<HTMLTableRowElement | null>;
};

export const Component: React.FC<Props> = ({
  transUnit,
  commandManager,
  onUpdateSource,
  onUpdateTarget,
  onCommitSource,
  onCommitTarget,
  groupedFieldDefs,
  transUnitElRef,
  onSelectSource,
  onSelectTarget,
  onBlurSource,
  onBlurTarget,
}) => {
  const handleMouseDown: MouseEventHandler<HTMLTableDataCellElement> = (e) => {
    // アクティブセグメントのフォーカスを保持するために必要
    e.preventDefault();
  };
  const sortCells = () => {
    const positionCell = (
      <td
        key="position"
        className="generic-cell"
        data-celltype="position"
        data-cellname="position"
        onMouseDown={handleMouseDown}
      >
        {transUnit.position}
      </td>
    );
    const otherCells = Object.keys(groupedFieldDefs).map((gropuName) => {
      const cells = groupedFieldDefs[gropuName].map((fieldDef) => {
        if (isSegmentDefinition(fieldDef.definition)) {
          const { isSource } = fieldDef.definition;
          if (isSource) {
            return (
              <XliffSegment
                key={fieldDef.field}
                position={transUnit.position}
                tuid={transUnit._id}
                isSource={true}
                isLocked={transUnit.isLocked}
                xml={transUnit.source.text}
                langCode={transUnit.source.langCode}
                onUpdateSegment={onUpdateSource}
                onCommitSegment={onCommitSource}
                commandManager={commandManager}
                transUnitElRef={transUnitElRef}
                onSelectSegment={onSelectSource}
                onBlurSegment={onBlurSource}
              />
            );
          } else {
            return (
              <XliffSegment
                key={fieldDef.field}
                position={transUnit.position}
                tuid={transUnit._id}
                isSource={false}
                isLocked={transUnit.isLocked}
                xml={transUnit.target.text}
                langCode={transUnit.target.langCode}
                onUpdateSegment={onUpdateTarget}
                onCommitSegment={onCommitTarget}
                commandManager={commandManager}
                transUnitElRef={transUnitElRef}
                onSelectSegment={onSelectTarget}
                onBlurSegment={onBlurTarget}
              />
            );
          }
        } else {
          const fieldName = fieldDef.field;
          if (fieldName === XliffDefaultPropertyName.State) {
            return (
              <td
                key={fieldName}
                className="generic-cell"
                data-celltype="state"
                data-cellname={fieldName}
                onMouseDown={handleMouseDown}
                style={{ fontSize: (fieldDef.definition as TextDefinition).fontSize }}
              >
                {StateIcon(transUnit)}
              </td>
            );
          }
          if (fieldName === XliffDefaultPropertyName.StateQualifier) {
            return (
              <td
                key={fieldName}
                className="generic-cell"
                data-celltype="state-qualifier"
                data-cellname={fieldName}
                onMouseDown={handleMouseDown}
                style={{ fontSize: (fieldDef.definition as TextDefinition).fontSize }}
              >
                {transUnit.stateQualifier}
              </td>
            );
          }
          if (fieldName === XliffDefaultPropertyName.IsLocked) {
            return (
              <td
                key={fieldName}
                className="generic-cell"
                data-celltype="locked"
                onMouseDown={handleMouseDown}
                data-cellname={fieldName}
                style={{ fontSize: (fieldDef.definition as TextDefinition).fontSize }}
              >
                {LockedIcon(transUnit)}
              </td>
            );
          }
          if (!transUnit.properties) return null;
          const customFieldName = fieldDef.field.replace(/^properties\./, '');
          const key = Object.keys(transUnit.properties).find((key) => key === customFieldName);
          if (key) {
            return (
              <td
                className="generic-cell"
                data-celltype="custom"
                data-cellname={key}
                onMouseDown={handleMouseDown}
                key={key}
                style={{ fontSize: (fieldDef.definition as TextDefinition).fontSize }}
              >
                {transUnit.properties[key]}
              </td>
            );
          }
          return null;
        }
      });
      return cells.filter((c): c is JSX.Element => c != null);
    });
    return [positionCell, ...otherCells.flat()];
  };

  const sortedCells = sortCells();

  return (
    <tr
      className="communication-inactive"
      data-rowtype="tu-row"
      data-position={transUnit.position}
      data-id={transUnit._id}
      data-is-locked={transUnit.isLocked}
      data-tu-state={transUnit.state}
      data-tu-state-qualifier={transUnit.stateQualifier}
      ref={transUnitElRef}
    >
      {sortedCells}
    </tr>
  );
};

const Container: React.FC<ContainerProps> = ({ transUnit: initialTransUnit }) => {
  const [transUnit, setTransUnit] = useState<XliffUnit>(initialTransUnit);
  const axios = useAxiosInstance();

  useEffect(() => {
    setTransUnit(initialTransUnit);
  }, [initialTransUnit]);

  const commandManager = useXliffEditorCommandManager();
  const { editorEvent, raiseEditorEvent } = useEditorEvent();
  const { fieldDefinitions } = useFieldDefinitions();
  const { groupDefinitions } = useGroupDefinitions();
  const groupedFieldDefs = useMemo(() => {
    return sortFieldDefinitions(fieldDefinitions, groupDefinitions);
  }, [fieldDefinitions, groupDefinitions]);

  const { updateUnitSelection } = useXliffUnitSelection();

  const [isPatchRunning, setIsPatchRunning] = useState(false);

  const transUnitElRef = useRef<HTMLTableRowElement | null>(null);

  // サーバーと通信中を表すCSSクラスを追加/削除する
  useEffect(() => {
    if (!transUnitElRef.current) return;
    if (isPatchRunning) {
      transUnitElRef.current.classList.replace('communication-inactive', 'communication-active');
    } else {
      transUnitElRef.current.classList.replace('communication-active', 'communication-inactive');
    }
  }, [isPatchRunning]);

  // useAxiosだと連続でセルを更新された場合、リクエストがキャンセルされる場合があるので、生のaxiosを使う
  const executePatch = useCallback(
    async (data: unknown) => {
      setIsPatchRunning(true);
      try {
        await axios.patch(`units/${transUnit._id}`, data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsPatchRunning(false);
      }
    },
    [axios, transUnit._id]
  );

  useEffect(() => {
    if (!editorEvent) return;
    const handlePropagateSegmentUpdateEvent = async () => {
      const payload = editorEvent.payload as PropagateSegmentEventPayload;
      if (payload.source !== transUnit.source.text) return;
      if (payload.propagateFrom === transUnit._id) return;
      if (transUnit.isLocked) return;
      console.log(payload);
      setTransUnit((prev) => ({
        ...prev,
        state: payload.state,
        target: {
          ...prev.target,
          text: payload.target,
        },
      }));
    };

    const handleChangeSegmentState = async () => {
      const payload = editorEvent.payload as ChangeSegmentStateEventPayload;
      if (payload.tuid !== transUnit._id) return;
      // console.log(payload);
      setTransUnit((prev) => ({
        ...prev,
        state: payload.state,
      }));
      executePatch({
        state: payload.state,
        tgtXliff: transUnit.target.text,
      }).then(() => {
        moveToNextSegment();
      });
    };

    const handleDeactivateTransUnit = () => {
      const payload = editorEvent.payload as DeactivateTransUnitEventPayload;
      if (payload.activeTuid === transUnit._id) return;
      if (transUnitElRef.current && transUnitElRef.current.classList.contains('active')) {
        transUnitElRef.current.classList.remove('active');
        if (transUnitElRef.current.classList.length === 0) {
          transUnitElRef.current.removeAttribute('class');
        }
      }
    };

    if (editorEvent.eventId === EventId.PropagateSegmentUpdate) {
      handlePropagateSegmentUpdateEvent();
    } else if (editorEvent.eventId === EventId.ChangeSegmentState) {
      handleChangeSegmentState();
    } else if (editorEvent.eventId === EventId.DeactivateTransUnit) {
      handleDeactivateTransUnit();
    }
  }, [
    editorEvent,
    executePatch,
    transUnit._id,
    transUnit.isLocked,
    transUnit.source.text,
    transUnit.target.text,
  ]);

  const handleUpdateSource = useCallback((xml: string, langCode: string) => {
    setTransUnit((prev) => ({
      ...prev,
      source: {
        ...prev.source,
        text: xml,
      },
    }));
  }, []);

  const handleUpdateTarget = useCallback((xml: string, langCode: string) => {
    setTransUnit((prev) => ({
      ...prev,
      target: {
        ...prev.target,
        text: xml,
      },
    }));
  }, []);

  const handleCommitSource = useCallback(
    (xml: string, langCode: string) => {
      executePatch({
        srcXliff: xml,
      });
    },
    [executePatch]
  );

  const handleCommitTarget = useCallback(
    (xml: string, langCode: string) => {
      executePatch({
        tgtXliff: xml,
      });
    },
    [executePatch]
  );

  const handleSelectSegment = useCallback(
    (tuid: string, segmentEl: HTMLElement | null) => {
      updateUnitSelection({ activeUnit: transUnit, selectedUnits: [transUnit] });
      segmentEl?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      if (transUnitElRef.current && !transUnitElRef.current.classList.contains('active')) {
        transUnitElRef.current.classList.add('active');
        raiseEditorEvent({
          eventId: EventId.DeactivateTransUnit,
          payload: {
            activeTuid: transUnit._id,
          },
        });
      }
    },
    [raiseEditorEvent, transUnit, updateUnitSelection]
  );

  return (
    <Component
      transUnit={transUnit}
      commandManager={commandManager as XliffEditorCommandManager}
      onUpdateSource={handleUpdateSource}
      onUpdateTarget={handleUpdateTarget}
      onCommitSource={handleCommitSource}
      onCommitTarget={handleCommitTarget}
      groupedFieldDefs={groupedFieldDefs}
      transUnitElRef={transUnitElRef}
      onSelectSource={handleSelectSegment}
      onSelectTarget={handleSelectSegment}
    />
  );
};

Container.displayName = 'XliffUnit';

export default Container;
