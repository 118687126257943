import React from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';

export type Props = Omit<InputTextProps, 'ref'> & {
  id: string;
  placeholder: string;
  usePlaceholderAsLabel?: boolean;
  helpText?: string;
  helpTextClassName?: string;
  pFieldClassName?: string;
  inputRef?: (node: HTMLInputElement) => void;
};

export const TextField: React.FC<Props> = ({
  id,
  placeholder,
  helpText,
  helpTextClassName = '',
  usePlaceholderAsLabel,
  pFieldClassName = '',
  inputRef,
  ...otherProps
}) => {
  let helpElement;
  if (helpText) {
    helpElement = <small className={`${helpTextClassName}`}>{helpText}</small>;
  } else {
    helpElement = <React.Fragment />;
  }

  if (usePlaceholderAsLabel) {
    return (
      <div className={`p-field ${pFieldClassName}`}>
        <label htmlFor={id}>{placeholder}</label>
        <InputText ref={inputRef as any} id={id} {...otherProps} />
        {helpElement}
      </div>
    );
  }

  return (
    <div className={`p-field ${pFieldClassName}`}>
      <InputText ref={inputRef as any} id={id} placeholder={placeholder} {...otherProps} />
      {helpElement}
    </div>
  );
};
