import React, { useRef, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AxiosError } from 'axios';
import useAxios from 'axios-hooks';
import { Toast } from 'primereact/toast';
import { Splitter, SplitterPanel, SplitterResizeEndParams } from 'primereact/splitter';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import XliffGrid from '../XliffGrid';
import AppMenuBar from '../AppMenuBar';
import StatusBar from '../StatusBar';
import CatPanelToolbar from '../CatPanelToolbar';
import TMLookupPanel from '../TMLookupPanel';
import ConcordanceSearchPanel from '../ConcordanceSearchPanel';
import {
  ConfirmAllSegmentsEventPayload,
  ConfirmAllSegmentsResponse,
  EventId,
  FieldDefinition,
  GroupDefinition,
  PanelSizes,
  XliffJob,
  XliffUnit,
} from '../libs/types';
import { useFieldDefinitions } from '../libs/hooks/useFieldDefinitions';
import { useGroupDefinitions } from '../libs/hooks/useGroupDefinitions';
import { FetchNext, FetchPrev, useXliffUnits } from '../libs/hooks/useXliffUnits';
import {
  XliffEditorCommandManagerProvider,
  useXliffEditorCommandManager,
} from '../libs/hooks/useEditorCommandManager';
import { XliffEditorCommandManager } from '../libs/xliff-editor-command-manager';
import { useEditorOptions } from '../libs/hooks/useEditorOptions';
import { useEditorEvent } from '../libs/hooks/useEditorEvent';
import './XliffEditor.css';
import { isChrome } from '../libs/platform-util';
import { useXliffUnitSelection } from '../libs/hooks/useXliffUnitSelection';
import { useCallback } from 'react';
import { PanelVisibility, usePanelVisibility } from './usePanelVisibility';
import { useKeyboardEventHandler } from './useKeyboardEventHandler';
import { useLocalStorage } from '../../libs/useLocalStorage';
import { useCategoryOptions } from '../libs/hooks/useCategoryOptions';
import DocumentSearchPanel from '../DocumentSearchPanel';
import GoToSegmentDialog from '../Dialogs/GoToSegmentDialog';
import { moveToSegmentByPosition } from '../Segment/libs/editor-util';

type ContainerProps = RouteComponentProps<{ id: string }> & {
  onInitializeJob: React.Dispatch<React.SetStateAction<XliffJob | null | undefined>>;
  onLogOut: () => void;
};

export type Props = {
  fieldDefinitions: FieldDefinition[];
  groupDefinitions: GroupDefinition[];
  fetchPrevTransUnits: FetchPrev;
  fetchNextTransUnits: FetchNext;
  hasPrevTransUnits: boolean;
  hasNextTransUnits: boolean;
  isLoadingPrevTransUnits: boolean;
  isLoadingNextTransUnits: boolean;
  transUnits: XliffUnit[];
  totalUnitsCount: number;
  resetFetchData: () => void;
  job?: XliffJob;
  confirmAllResult?: ConfirmAllSegmentsResponse;
  confirmAllError?: AxiosError<unknown>;
  isConfirmDialogVisible: boolean;
  onCloseConfirmDialog: () => void;
  isGoToSegmentDialogVisible: boolean;
  onCloseGoToSegmentDialog: () => void;
  activeTransUnit?: XliffUnit | null;
  categoryLabel: string;
  panelVisibility: PanelVisibility;
  onSplitterResizeEnd: (e: SplitterResizeEndParams) => void;
  onLogOut: () => void;
  fetchNeighboringUnits: (id?: string, position?: number) => Promise<boolean>;
  isFetchingNeighboringUnits: boolean;
};

const Component_: React.FC<Props> = ({
  fieldDefinitions,
  groupDefinitions,
  isLoadingPrevTransUnits,
  isLoadingNextTransUnits,
  resetFetchData,
  totalUnitsCount,
  job,
  confirmAllResult,
  confirmAllError,
  isConfirmDialogVisible,
  onCloseConfirmDialog,
  isGoToSegmentDialogVisible,
  onCloseGoToSegmentDialog,
  activeTransUnit,
  panelVisibility,
  onSplitterResizeEnd,
  onLogOut,
  categoryLabel,
  fetchNeighboringUnits,
  isFetchingNeighboringUnits,
  ...otherProps
}) => {
  const msgToastRef = useRef<Toast>(null);
  const commandManager = useXliffEditorCommandManager();

  useEffect(() => {
    if (confirmAllResult) {
      msgToastRef.current?.show({
        severity: 'info',
        summary: 'Confirm all segments',
        sticky: true,
        detail:
          'Request has been accepted.\n' +
          'You will receive notification emails (CONFIRM-TRANSLATIONS and CREATE-TRANSLATION-KIT) when the process has been completed.',
      });
    } else if (confirmAllError) {
      msgToastRef.current?.show({
        severity: 'error',
        summary: 'Confirm all segments',
        sticky: true,
        detail: 'Request has been rejected.',
      });
    }
  }, [confirmAllError, confirmAllResult]);

  const dialogFooter = <Button label="OK" onClick={onCloseConfirmDialog} />;
  return (
    <div className="xliff-editor-root">
      <ConfirmDialog
        visible={isConfirmDialogVisible}
        onHide={onCloseConfirmDialog}
        header="Unsupported browser detected"
        message={
          'Your browser is currently not supported. Use one of the following browsers:\n\n- Google Chrome'
        }
        icon="pi pi-exclamation-triangle"
        footer={dialogFooter}
      />
      {isGoToSegmentDialogVisible && (
        <GoToSegmentDialog
          onHide={onCloseGoToSegmentDialog}
          onSubmit={({ tuPosition }) =>
            fetchNeighboringUnits(undefined, tuPosition).then((success) => {
              if (success) {
                moveToSegmentByPosition(tuPosition);
              }
            })
          }
          startPosition={1}
          endPosition={totalUnitsCount}
        />
      )}
      <div
        className="xliff-editor-header"
        onMouseDown={(e) => {
          // アクティブセグメントのフォーカスを保持するために必要
          e.preventDefault();
        }}
      >
        <Toast ref={msgToastRef} />
        <AppMenuBar
          commandManager={commandManager}
          job={job}
          panelVisibility={panelVisibility}
          onLogOut={onLogOut}
        />
      </div>
      <div className="xliff-editor-splitter-wrapper p-d-flex">
        <Splitter
          className="xliff-editor-content"
          onResizeEnd={onSplitterResizeEnd}
          // stateKey={'cistate-translation-editor/panel-sizes'}
          // stateStorage={'local'}
          gutterSize={6}
        >
          <SplitterPanel className="grid-panel" size={100}>
            <XliffGrid
              job={job}
              {...otherProps}
              isLoadingNextTransUnits={isLoadingNextTransUnits}
              isLoadingPrevTransUnits={isLoadingPrevTransUnits}
              isFetchingNeighboringUnits={isFetchingNeighboringUnits}
            />
          </SplitterPanel>
          <SplitterPanel className="cat-panel-root" size={0}>
            {job && (
              <>
                <TMLookupPanel
                  activeTransUnit={activeTransUnit}
                  categoryCode={job.properties.category_code}
                  ownerGroupId={job.project.ownerGroupId}
                  isExpanded={panelVisibility.tmLookupPanel.isExpanded}
                />
                <ConcordanceSearchPanel
                  activeTransUnit={activeTransUnit}
                  job={job}
                  isExpanded={panelVisibility.concordanceSearchPanel.isExpanded}
                />
                <DocumentSearchPanel
                  job={job}
                  fetchNeighboringUnits={fetchNeighboringUnits}
                  isFetchingNeighboringUnits={isFetchingNeighboringUnits}
                  isExpanded={panelVisibility.documentSearchPanel.isExpanded}
                />
              </>
            )}
          </SplitterPanel>
        </Splitter>

        <CatPanelToolbar panelVisibility={panelVisibility} commandManager={commandManager} />
      </div>
      <div className="xliff-editor-footer">
        <StatusBar
          items={[
            { label: 'Units', value: `${totalUnitsCount}` },
            { label: 'Anken', value: job?.properties.anken_id ?? '' },
            {
              label: 'Category',
              value: categoryLabel,
            },
            {
              label: 'Brand',
              value: activeTransUnit?.properties?.brandCode ?? job?.properties.brand_code ?? '',
            },
            {
              label: 'Subsidiary',
              value:
                `${job?.properties.from_subsidiary_code} » ${job?.properties.to_subsidiary_code}` ??
                '',
            },
            { label: 'Owner Group', value: job?.project.ownerGroup?.name ?? '' },
            { label: 'Name', value: job?.name ?? '' },
          ]}
        />
      </div>
    </div>
  );
};

export const Component = React.memo(Component_);

const Container: React.FC<ContainerProps> = ({ match, onInitializeJob, onLogOut }) => {
  const jobId = match.params.id;
  const { fieldDefinitions } = useFieldDefinitions();
  const { groupDefinitions } = useGroupDefinitions();
  const { editorOptions, updateEditorOptions } = useEditorOptions();
  const { raiseEditorEvent, editorEvent } = useEditorEvent();
  const [{ data: job }] = useAxios<XliffJob>(`jobs/${jobId}`);
  const {
    transUnits,
    isLoadingPrev: isLoadingPrevTransUnits,
    isLoadingNext: isLoadingNextTransUnits,
    fetchPrev: fetchPrevTransUnits,
    fetchNext: fetchNextTransUnits,
    fetchCount: fetchUnitsCount,
    hasPrev: hasPrevTransUnits,
    hasNext: hasNextTransUnits,
    totalUnitsCount,
    resetFetchData,
    fetchNeighboringUnits,
    isFetchingNeighboringUnits,
  } = useXliffUnits({
    limit: 100,
    job,
  });

  const [categoryLabel, setCategoryLabel] = useState('');
  const { findCategoryLabelByCode } = useCategoryOptions({
    lazyLoad: false,
    ownerGroupId: job?.project.ownerGroupId,
    srcLang: job?.srcLang,
    tgtLang: job?.tgtLang,
  });

  const { unitSelection } = useXliffUnitSelection();

  const {
    storedValue: storedPanelSizes,
    setStoredValue: storePanelSizes,
  } = useLocalStorage<PanelSizes>('panel-sizes', [80, 20]);

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(!isChrome());
  const [isGoToSegmentDialogVisible, setIsGoToSegmentDialogVisible] = useState(false);
  const [commandManager, setCommandManager] = useState<XliffEditorCommandManager | null>(null);

  const [
    { data: confirmAllResult, error: confirmAllError },
    confirmAllSegments,
  ] = useAxios<ConfirmAllSegmentsResponse>(
    {
      url: `projects/${job?.projectId}/confirmTranslations`,
      method: 'POST',
    },
    { manual: true }
  );
  const { panelVisibility, toggleIsExpanded } = usePanelVisibility();

  useKeyboardEventHandler(commandManager, panelVisibility);

  const handleSplitterReiszeEnd = useCallback(
    // Splitterをドラッグ後、パネルサイズをLocalStorageに保存
    (e: SplitterResizeEndParams) => {
      storePanelSizes([e.sizes[0], e.sizes[1]]);
    },
    [storePanelSizes]
  );

  useEffect(() => {
    // document.titleの設定
    onInitializeJob(job);
    if (!job) return;
    document.title = `${job.name} - MISUMI Translation Editor`;
  }, [job, onInitializeJob]);

  useEffect(() => {
    // commandManagerの初期化
    const commandManager = new XliffEditorCommandManager(
      editorOptions,
      updateEditorOptions,
      raiseEditorEvent
    );
    setCommandManager(commandManager);
  }, [editorOptions, raiseEditorEvent, updateEditorOptions]);

  useEffect(() => {
    // editorEventのハンドラ
    if (!editorEvent) return;
    if (editorEvent.eventId === EventId.ConfirmAllSegments) {
      const { jobId } = editorEvent.payload as ConfirmAllSegmentsEventPayload;
      confirmAllSegments({
        data: {
          jobId,
        },
      });
    } else if (editorEvent.eventId === EventId.ToggleTMLookupPanelVisibility) {
      const panelName: keyof PanelVisibility = 'tmLookupPanel';
      toggleIsExpanded(panelName);
    } else if (editorEvent.eventId === EventId.ToggleConcordanceSearchPanelVisibility) {
      const panelName: keyof PanelVisibility = 'concordanceSearchPanel';
      toggleIsExpanded(panelName);
    } else if (editorEvent.eventId === EventId.ToggleDocumentSearchPanelVisibility) {
      const panelName: keyof PanelVisibility = 'documentSearchPanel';
      toggleIsExpanded(panelName);
    } else if (editorEvent.eventId === EventId.ShowGoToSegmentDialog) {
      setIsGoToSegmentDialogVisible(true);
    }
  }, [confirmAllSegments, editorEvent, toggleIsExpanded]);

  useEffect(() => {
    // 初回ページ読み込み時のunitのロードとunit数のロード
    fetchNextTransUnits();
    fetchUnitsCount();
  }, [fetchNextTransUnits, fetchUnitsCount]);

  useEffect(() => {
    const resizeSplitterPanel = (catPanelRootSize: number) => {
      const gridPanelSize = 100 - catPanelRootSize;
      const gridPanel = document.querySelector('.grid-panel') as HTMLElement;
      if (gridPanel) {
        gridPanel.style.setProperty('flex-basis', `${gridPanelSize}%`);
      }
      const catPanelRoot = document.querySelector('.cat-panel-root') as HTMLElement;
      if (catPanelRoot) {
        catPanelRoot.style.setProperty('flex-basis', `${catPanelRootSize}%`);
      }
    };

    const vals = [
      panelVisibility.tmLookupPanel.isExpanded,
      panelVisibility.concordanceSearchPanel.isExpanded,
      panelVisibility.documentSearchPanel.isExpanded,
    ];
    if (vals.every((v) => !v)) {
      resizeSplitterPanel(0);
    } else if (vals.some((v) => v)) {
      // パネルサイズはLocalStorageから復元
      resizeSplitterPanel(storedPanelSizes[1]);
    }
  }, [
    panelVisibility.concordanceSearchPanel.isExpanded,
    panelVisibility.documentSearchPanel.isExpanded,
    panelVisibility.tmLookupPanel.isExpanded,
    storedPanelSizes,
  ]);

  useEffect(() => {
    const categoryCode =
      unitSelection.activeUnit?.properties?.categoryCode ?? job?.properties.category_code ?? '';

    let label = '';
    if (categoryCode) {
      label = `${findCategoryLabelByCode(categoryCode)} (${categoryCode})`;
    }
    setCategoryLabel(label);
  }, [
    findCategoryLabelByCode,
    job?.properties.category_code,
    unitSelection.activeUnit?.properties?.categoryCode,
  ]);

  const handleCloseConfirmDialog = () => setIsConfirmDialogVisible(false);
  const handleCloseGoToSegmentDialog = () => setIsGoToSegmentDialogVisible(false);

  return (
    <XliffEditorCommandManagerProvider value={commandManager}>
      <Component
        transUnits={transUnits}
        fetchPrevTransUnits={fetchPrevTransUnits}
        fetchNextTransUnits={fetchNextTransUnits}
        hasPrevTransUnits={hasPrevTransUnits}
        hasNextTransUnits={hasNextTransUnits}
        fieldDefinitions={fieldDefinitions}
        groupDefinitions={groupDefinitions}
        isLoadingPrevTransUnits={isLoadingPrevTransUnits}
        isLoadingNextTransUnits={isLoadingNextTransUnits}
        totalUnitsCount={totalUnitsCount}
        resetFetchData={resetFetchData}
        confirmAllResult={confirmAllResult}
        confirmAllError={confirmAllError}
        isConfirmDialogVisible={isConfirmDialogVisible}
        onCloseConfirmDialog={handleCloseConfirmDialog}
        isGoToSegmentDialogVisible={isGoToSegmentDialogVisible}
        onCloseGoToSegmentDialog={handleCloseGoToSegmentDialog}
        job={job}
        activeTransUnit={unitSelection.activeUnit}
        panelVisibility={panelVisibility}
        onSplitterResizeEnd={handleSplitterReiszeEnd}
        onLogOut={onLogOut}
        categoryLabel={categoryLabel}
        fetchNeighboringUnits={fetchNeighboringUnits}
        isFetchingNeighboringUnits={isFetchingNeighboringUnits}
      />
    </XliffEditorCommandManagerProvider>
  );
};

Container.displayName = 'XliffEditor';
export default withRouter(Container);
