import { FieldDefinition, XliffDefaultPropertyName } from '../components/libs/types';

export const fieldDefinitions: FieldDefinition[] = [
  {
    field: 'srcXliff',
    label: 'Source',
    definition: {
      type: 'segment',
      isSource: true,
      // langCode: 'srcLang',
    },
  },
  {
    field: 'tgtXliff',
    label: 'Target',
    definition: {
      type: 'segment',
      isSource: false,
      // langCode: 'tgtLang',
    },
  },
  {
    field: 'properties.seriesCode',
    label: 'Series',
    definition: {
      type: 'text',
      columnWidth: '100px',
      fontSize: '0.7rem',
    },
  },
  {
    field: 'properties.itemName',
    label: 'Item',
    definition: {
      type: 'text',
      columnWidth: '100px',
      fontSize: '0.7rem',
    },
  },
  {
    field: XliffDefaultPropertyName.IsLocked,
    label: '',
    definition: {
      type: 'text',
      columnWidth: '100px',
      fontSize: '0.7rem',
    },
  },
  {
    field: XliffDefaultPropertyName.State,
    label: '',
    definition: {
      type: 'text',
      columnWidth: '100px',
      fontSize: '0.7rem',
    },
  },
  {
    field: XliffDefaultPropertyName.StateQualifier,
    label: '',
    definition: {
      type: 'text',
      columnWidth: '100px',
      fontSize: '0.7rem',
    },
  },
];
