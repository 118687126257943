import {
  computeTagCandidates,
  createTagMarker,
  extractTags,
  getActiveSourceHtml,
  getActiveTargetHtml,
  getSelectedPlainContent,
  insertNodeAtCursor,
  removeSelection,
  moveToPreviousSegment,
  moveToNextSegment,
  getActiveTransUnitId,
  getActiveTransUnitAttributes,
} from '../Segment/libs/editor-util';

import { escapeHtmlEntities, sanitize } from '../Segment/libs/string-util';

import {
  EditorCommandManager,
  EditorOptions,
  TagDisplayMode,
  EventId,
  XliffUnitState,
  XliffUnitStateType,
  TMEntry,
} from './types';
import { xliffToHtml } from '../Segment/libs/xliff-util';
import { RaiseEditorEvent } from './hooks/useEditorEvent';
import { UpdateEditorOptions } from './hooks/useEditorOptions';

export class XliffEditorCommandManager implements EditorCommandManager {
  constructor(
    private _editorOptions: EditorOptions,
    private _updateEditorOptions: UpdateEditorOptions,
    private _raiseEditorEvent: RaiseEditorEvent
  ) {}

  async copy(e?: React.ClipboardEvent): Promise<void> {
    const content = getSelectedPlainContent();
    if (!content) return;

    if (!e) {
      if (!navigator.clipboard || !navigator.clipboard.writeText) {
        // TODO: ちゃんとalert出す
        console.error('Use Ctrl+C/Command+C');
        return;
      }
      await navigator.clipboard.writeText(content);
    } else {
      e.stopPropagation();
      e.preventDefault();
      e.clipboardData.setData('text/plain', content);
    }
  }

  async cut(e?: React.ClipboardEvent): Promise<void> {
    const content = getSelectedPlainContent();
    if (!content) return;

    if (!e) {
      if (!navigator.clipboard || !navigator.clipboard.writeText) {
        // TODO: ちゃんとalert出す
        console.error('Use Ctrl+X/Command+X');
        return;
      }
      removeSelection();
      await navigator.clipboard.writeText(content);
    } else {
      e.stopPropagation();
      e.preventDefault();
      removeSelection();
      e.clipboardData.setData('text/plain', content);
    }
  }

  async paste(e?: React.ClipboardEvent): Promise<void> {
    let clipText;
    if (!e) {
      if (!navigator.clipboard || !navigator.clipboard.readText) {
        // TODO: ちゃんとalert出す
        console.error('Use Ctrl+V/Command+V');
        return;
      }
      clipText = await navigator.clipboard.readText();
    } else {
      e.stopPropagation();
      e.preventDefault();
      clipText = e.clipboardData.getData('text/plain');
    }
    // とりあえずプレインテキストのペーストのみサポート
    const plaintext = escapeHtmlEntities(sanitize(clipText));
    const html = xliffToHtml(plaintext, {
      prependZWSP: false,
      includeNewlines: false,
      includeTags: false,
    });
    document.execCommand('insertHTML', false, html);
  }

  insertTag(): void {
    if (this._editorOptions.isReadOnly) return;
    const srcTags = extractTags(getActiveSourceHtml());
    const tgtTags = extractTags(getActiveTargetHtml());
    const tagCandidates = computeTagCandidates(srcTags, tgtTags);
    if (tagCandidates.length > 0) {
      const tagMarker = createTagMarker(tagCandidates[0], this._editorOptions.tagDisplayMode);
      insertNodeAtCursor(tagMarker);
    }
  }

  toggleTagMode(): void {
    const newMode =
      this._editorOptions.tagDisplayMode === TagDisplayMode.ShowTagId
        ? TagDisplayMode.ShowFullTag
        : TagDisplayMode.ShowTagId;

    this._updateEditorOptions({
      tagDisplayMode: newMode,
    });
  }

  moveToPreviousSegment(): void {
    moveToPreviousSegment();
  }

  moveToNextSegment(): void {
    moveToNextSegment();
  }

  undo(): void {
    if (this._editorOptions.isReadOnly) return;
    const tuid = getActiveTransUnitId();
    if (!tuid) return;
    this._raiseEditorEvent({
      eventId: EventId.Undo,
      payload: {
        tuid,
      },
    });
  }

  redo(): void {
    if (this._editorOptions.isReadOnly) return;
    const tuid = getActiveTransUnitId();
    if (!tuid) return;
    this._raiseEditorEvent({
      eventId: EventId.Redo,
      payload: {
        tuid,
      },
    });
  }

  changeSegmentState(newState: XliffUnitStateType, tuid?: string): void {
    if (this._editorOptions.isReadOnly) return;
    const id = tuid ?? getActiveTransUnitId();
    if (!id) return;
    this._raiseEditorEvent({
      eventId: EventId.ChangeSegmentState,
      payload: {
        tuid: id,
        state: newState,
      },
    });
  }

  confirmSegment(): void {
    if (this._editorOptions.isReadOnly) return;
    const attrs = getActiveTransUnitAttributes();
    console.log(attrs);
    if (!attrs) return;
    if (attrs.isLocked) return;

    const state = XliffUnitState.Translated;

    const { id } = attrs;
    this.changeSegmentState(state, id);
  }

  confirmAllSegments(projectId: string, jobId: string): void {
    this._raiseEditorEvent({
      eventId: EventId.ConfirmAllSegments,
      payload: {
        projectId,
        jobId,
      },
    });
  }

  toggleTMLookupPanelVisibility(): void {
    this._raiseEditorEvent({
      eventId: EventId.ToggleTMLookupPanelVisibility,
      payload: null,
    });
  }

  selectPrevMatch(): void {
    this._raiseEditorEvent({
      eventId: EventId.SelectPrevMatch,
      payload: null,
    });
  }

  selectNextMatch(): void {
    this._raiseEditorEvent({
      eventId: EventId.SelectNextMatch,
      payload: null,
    });
  }

  applySelectedMatch(): void {
    this._raiseEditorEvent({
      eventId: EventId.ApplySelectedMatch,
      payload: null,
    });
  }

  selectPrevConcordanceSearchResult(): void {
    this._raiseEditorEvent({
      eventId: EventId.SelectPrevConcordanceSearchResult,
      payload: null,
    });
  }

  selectNextConcordanceSearchResult(): void {
    this._raiseEditorEvent({
      eventId: EventId.SelectNextConcordanceSearchResult,
      payload: null,
    });
  }

  applySelectedConcordanceSearchResult(): void {
    this._raiseEditorEvent({
      eventId: EventId.ApplySelectedConcordanceSearchResult,
      payload: null,
    });
  }

  toggleConcordanceSearchPanelVisibility(): void {
    this._raiseEditorEvent({
      eventId: EventId.ToggleConcordanceSearchPanelVisibility,
      payload: null,
    });
  }

  toggleDocumentSearchPanelVisibility(): void {
    this._raiseEditorEvent({
      eventId: EventId.ToggleDocumentSearchPanelVisibility,
      payload: null,
    });
  }

  showGoToSegmentDialog(): void {
    this._raiseEditorEvent({
      eventId: EventId.ShowGoToSegmentDialog,
      payload: null,
    });
  }

  get editorOptions(): EditorOptions {
    return this._editorOptions;
  }
}
