import * as rangy from 'rangy';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-selectionsaverestore';
import { getSegmentRoot, isValidTagType, insertAfter } from './editor-util';
import { MarkerCategory } from './types';
import { ZWSP } from './constants';

/**
 * 現在の選択終了点がtag-wrapperに含まれる場合、そのtag-wrapperを返します。
 * @returns tag-wrapper
 */
export const getParentTagWrapper = (): HTMLElement | null => {
  const sel = rangy.getSelection();
  let tagWrapper: HTMLElement | null = null;
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    // キャレット位置がtag-wrapperの中にあるケースは以下の2つ
    // 1. range.endContainerがtag-wrapper
    // 2. range.endcontainer.parentElementがtag-wrapper
    const endContainer = range.endContainer as HTMLElement;
    const parent = range.endContainer.parentElement;
    if (endContainer.dataset && endContainer.dataset.type === MarkerCategory.TagWrapper) {
      tagWrapper = endContainer;
    } else if (parent?.dataset && parent.dataset.type === MarkerCategory.TagWrapper) {
      tagWrapper = parent;
    }
  }
  return tagWrapper;
};

/**
 * tag-wrapperに含まれるテキストノードのうち、ZWSP以外を削除し、削除した文字列を返します。
 * @param tagWrapper - 対象のtag-wrapper
 * @returns 削除した文字列
 */
export const ensureTagWrapperHasOnlyZWSP = (tagWrapper: Node): string => {
  const children = [...tagWrapper.childNodes] as HTMLElement[];
  // ['TEXT1', 'TEXT2', <tag>, 'TEXT3', 'TEXT4']
  const tagPos = children.findIndex((n) => isValidTagType(n.dataset?.type));
  if (tagPos === -1) {
    return '';
  }
  // childrenBeforeTag = ['TEXT1', 'TEXT2']
  // childrenAfterTag = ['TEXT3', 'TEXT4']
  const childrenBeforeTag = children.slice(0, tagPos).filter((n) => n.nodeType === Node.TEXT_NODE);
  const childrenAfterTag = children.slice(tagPos + 1).filter((n) => n.nodeType === Node.TEXT_NODE);

  // 一旦tag-wrapper内のテキストノードを全削除
  const textBeforeTag = childrenBeforeTag
    .map((textNode) => {
      const text = textNode.textContent || '';
      tagWrapper.removeChild(textNode);
      return text;
    })
    .join('');
  const textAfterTag = childrenAfterTag
    .map((textNode) => {
      const text = textNode.textContent || '';
      tagWrapper.removeChild(textNode);
      return text;
    })
    .join('');

  // 最後にZWSPを追加
  const tag = children[tagPos];
  insertAfter(document.createTextNode(ZWSP), tag);

  // ZWSPを除くテキスト（間違ってtag-wrapper内に入り込んだテキスト）を返す
  const text = `${textBeforeTag}${textAfterTag}`.replace(/\u200B/g, '');
  return text;
};

/**
 * tag-wrapperの中に入り込んだ入力文字列をtag-wrapperの直後に移動させます。
 *
 * 例:
 *
 * \<tag-wrapper>\<tag />ZWSP[文字列]\</tag-wrapper>
 *
 * ↓
 *
 *  \<tag-wrapper>\<tag />ZWSP\</tag-wrapper>[文字列]
 */
export const moveTypedTextOutOfTagWrapper = (): void => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    const tagWrapper = getParentTagWrapper();
    if (tagWrapper) {
      const typedText = ensureTagWrapperHasOnlyZWSP(tagWrapper);
      if (typedText) {
        const newText = document.createTextNode(typedText);
        insertAfter(newText, tagWrapper);
        range.selectNodeContents(newText);
        range.collapse();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }
};

/**
 * 指定したノードに含まれるtag-wrapperを返します。
 * @param node - tag-wrapperを探索する親ノード
 * @returns tag-wrapperの配列
 */
export const tagWrappersUnder = (node: Node): HTMLElement[] => {
  let n: Node | null;
  const nodes: Node[] = [];
  const walk = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT, function (
    node: Node
  ): number {
    const elem = node as HTMLElement;
    if (elem.dataset?.type === MarkerCategory.TagWrapper) {
      return NodeFilter.FILTER_ACCEPT;
    }
    return NodeFilter.FILTER_SKIP;
  } as any);
  while ((n = walk.nextNode())) {
    nodes.push(n);
  }
  return nodes as HTMLElement[];
};

export const selectTagWrapper = (tagWrapper: HTMLElement): void => {
  tagWrapper.classList.add('tag-selected');
};

export const selectTagWrappers = (): void => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    const editorRoot = getSegmentRoot(range.endContainer);
    if (!editorRoot) return;
    const allTagWrappers = tagWrappersUnder(editorRoot) as HTMLElement[];
    allTagWrappers.forEach((tagWrapper) => {
      if (!range.collapsed && range.intersectsNode(tagWrapper)) {
        tagWrapper.classList.add('tag-selected');
      } else {
        tagWrapper.classList.remove('tag-selected');
      }
    });
  }
};

export const unselectTagWrapper = (tagWrapper: HTMLElement): void => {
  tagWrapper.classList.remove('tag-selected');
};

export const unselectTagWrappers = (): void => {
  console.log('unselect');
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    const editorRoot = getSegmentRoot(range.endContainer);
    if (!editorRoot) return;
    const allTagWrappers = tagWrappersUnder(editorRoot) as HTMLElement[];
    allTagWrappers.forEach((tagWrapper) => {
      if (tagWrapper.classList.contains('tag-selected')) {
        unselectTagWrapper(tagWrapper);
      }
    });
  }
};

export const removeSelectedTagWrappers = (): void => {
  const sel = rangy.getSelection();
  if (sel && sel.rangeCount) {
    const range = sel.getRangeAt(0);
    const editorRoot = getSegmentRoot(range.endContainer);
    if (!editorRoot) return;
    const allTagWrappers = tagWrappersUnder(editorRoot) as HTMLElement[];
    allTagWrappers.forEach((tagWrapper) => {
      if (tagWrapper.classList.contains('tag-selected')) {
        tagWrapper.parentElement?.removeChild(tagWrapper);
      }
    });
  }
};
