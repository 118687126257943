import { useState } from 'react';

type UseLocalStorage = {
  <T>(key: string, initialValue: T): {
    storedValue: T;
    setStoredValue: (value: T) => void;
  };
};

const rootKey = 'cistate-translation-editor';

export const useLocalStorage: UseLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue_] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(`${rootKey}/${key}`);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setStoredValue = (value: unknown) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue_(valueToStore);
      // Save to local storage
      window.localStorage.setItem(`${rootKey}/${key}`, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return { storedValue, setStoredValue };
};
