import React from 'react';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';

export type Props = Omit<CheckboxProps, 'ref'> & {
  id: string;
  labelText: string;
  helpText?: string;
  wrapperClassName?: string;
};

export const CheckField: React.FC<Props> = ({
  id,
  labelText,
  helpText,
  wrapperClassName,
  ...otherProps
}) => {
  let helpElement;
  if (helpText) {
    helpElement = <small>{helpText}</small>;
  } else {
    helpElement = <React.Fragment />;
  }

  const className = wrapperClassName ? `p-field-checkbox ${wrapperClassName}` : 'p-field-checkbox';

  return (
    <div className={className}>
      <Checkbox inputId={id} {...otherProps} />
      {labelText && <label htmlFor={id}>{labelText}</label>}
      {helpElement}
    </div>
  );
};
