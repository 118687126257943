import { HtmlToXml, XmlToHtml, TagDisplayMode } from '../../libs/types';
import { NEWLINE, ZWSP } from './constants';
import { createTagMarker, createNewlineMarker } from './editor-util';
import { escapeHtmlEntities, sanitize } from './string-util';
import { MarkerCategory, Tag, TagCategory, TagCategoryType } from './types';

export const htmlToXliff: HtmlToXml = (html) => {
  const div = document.createElement('div');
  div.innerHTML = sanitize(html);
  // テキストノードは<>&をエスケープ、newline-markerは改行文字、tag-wrapperはタグ文字列に変換する
  const xliff = [...div.childNodes]
    .map((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return escapeHtmlEntities(node.textContent ?? '');
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const elem = node as HTMLElement;
        if (!elem.dataset) {
          return '';
        }
        if (elem.dataset.type === MarkerCategory.NewlineMarker) {
          return NEWLINE;
        }
        if (elem.dataset.type !== MarkerCategory.TagWrapper) {
          return '';
        }
        const tag = elem.firstElementChild as HTMLElement;
        if (!tag) {
          return '';
        }
        const tagContent = tag.dataset ? tag.dataset.content ?? '' : '';
        return tagContent;
      }
      return '';
    })
    .join('');
  return xliff;
};

const g_beginRegex = /<g\s+[^<>/]+>/;
const g_endRegex = /<\/g>/;
const x_regex = /<x\s+[^<>]+\/>/;
const bx_regex = /<bx\s+[^<>]+\/>/;
const ex_regex = /<ex\s+[^<>]+\/>/;
const ph_regex = /<ph.*?id=".*?".*?>.*?<\/ph>/;
const it_regex = /<it.*?id=".*?".*?>.*?<\/it>/;
const bpt_regex = /<bpt.*?id=".*?".*?>.*?<\/bpt>/;
const ept_regex = /<ept.*?id=".*?".*?>.*?<\/ept>/;
const splitRegex = /(<[be]x[^<>]*>)|(<\/?[gx]\s?[^<>]*>)|(\n)|(<ph.*?id=".*?".*?>.*?<\/ph>)|(<it.*?id=".*?".*?>.*?<\/it>)|(<[be]pt.*?id=".*?".*?>.*?<\/[be]pt>)/g;

const createTag = (id: string, content: string, type: TagCategoryType) => {
  return {
    id,
    content,
    type,
  };
};

const extractTagId = (tagString: string): string | null => {
  const id = tagString.match(/<.+? id="(.*?)"/);
  if (!id) return null;
  return id[1];
};

export const xliffToHtml: XmlToHtml = (
  xliff: string,
  {
    prependZWSP = true,
    includeTags = true,
    includeNewlines = true,
    tagDisplayMode = TagDisplayMode.ShowTagId,
  } = {}
): string => {
  const sanitizedXliff = sanitize(xliff, { keepNewLines: includeNewlines });
  const substrings = sanitizedXliff.split(splitRegex).filter((s) => s);

  // エディタ上に表示するタグIDはxliffインラインタグのid属性値を使用する
  // 何らかの理由でid属性値が得られなかった場合、"cte-1"のようなタグIDを使用する
  const tagStack: Tag[] = []; // 開始タグを保存しておくためのスタック。閉じタグのID付与に使用する
  const instantTagPrefix = 'cte-';
  let instantTagId = 0;
  const runs = substrings.map((substr, idx) => {
    if (
      ph_regex.test(substr) ||
      it_regex.test(substr) ||
      bpt_regex.test(substr) ||
      ept_regex.test(substr)
    ) {
      if (!includeTags) return '';
      const tagId = extractTagId(substr) ?? `${instantTagPrefix}${++instantTagId}`;
      const tag = createTag(tagId, substr, TagCategory.Empty);
      const marker = createTagMarker(tag, tagDisplayMode);
      return `${marker.outerHTML}`;
    }
    if (g_beginRegex.test(substr)) {
      if (!includeTags) return '';
      const tagId = extractTagId(substr) ?? `${instantTagPrefix}${++instantTagId}`;
      const tag = createTag(tagId, substr, TagCategory.Begin);
      tagStack.push(tag);
      const marker = createTagMarker(tag, tagDisplayMode);
      return `${marker.outerHTML}`;
    }
    if (g_endRegex.test(substr)) {
      if (!includeTags) return '';
      const beginTag = tagStack.pop();
      const tagId = beginTag?.id ?? `${instantTagPrefix}${++instantTagId}`;
      const tag = createTag(tagId, substr, TagCategory.End);
      const marker = createTagMarker(tag, tagDisplayMode);
      return `${marker.outerHTML}`;
    }
    if (x_regex.test(substr) || bx_regex.test(substr) || ex_regex.test(substr)) {
      if (!includeTags) return '';
      const tagId = extractTagId(substr) ?? `${instantTagPrefix}${++instantTagId}`;
      const tag = createTag(tagId, substr, TagCategory.Empty);
      const marker = createTagMarker(tag, tagDisplayMode);
      return `${marker.outerHTML}`;
    }
    if (substr === '\n') {
      if (!includeNewlines) return '';
      const marker = createNewlineMarker();
      return `${marker.outerHTML}`;
    }
    return substr;
  });
  const html = runs.join('').replace(/\u200B{2,}/g, '\u200B');
  const zwsp = prependZWSP ? ZWSP : '';
  return `${zwsp}${html}`;
};

export const escapeXliff = (xliff: string): string => {
  const sanitizedXliff = sanitize(xliff);
  const substrings = sanitizedXliff.split(splitRegex).filter((s) => s);
  const escapedXliff = substrings
    .map((substr) => {
      if (
        ph_regex.test(substr) ||
        it_regex.test(substr) ||
        bpt_regex.test(substr) ||
        ept_regex.test(substr) ||
        g_beginRegex.test(substr) ||
        g_endRegex.test(substr) ||
        x_regex.test(substr) ||
        bx_regex.test(substr) ||
        ex_regex.test(substr)
      ) {
        return substr;
      }
      return escapeHtmlEntities(substr);
    })
    .join('');
  return escapedXliff;
};

export const unxliffizePh = (xliff: string): string => {
  const masked = xliff.replace(/<ph.*?id="(.*?)".*?>.*?<\/ph>/g, function (_, id) {
    return '{{{' + id + '}}}';
  });

  return masked;
};
