import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { escapeRegExp } from 'lodash';

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Messages } from '../libs/messages';
import { XliffJob, XliffUnit } from '../libs/types';

import './DocumentSearchPanel.css';
import { useCallback } from 'react';
import { extractMaskId, maskRegex } from '../Segment/libs/string-util';
import { useXliffUnits } from '../libs/hooks/useXliffUnits';
import { CheckField } from '../CheckField';
import { isFirefox } from '../libs/platform-util';
import { moveToSegmentById } from '../Segment/libs/editor-util';
import { InputText } from 'primereact/inputtext';

const toRuns = (maskedString: string, highlightRegExp?: RegExp) => {
  const runs = maskedString
    .split(maskRegex)
    .map((substr, idx) => {
      if (maskRegex.test(substr)) {
        return (
          <span className="tag tag-empty" key={idx}>
            {extractMaskId(substr)}
          </span>
        );
      }
      if (highlightRegExp) {
        return substr.split(highlightRegExp).map((substr2, idx2) => {
          if (highlightRegExp.test(substr2)) {
            return (
              <span key={`${idx}-${idx2}`} className="document-search-highlighted">
                {substr2}
              </span>
            );
          }
          return substr2;
        });
      }
      return substr;
    })
    .flat();
  return runs;
};

const ProgressSpinnerRow = ({
  loadingMessage,
  isLoading,
}: {
  loadingMessage: string;
  isLoading: boolean;
}) => {
  return (
    <tr style={isLoading ? { visibility: 'visible' } : { display: 'none' }}>
      <td className="loading-msg-cell" colSpan={2}>
        <ProgressSpinner />
        {loadingMessage}
      </td>
    </tr>
  );
};

export type ContainerProps = {
  isExpanded: boolean;
  fetchNeighboringUnits: (id?: string, position?: number) => Promise<boolean>;
  isFetchingNeighboringUnits: boolean;
  job: XliffJob;
};

export type Props = Omit<ContainerProps, 'searchUnits' | 'job'> & {
  transUnits: XliffUnit[];
  setFormData: Dispatch<SetStateAction<FormData>>;
  formData: FormData;
  handleInitialSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleClearButtonClick: () => void;
  highlightRegExp?: { src?: RegExp; tgt?: RegExp };

  prevRef: React.MutableRefObject<HTMLTableRowElement | null>;
  nextRef: React.MutableRefObject<HTMLTableRowElement | null>;
  isLoadingPrevTransUnits: boolean;
  isLoadingNextTransUnits: boolean;
  isLoadingTotalCount: boolean;
  isInitialized: boolean;
  totalUnitsCount: number;
};

type FormData = {
  srcText: string;
  tgtText: string;
  matchCase: boolean;
};

export const Component: React.FC<Props> = ({
  transUnits,
  setFormData,
  formData,
  handleInitialSubmit,
  isExpanded,
  highlightRegExp,
  fetchNeighboringUnits,
  isFetchingNeighboringUnits,
  handleClearButtonClick,
  prevRef,
  nextRef,
  isLoadingPrevTransUnits,
  isLoadingNextTransUnits,
  isLoadingTotalCount,
  isInitialized,
  totalUnitsCount,
}) => {
  const [srcTextField, setSrcTextField] = useState<HTMLInputElement>();
  const [tgtTextField, setTgtTextField] = useState<HTMLInputElement>();
  const srcTextFieldRef = useCallback((node) => setSrcTextField(node), []);
  const tgtTextFieldRef = useCallback((node) => setTgtTextField(node), []);
  const searchFormRef = useRef<HTMLFormElement>(null);
  const [selectedUnit, setSelectedUnit] = useState<XliffUnit>();

  useEffect(() => {
    // パネルが開いたらテキストフィールドにフォーカス
    if (srcTextField && isExpanded) {
      srcTextField.focus();
    }
  }, [isExpanded, srcTextField]);

  const handleUnitEntryClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!transUnits) return;
    const elem = e.target as HTMLElement;
    const tmEntryId = elem.closest('tr')?.getAttribute('data-id');
    if (tmEntryId) {
      const tu = transUnits.find((tu) => tu._id === tmEntryId);
      setSelectedUnit(tu);
      if (tu) {
        fetchNeighboringUnits(tu._id).then((success) => {
          if (success) {
            moveToSegmentById(tu._id);
          }
        });
      }
    }
  };

  return (
    <div
      style={isExpanded ? undefined : { display: 'none' }}
      className="document-search-panel panel-base"
    >
      <form
        ref={searchFormRef}
        onSubmit={handleInitialSubmit}
        className="document-search-form panel-base"
      >
        <div className="p-fluid p-formgrid p-grid document-search-basic-search">
          <CheckField
            id="document-search-match-case"
            labelText="Match case"
            wrapperClassName="p-col-12"
            onChange={(e) => setFormData((prev) => ({ ...prev, matchCase: e.checked }))}
            checked={formData.matchCase}
          />
          <div className="p-inputgroup p-col-12 p-field">
            <InputText
              placeholder="Look in source"
              ref={srcTextFieldRef}
              id="document-search-text-src"
              value={formData.srcText ?? ''}
              onChange={(e) => setFormData((prev) => ({ ...prev, srcText: e.target.value }))}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-secondary"
              tooltip="Clear search text"
              tooltipOptions={{ position: 'left' }}
              type="button"
              onClick={() => {
                setFormData((prev) => ({ ...prev, srcText: '' }));
                srcTextField?.focus();
              }}
            />
          </div>
          <div className="p-inputgroup p-col-12 p-field">
            <InputText
              placeholder="Look in target"
              ref={tgtTextFieldRef}
              id="document-search-text-tgt"
              value={formData.tgtText ?? ''}
              onChange={(e) => setFormData((prev) => ({ ...prev, tgtText: e.target.value }))}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-secondary"
              tooltip="Clear search text"
              tooltipOptions={{ position: 'left' }}
              type="button"
              onClick={() => {
                setFormData((prev) => ({ ...prev, tgtText: '' }));
                tgtTextField?.focus();
              }}
            />
          </div>
          <div className="p-col-12 p-d-flex">
            <Button
              type="submit"
              label="Search"
              disabled={(!formData.srcText && !formData.tgtText) || isFetchingNeighboringUnits}
            />
          </div>
        </div>

        {!isInitialized && !isLoadingNextTransUnits && !isLoadingPrevTransUnits && (
          <div className="document-search-results-count">{totalUnitsCount} results</div>
        )}
      </form>

      <div className="document-search-grid-wrapper">
        {/* 
          ローディング中: ProgressSpinnerとメッセージを表示
          transUnitsがnull/undefined: 空のdivを表示
          transUnitsが0件: メッセージを表示
          上記以外 (tmEntriesが1件以上): 検索結果をtableに表示
         */}
        {isInitialized ? (
          <div className="document-search-panel-progress-msg"></div>
        ) : !isLoadingNextTransUnits && !isLoadingPrevTransUnits && totalUnitsCount === 0 ? (
          <div className="document-search-panel-progress-msg">{Messages.NoMatchesFound}</div>
        ) : (
          <>
            <table className="document-search-grid">
              <tbody>
                <tr data-rowtype="load-marker-prev" ref={prevRef}></tr>
                <ProgressSpinnerRow
                  loadingMessage={Messages.LoadingPrevContent}
                  isLoading={isLoadingPrevTransUnits}
                />
                {transUnits.map((tu, index) => {
                  const selectedClass =
                    tu._id === selectedUnit?._id ? 'concordance-search-selected-tm-entry' : '';
                  const srcRuns = toRuns(tu.source.text, highlightRegExp?.src);
                  const tgtRuns = toRuns(tu.target.text, highlightRegExp?.tgt);

                  return (
                    <tr
                      key={tu._id}
                      className={selectedClass}
                      data-id={tu._id}
                      onClick={handleUnitEntryClick}
                      data-is-locked={tu.isLocked}
                    >
                      {/* <th className={`document-search-no`}>{tu.position}</th> */}
                      <td className="document-search-src-text">{srcRuns}</td>
                      <td className="document-search-tgt-text">{tgtRuns}</td>
                    </tr>
                  );
                })}
                <ProgressSpinnerRow
                  loadingMessage={Messages.LoadingNextContent}
                  isLoading={isLoadingNextTransUnits}
                />
                <tr data-rowtype="load-marker-next" ref={nextRef}></tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

const Container: React.FC<ContainerProps> = ({
  job,
  fetchNeighboringUnits,
  isFetchingNeighboringUnits,
  isExpanded,
}) => {
  const [isInitialized, setIsInitialized] = useState(true);
  const prevRef = useRef<HTMLTableRowElement | null>(null);
  const nextRef = useRef<HTMLTableRowElement | null>(null);
  const firstRow = useRef<HTMLTableRowElement | null>(null);
  const lastRow = useRef<HTMLTableRowElement | null>(null);
  const [formData, setFormData] = useState<FormData>({
    srcText: '',
    tgtText: '',
    matchCase: false,
  });
  const [formDataSnapshot, setFormdataSnapshot] = useState<FormData>({ ...formData });

  const [highlightRegExp, setHighlightRegExp] = useState<{ src?: RegExp; tgt?: RegExp }>({
    src: undefined,
    tgt: undefined,
  });
  const {
    fetchNext,
    fetchPrev,
    transUnits,
    isLoadingPrev,
    isLoadingNext,
    isLoadingTotalCount,
    hasPrev,
    hasNext,
    resetFetchData,
    fetchCount,
    totalUnitsCount,
  } = useXliffUnits({ job, limit: 100 });

  const handleInitialSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      console.log(JSON.stringify(formData, null, 2));
      event.preventDefault();
      setIsInitialized(true);
      setFormdataSnapshot({ ...formData });
      if (!formData.srcText && !formData.tgtText) {
        return;
      }
      lastRow.current = nextRef.current?.previousElementSibling
        ?.previousElementSibling as HTMLTableRowElement;
      resetFetchData();
      const payload = {
        srcMasked: formData.srcText || undefined,
        tgtMasked: formData.tgtText || undefined,
        ignoreCase: !formData.matchCase,
      };
      fetchNext(payload);
      fetchCount(payload);
      setIsInitialized(false);
    },
    [fetchCount, fetchNext, formData, resetFetchData]
  );

  useEffect(() => {
    if (transUnits.length && (formDataSnapshot.srcText || formDataSnapshot.tgtText)) {
      const highlightFlags = `g${formDataSnapshot.matchCase ? '' : 'i'}`;
      const regexSrc = formDataSnapshot.srcText
        ? new RegExp(`(${escapeRegExp(formDataSnapshot.srcText)})`, highlightFlags)
        : undefined;

      const regexTgt = formDataSnapshot.tgtText
        ? new RegExp(`(${escapeRegExp(formDataSnapshot.tgtText)})`, highlightFlags)
        : undefined;

      setHighlightRegExp({
        src: regexSrc,
        tgt: regexTgt,
      });
    } else {
      setHighlightRegExp({
        src: undefined,
        tgt: undefined,
      });
    }
  }, [
    formDataSnapshot.matchCase,
    formDataSnapshot.srcText,
    formDataSnapshot.tgtText,
    transUnits.length,
  ]);

  useEffect(() => {
    if (!isLoadingPrev) {
      // previous transunitsの取得完了後、取得前の最初の行にスクロール
      firstRow.current?.scrollIntoView(true);
    }
  }, [isLoadingPrev]);

  useEffect(() => {
    // Firefoxの場合のみnext transunitsの取得後、スクロール調整が必要
    if (!isLoadingNext && transUnits && transUnits.length > 0 && isFirefox()) {
      lastRow.current?.scrollIntoView(false);
    }
  }, [isLoadingNext, transUnits]);

  const loadPrev: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasPrev && !isLoadingPrev && !isLoadingNext) {
        // previous transunits取得前に、現在の最初の行を取得しておき、後でこの行にスクロールする
        firstRow.current = prevRef.current?.nextElementSibling
          ?.nextElementSibling as HTMLTableRowElement;
        const payload = {
          srcMasked: formDataSnapshot.srcText || undefined,
          tgtMasked: formDataSnapshot.tgtText || undefined,
          ignoreCase: !formDataSnapshot.matchCase,
        };
        fetchPrev(payload);
      }
    },
    [
      fetchPrev,
      formDataSnapshot.matchCase,
      formDataSnapshot.srcText,
      formDataSnapshot.tgtText,
      hasPrev,
      isLoadingNext,
      isLoadingPrev,
    ]
  );

  const loadNext: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasNext && !isLoadingPrev && !isLoadingNext) {
        lastRow.current = nextRef.current?.previousElementSibling
          ?.previousElementSibling as HTMLTableRowElement;
        const payload = {
          srcMasked: formDataSnapshot.srcText || undefined,
          tgtMasked: formDataSnapshot.tgtText || undefined,
          ignoreCase: !formDataSnapshot.matchCase,
        };
        fetchNext(payload);
      }
    },
    [
      fetchNext,
      formDataSnapshot.matchCase,
      formDataSnapshot.srcText,
      formDataSnapshot.tgtText,
      hasNext,
      isLoadingNext,
      isLoadingPrev,
    ]
  );

  useEffect(() => {
    if (!prevRef.current) return;
    const observingEl = prevRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(loadPrev, options);
    observer.observe(observingEl);

    return () => {
      observer.unobserve(observingEl);
    };
  }, [loadPrev]);

  useEffect(() => {
    if (!nextRef.current) return;
    const observingEl = nextRef.current;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(loadNext, options);
    observer.observe(observingEl);

    return () => {
      observer.unobserve(observingEl);
    };
  }, [loadNext]);

  const handleClearButtonClick = () => {
    resetFetchData();
    setFormData((prev) => ({ ...prev, srcText: '', tgtText: '' }));
    setIsInitialized(true);
  };

  return (
    <Component
      transUnits={transUnits}
      setFormData={setFormData}
      formData={formData}
      handleInitialSubmit={handleInitialSubmit}
      fetchNeighboringUnits={fetchNeighboringUnits}
      isFetchingNeighboringUnits={isFetchingNeighboringUnits}
      isExpanded={isExpanded}
      handleClearButtonClick={handleClearButtonClick}
      highlightRegExp={highlightRegExp}
      prevRef={prevRef}
      nextRef={nextRef}
      isLoadingPrevTransUnits={isLoadingPrev}
      isLoadingNextTransUnits={isLoadingNext}
      isLoadingTotalCount={isLoadingTotalCount}
      totalUnitsCount={totalUnitsCount}
      isInitialized={isInitialized}
    />
  );
};

Container.displayName = 'DocumentSearchPanel';
export default Container;
