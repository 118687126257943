/**
 * 文字列中の ">" と "<" を実体参照に変換します。またXMLとして使用できない実体参照をエスケープします。 (例: \&ldquo; → \&amp;ldquo;)
 * @param text - 文字列
 * @returns 変換後の文字列
 */
export const escapeHtmlEntities = (text: string): string => {
  const escapedChars: [RegExp, string][] = [
    // XMLとしてvalidな実体参照以外はエスケープ (例: &ldquo; → &amp;ldquo;)
    [/&(?!(amp|gt|lt|apos|quot)|(#x[0-9a-fA-F]+)|(#[0-9]+);)/g, '&amp;'],
    // [/&(?![^&]+;)/g, '&amp;'],

    [/>/g, '&gt;'],
    [/</g, '&lt;'],
  ];
  for (const [search, repl] of escapedChars) {
    text = text.replace(search, repl);
  }
  return text;
};

/**
 * 文字列中のシングルクォート/ダブルクォートを表す実体参照を文字に戻します。(例: \&apos; → ')
 * @param text - 文字列
 * @returns 変換後の文字列
 */
export const decodeQuotes = (text: string): string => {
  return text.replace(/&(apos|#39|#x0027|#x27);/g, "'").replace(/&(quot|#34|#x0022|#x22);/g, '"');
};

/**
 * keepNewLinesがtrueの場合、文字列中の\r\nと\rを\nに正規化します。keepNewLinesがfalseの場合、\r,\n,\tを削除します。また、ZWSP (\u200B、\uFEFF) を削除します。
 * @param text - 文字列
 * @returns 変換後の文字列
 */
export const sanitize = (text: string, { keepNewLines = false } = {}): string => {
  if (!text) return '';
  if (keepNewLines) {
    text = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
  } else {
    text = text.replace(/[\r\n\t]/g, '');
  }
  text = text.replace(/[\u200B\uFEFF]/g, '');

  return text;
};

/**
 * 文字列を16進数表記に変換します。
 * @param text - 文字列
 * @returns 変換後の文字列
 */
export const hexCodePoint = (text: string): string => {
  if (text === null || text === undefined) return '';
  const codepoints = [...text].map((char) => {
    const hex = char.codePointAt(0)?.toString(16);
    if (hex === undefined) return '';
    const result = '\\u' + '0000'.substring(0, 4 - hex.length) + hex;
    return result;
  });
  return codepoints.join('');
};

export const maskRegex = /({{{[^{}]+}}})/;

/**
 * {{{1}}} から 1 を抜き出します。
 * @param maskedString - 文字列
 * @returns 変換後の文字列
 */
export const extractMaskId = (maskedString: string): string => {
  return maskedString.replace(/{{{|}}}/g, '');
};

/**
 * {{{1}}}を{1}に簡略化します。DIFF表示用
 * @param maskedString マスクされたXLIFF/TMX文字列
 * @returns マスクが簡略化されたXLIFF/TMX文字列
 */
export const simplifyMasks = (maskedString: string): string => {
  return maskedString.replace(/{{{([^{}]+)}}}/g, '{$1}');
};
