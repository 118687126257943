type Values<T> = T[keyof T];

export const TagCategory = {
  Begin: 'tag-begin',
  End: 'tag-end',
  Empty: 'tag-empty',
} as const;

export type TagCategoryType = Values<typeof TagCategory>;

export type Tag = { id: string; content: string; type: TagCategoryType };

// TODO: BeginTag/EndTag/EmptyTag削除できるか確認
export const MarkerCategory = {
  BeginTag: TagCategory.Begin,
  EndTag: TagCategory.End,
  EmptyTag: TagCategory.Empty,
  TagWrapper: 'tag-wrapper',
  NewlineMarker: 'newline-marker',
} as const;

export type MarkerCategoryType = Values<typeof MarkerCategory>;

export type Marker = { content: string; type: MarkerCategoryType };
