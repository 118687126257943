import { useCallback, useState } from 'react';

export type PanelVisibility = {
  tmLookupPanel: {
    isActive: boolean;
    isExpanded: boolean;
  };
  concordanceSearchPanel: {
    isActive: boolean;
    isExpanded: boolean;
  };
  documentSearchPanel: {
    isActive: boolean;
    isExpanded: boolean;
  };
};

type UsePanelVisibility = {
  (): {
    panelVisibility: PanelVisibility;
    setIsExpanded: (panelName: keyof PanelVisibility, isExpanded: boolean) => void;
    toggleIsExpanded: (panelName: keyof PanelVisibility) => void;
    getActivePanelName: () => keyof PanelVisibility | undefined;
  };
};

export const usePanelVisibility: UsePanelVisibility = () => {
  const [panelVisibility, setPanelVisibility] = useState<PanelVisibility>({
    tmLookupPanel: {
      isActive: true,
      isExpanded: false,
    },
    concordanceSearchPanel: {
      isActive: false,
      isExpanded: false,
    },
    documentSearchPanel: {
      isActive: false,
      isExpanded: false,
    },
  });

  const setIsExpanded = useCallback((panelName: keyof PanelVisibility, isExpanded: boolean) => {
    setPanelVisibility((prev) => {
      const prev_ = Object.keys(prev).reduce((acc, pn) => {
        if (pn === panelName) {
          return acc;
        }
        return {
          ...acc,
          [pn]: {
            isActive: false,
            isExpanded: false,
          },
        };
      }, prev);
      return {
        ...prev_,
        [panelName]: {
          isActive: true,
          isExpanded,
        },
      };
    });
  }, []);

  const toggleIsExpanded = useCallback((panelName: keyof PanelVisibility) => {
    setPanelVisibility((prev) => {
      const prev_ = Object.keys(prev).reduce((acc, pn) => {
        if (pn === panelName) {
          return acc;
        }
        return {
          ...acc,
          [pn]: {
            isActive: false,
            isExpanded: false,
          },
        };
      }, prev);
      return {
        ...prev_,
        [panelName]: {
          isActive: true,
          isExpanded: !prev_[panelName].isExpanded,
        },
      };
    });
  }, []);

  const getActivePanelName = useCallback(() => {
    const activePanelName = (Object.keys(panelVisibility) as (keyof PanelVisibility)[]).find(
      (pn) => panelVisibility[pn].isActive
    );
    return activePanelName;
  }, [panelVisibility]);

  return {
    panelVisibility,
    setIsExpanded,
    toggleIsExpanded,
    getActivePanelName,
  };
};
