import { AxiosPromise, AxiosRequestConfig } from 'axios';
import useAxios, { RefetchOptions } from 'axios-hooks';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { CategoryMapping } from '../types';

type UseCategoryOptions = {
  ({
    ownerGroupId,
    srcLang,
    tgtLang,
    lazyLoad,
  }: {
    ownerGroupId?: string;
    srcLang?: string;
    tgtLang?: string;
    lazyLoad: boolean;
  }): {
    loadCategoryMappings: (
      config?: AxiosRequestConfig | undefined,
      options?: RefetchOptions | undefined
    ) => AxiosPromise<CategoryMapping[]>;
    findCategoryMappingByCode: (categoryCode: string) => CategoryMapping | undefined;
    findCategoryMappingByIndex: (index: string) => CategoryMapping | undefined;
    findCategoryLabelByCode: (categoryCode: string) => string;
    categoryOptions: { value: string; label: string }[];
    categoryMappings: CategoryMapping[];
    hasCategoryMapping: boolean;
  };
};

export const defaultCategoryOptions = [
  { label: 'No category', value: '' },
  { label: 'E', value: 'E' },
  { label: 'M', value: 'M' },
  { label: 'T', value: 'T' },
  { label: 'P', value: 'P' },
  { label: 'K', value: 'K' },
];

export const useCategoryOptions: UseCategoryOptions = ({
  ownerGroupId,
  srcLang,
  tgtLang,
  lazyLoad,
}) => {
  const [hasCategoryMapping, setHasCategoryMapping] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<{ label: string; value: string }[]>(
    defaultCategoryOptions
  );

  const [{ data: categoryMappings }, loadCategoryMappings] = useAxios<CategoryMapping[]>(
    {
      url: 'categoryMappings',
      method: 'get',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!lazyLoad) {
      loadCategoryMappings(undefined, { useCache: true });
    }
  }, [lazyLoad, loadCategoryMappings]);

  useEffect(() => {
    if (!categoryMappings || !ownerGroupId || !srcLang || !tgtLang) return;
    const found = categoryMappings.find(
      (cm) => cm.ownerGroupId === ownerGroupId && cm.srcLang === srcLang && cm.tgtLang === tgtLang
    );
    setHasCategoryMapping(Boolean(found));
    setCategoryOptions((prev) => {
      const options = [
        ...prev,
        ...categoryMappings.map((m) => ({ label: m.name, value: m.index })),
      ];
      return _.uniqBy(options, 'value');
    });
  }, [categoryMappings, ownerGroupId, srcLang, tgtLang]);

  const findCategoryMappingByCode = useCallback(
    (categoryCode: string) => {
      if (!categoryMappings || !categoryCode) return;
      const filteredCategoryMappings = categoryMappings
        .filter(
          (cm) =>
            cm.ownerGroupId === ownerGroupId && cm.srcLang === srcLang && cm.tgtLang === tgtLang
        )
        .flatMap((cm) =>
          cm.categoryCodes.map((code) => ({
            ...cm,
            _categoryCode: code,
          }))
        )
        .sort((a, b) => b._categoryCode.length - a._categoryCode.length);

      const foundCm = filteredCategoryMappings.find((cm) =>
        categoryCode.startsWith(cm._categoryCode)
      );
      return foundCm;
    },
    [categoryMappings, ownerGroupId, srcLang, tgtLang]
  );

  const findCategoryMappingByIndex = useCallback(
    (index: string) => {
      if (!categoryMappings || !index) return;
      const filteredCategoryMappings = categoryMappings.filter(
        (cm) => cm.ownerGroupId === ownerGroupId && cm.srcLang === srcLang && cm.tgtLang === tgtLang
      );

      return filteredCategoryMappings.find((cm) => cm.index === index);
    },
    [categoryMappings, ownerGroupId, srcLang, tgtLang]
  );

  const findCategoryLabelByCode = useCallback(
    (categoryCode: string) => {
      if (!categoryMappings || !categoryCode) return '';

      let shortCode: string | undefined;
      if (hasCategoryMapping) {
        const mapping = findCategoryMappingByCode(categoryCode);
        shortCode = mapping?.index;
      } else {
        shortCode = categoryCode.length === 1 ? categoryCode : categoryCode[0];
      }
      const option = categoryOptions.find((option) => option.value === shortCode);
      return option?.label ?? '';
    },
    [categoryMappings, categoryOptions, findCategoryMappingByCode, hasCategoryMapping]
  );

  return {
    loadCategoryMappings,
    findCategoryMappingByCode,
    findCategoryMappingByIndex,
    findCategoryLabelByCode,
    hasCategoryMapping,
    categoryOptions,
    categoryMappings: categoryMappings ?? [],
  };
};
