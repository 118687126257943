import { useCallback } from 'react';
import { createContext, useMemo, useReducer, useContext } from 'react';
import { CatResults } from '../types';

export const initialState: CatResults = {
  selectedTMEntry: null,
  selectedConcordanceSearchEntry: null,
};

export type Action = { type: 'UPDATE'; payload: Partial<CatResults> } | { type: 'RESET' };

export const reducer = (state: CatResults, action: Action): CatResults => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'RESET': {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export type UpdateCatResults = (payload: Partial<CatResults>) => void;
export type ResetCatResults = () => void;

type ContextType = {
  catResults: CatResults;
  updateCatResults: UpdateCatResults;
  resetCatResults: ResetCatResults;
};

export const CatResultsContext = createContext<ContextType | null>(null);

export const CatResultsContextProvider: React.FC = (props) => {
  const [catResults, dispatch] = useReducer(reducer, initialState);

  const updateCatResults: UpdateCatResults = useCallback(
    (payload) => dispatch({ type: 'UPDATE', payload }),
    []
  );
  const resetCatResults: ResetCatResults = useCallback(() => dispatch({ type: 'RESET' }), []);

  const value = useMemo(
    () => ({
      catResults,
      updateCatResults,
      resetCatResults,
    }),
    [catResults, resetCatResults, updateCatResults]
  );

  return <CatResultsContext.Provider value={value} {...props} />;
};

export const useCatResults = (): ContextType => {
  const context = useContext(CatResultsContext);

  if (typeof context === 'undefined') {
    throw new Error('useCatResults must be used within CatResultsContext');
  }

  return context as ContextType;
};
