import { ZWSP } from './constants';
import {
  insertAfter,
  textNodesUnder,
  isValidTagType,
  restoreSelection,
  saveCurrentSelection,
} from './editor-util';
import { moveTypedTextOutOfNewlineMarker } from './newline-marker-util';
import { moveTypedTextOutOfTagWrapper, tagWrappersUnder } from './tag-wrapper-util';

export class SegmentNormalizer {
  constructor(private tuid: string, private segment: HTMLElement) {}

  /**
   * セグメントのDOMツリーに対して一連の正規化処理を実行します。
   */
  run(): void {
    moveTypedTextOutOfTagWrapper();
    moveTypedTextOutOfNewlineMarker();
    const currentSelection = saveCurrentSelection(this.tuid);
    this.ensureFirstZWSPExists();
    // this.ensureTagWrappersHaveZWSP();  // moveTypedTextOutOfTagWrapperで対応
    this.ensureNoDuplicateZWSPsExist();
    this.removeEmptyTagWrappers();
    if (currentSelection) {
      restoreSelection(currentSelection);
    }
  }

  /**
   * セグメントの先頭にZWSPテキストノードが存在するか確認し、存在しない場合は挿入します。
   * 先頭以外の子テキストノードからZWSPを削除します。
   */
  ensureFirstZWSPExists(): void {
    const firstChild = this.segment.firstChild;
    if (!firstChild) {
      // 最初の子要素としてZWSPテキストノードを(なければ)追加する
      const n = document.createTextNode(ZWSP);
      this.segment.appendChild(n);
    } else {
      // 最初のテキストノードの先頭にZWSPがなければ付加する。
      if (firstChild.nodeType === Node.TEXT_NODE && !firstChild.textContent?.startsWith(ZWSP)) {
        firstChild.textContent = `${ZWSP}${firstChild.textContent || ''}`;
      } else {
        const n = document.createTextNode(ZWSP);
        this.segment.insertBefore(n, firstChild);
      }
    }

    const textNodes = [...this.segment.childNodes].filter((x) => x.nodeType === Node.TEXT_NODE);
    if (textNodes.length === 0) {
      return;
    }
    const [firstNode, ...otherNodes] = textNodes;
    if (firstNode.textContent && new RegExp(ZWSP).test(firstNode.textContent)) {
      // 最初テキストノードに関しては1文字目以外のZWSPを削除する
      firstNode.textContent = firstNode.textContent.replace(new RegExp(`(?!^${ZWSP})${ZWSP}`), '');
    }
    // 残りのテキストノードからはZWSPを削除する
    otherNodes.forEach((node) => {
      if (node.textContent?.includes(ZWSP)) {
        node.textContent = node.textContent.replace(new RegExp(ZWSP, 'g'), '');
      }
    });
  }

  /**
   * tag-wrapperの中にZWSPを追加します。
   */
  ensureTagWrappersHaveZWSP(): void {
    const tagWrappers = tagWrappersUnder(this.segment);
    for (const tagWrapper of tagWrappers) {
      const children = [...tagWrapper.childNodes] as HTMLElement[];
      const tagPos = children.findIndex((n) => isValidTagType(n.dataset?.type));
      if (tagPos === -1) {
        continue;
      }

      const tag = children[tagPos];
      // <span data-type"tag-begin/end/empty" />の直後にZWSPを追加
      if (tag.nextSibling?.nodeType !== Node.TEXT_NODE) {
        insertAfter(document.createTextNode(ZWSP), tag);
      } else if (
        tag.nextSibling?.nodeType === Node.TEXT_NODE &&
        tag.nextSibling.textContent !== ZWSP
      ) {
        tag.nextSibling.textContent = ZWSP;
      }
    }
  }

  /**
   * 連続するZWSPを1つに収束します。
   */
  ensureNoDuplicateZWSPsExist(): void {
    this.segment.normalize();
    const textNodes = textNodesUnder(this.segment);
    const regexp = new RegExp(`${ZWSP}{2,}`, 'g');
    for (const textNode of textNodes.filter((t) => t.nodeValue && regexp.test(t.nodeValue))) {
      textNode.nodeValue = textNode.nodeValue?.replace(regexp, ZWSP) || '';
    }
  }

  /**
   * 子ノードがない、または1つの子テキストノードしか持たないtag-wrapperを削除します。
   */
  removeEmptyTagWrappers(): void {
    const tagWrappers = tagWrappersUnder(this.segment);
    for (const tagWrapper of tagWrappers) {
      if (
        !tagWrapper.hasChildNodes() ||
        (tagWrapper.childNodes.length === 1 && tagWrapper.lastChild?.nodeType === Node.TEXT_NODE)
      ) {
        this.segment.removeChild(tagWrapper);
      }
    }
  }
}
