import { useEffect } from 'react';
import * as KeyCode from 'keycode-js';
import { XliffEditorCommandManager } from '../libs/xliff-editor-command-manager';
import { PanelVisibility } from './usePanelVisibility';
import { isMac } from '../libs/platform-util';

export const useKeyboardEventHandler = (
  commandManager: XliffEditorCommandManager | null,
  panelVisibility: PanelVisibility
): void => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (!commandManager) return;
      if (e.altKey) {
        switch (e.code) {
          case KeyCode.CODE_UP: {
            e.preventDefault();
            if (panelVisibility.tmLookupPanel.isExpanded) {
              commandManager.selectPrevMatch();
            } else if (panelVisibility.concordanceSearchPanel.isExpanded) {
              commandManager.selectPrevConcordanceSearchResult();
            }
            break;
          }
          case KeyCode.CODE_DOWN: {
            e.preventDefault();
            if (panelVisibility.tmLookupPanel.isExpanded) {
              commandManager.selectNextMatch();
            } else if (panelVisibility.concordanceSearchPanel.isExpanded) {
              commandManager.selectNextConcordanceSearchResult();
            }
            break;
          }
          case KeyCode.CODE_COMMA: {
            e.preventDefault();
            if (panelVisibility.tmLookupPanel.isExpanded) {
              commandManager.applySelectedMatch();
            } else if (panelVisibility.concordanceSearchPanel.isExpanded) {
              commandManager.applySelectedConcordanceSearchResult();
            }
            break;
          }
        }
      } else if (isMac() && e.metaKey) {
        switch (e.key) {
          case KeyCode.VALUE_F: {
            e.preventDefault();
            commandManager.toggleDocumentSearchPanelVisibility();
            break;
          }
          case KeyCode.VALUE_G: {
            e.preventDefault();
            commandManager.showGoToSegmentDialog();
            break;
          }
        }
      } else if (!isMac() && e.ctrlKey) {
        switch (e.key) {
          case KeyCode.VALUE_F: {
            e.preventDefault();
            commandManager.toggleDocumentSearchPanelVisibility();
            break;
          }
          case KeyCode.VALUE_G: {
            e.preventDefault();
            commandManager.showGoToSegmentDialog();
            break;
          }
        }
      } else {
        switch (e.key) {
          case KeyCode.VALUE_F1: {
            e.preventDefault();
            commandManager.toggleTagMode();
            break;
          }
          case KeyCode.VALUE_F2: {
            e.preventDefault();
            commandManager.toggleTMLookupPanelVisibility();
            break;
          }
          case KeyCode.VALUE_F3: {
            e.preventDefault();
            commandManager.toggleConcordanceSearchPanelVisibility();
            break;
          }
          case KeyCode.VALUE_F4: {
            e.preventDefault();
            commandManager.toggleDocumentSearchPanelVisibility();
            break;
          }
        }
      }
    };
    document.addEventListener('keydown', handler, false);

    return () => {
      document.removeEventListener('keydown', handler, false);
    };
  }, [
    commandManager,
    panelVisibility.concordanceSearchPanel.isExpanded,
    panelVisibility.tmLookupPanel.isExpanded,
  ]);
};
