import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { config, STAGE } from './config';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';

setUseWhatChange(process.env.NODE_ENV === 'development');
if (process.env.REACT_APP_MOCK) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

Amplify.configure({ Auth: config[STAGE].Auth });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
