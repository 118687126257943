import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

export type Props = {
  appVersion: string;
  onHide: () => void;
};

const Component: React.FC<Props> = ({ appVersion, onHide }) => {
  const renderFooter = () => {
    return (
      <div>
        <Button label="OK" onClick={() => onHide()} autoFocus />
      </div>
    );
  };

  return (
    <Dialog
      header="Release notes"
      visible={true}
      style={{ width: '50vw' }}
      footer={renderFooter()}
      onHide={onHide}
      dismissableMask
    >
      <div>
        <h3>Version {appVersion}</h3>
        <hr />
        <List>
          <li>
            New feature: From <b>Navigation</b> &gt; <b>Go to segment...</b>, you can now jump to a
            particular segment by number.
          </li>
          <li>
            New feature: From <b>View</b> &gt; <b>Show Document Search panel</b>, you can now search
            for segments matching a specified text through the current document.{' '}
          </li>
        </List>

        <h3>Version 2.0.10</h3>
        <hr />
        <List>
          <li>Removes newline and tab characters on paste.</li>
        </List>

        <h3>Version 2.0.8</h3>
        <hr />
        <List>
          <li>Added support for category mapping.</li>
        </List>

        <h3>Version 2.0.7</h3>
        <hr />
        <List>
          <li>
            Updated to reference unit-based category/brand codes instead of job-based category/brand
            codes for TR jobs.
          </li>
        </List>

        <h3>Version 2.0.6</h3>
        <hr />
        <List>
          <li>
            Fixed a bug that occasionally caused the translation not to be saved in DB when a
            segment was confirmed.
          </li>
          <li>
            Fixed a bug that caused the category field in the Concordance Search panel to reset to
            the initial value when "X" button was clicked.
          </li>
        </List>

        <h3>Version 2.0.5</h3>
        <hr />
        <List>
          <li>
            Fixed a bug that caused the text cursor to move to the end of the segment when a
            Japanese character was typed after a tag.
          </li>
        </List>

        <h3>Version 2.0.4</h3>
        <hr />
        <List>
          <li>Fixed a bug that caused nontranslatable units to be locked for FA jobs.</li>
        </List>

        <h3>Version 2.0.3</h3>
        <hr />
        <List>
          <li>Fixed a bug that allowed unintentional editing in source segment when IME is on.</li>
        </List>

        <h3>Version 2.0.2</h3>
        <hr />
        <List>
          <li>
            Release notes - Lists updates that have been implemented in the previous versions.
          </li>
        </List>

        <h3>Version 2.0.1</h3>
        <hr />
        <List>
          <li>Optimized application menubar height and font size.</li>
          <li>Replaced the SIGNOUT button with a dropdown menu.</li>
        </List>

        <h3>Version 2.0.0</h3>
        <hr />
        <List>
          <li>
            TM Matches Panel - Looks up TM entries that match the source text of the active
            translation unit.
          </li>
          <li>
            Concordance Search Panel - Looks up TM entries that match the search criteria specified.
          </li>
          <li>
            Status bar - Shows the information of the job, such as the number of units, anken ID,
            category, and name.
          </li>
          <li>Series and Item columns added in the translation grid</li>
          <li>
            Green background effect shows up on the active translation unit when the target text has
            been updated.
          </li>
          <li>Overall visual optimization</li>
        </List>
      </div>
    </Dialog>
  );
};

const List = styled.ul`
  line-height: 1.5rem;
`;

Component.displayName = 'ReleaseNotes';
export default Component;
