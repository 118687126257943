import React, { useEffect, useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import PrimeReact from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import {
  EditorOptionsContextProvider,
  initialState as initialEditorOptions,
} from './components/libs/hooks/useEditorOptions';
import { AxiosInstanceProvider, useAxiosInstance } from './libs/useAxiosInstance';
import { EditorEventContextProvider } from './components/libs/hooks/useEditorEvent';
import { GroupDefinitionsContextProvider } from './components/libs/hooks/useGroupDefinitions';
import { FieldDefinitionsContextProvider } from './components/libs/hooks/useFieldDefinitions';
import { fieldDefinitions } from './field-defs/xliff';
import { SearchConditionsContextProvider } from './components/libs/hooks/useSearchConditions';
import { XliffUnitSelectionContextProvider } from './components/libs/hooks/useXliffUnitSelection';
import useAxios from 'axios-hooks';
import { Auth } from 'aws-amplify';
import { Routes } from './routes/Routes';
import { XliffJob } from './components/libs/types';
import { LoginUser, LoginUserProvider } from './libs/useLoginUser';
import { CatResultsContextProvider } from './components/libs/hooks/useCatResults';

const App: React.FC = () => {
  PrimeReact.ripple = true;
  const axios = useAxiosInstance();

  const [, getLoginUser] = useAxios<LoginUser>({
    url: `whoami`,
  });

  const [loginUser, setLoginUser] = useState<LoginUser | null>(null);
  const [job, setJob] = useState<XliffJob | null | undefined>(null);

  useEffect(() => {
    const whoAmI = async () => {
      const res = await getLoginUser();
      setLoginUser(res.data);
    };
    whoAmI();
  }, [getLoginUser]);

  useEffect(() => {
    const shouldLogOut = () => {
      if (!loginUser) return false;
      // unitsPerXliffが設定されていないprojectの場合は強制サインアウト
      if (job && !job.project.unitsPerXliff) return true;
      // vendorユーザーは強制サインアウト
      return loginUser.organizationType === 'vendor';
    };
    const forceLogOut = async () => {
      if (shouldLogOut()) {
        await Auth.signOut();
        setLoginUser(null);
        window.location.reload();
      }
    };
    forceLogOut();
  }, [job, loginUser]);

  const logOut = async () => {
    await Auth.signOut();
    setLoginUser(null);
    window.location.reload();
  };

  if (!loginUser) return null;

  return (
    <LoginUserProvider value={loginUser}>
      <AxiosInstanceProvider value={axios}>
        <EditorOptionsContextProvider
          intialEditorOptions={{ ...initialEditorOptions, isSourceEditable: false }}
        >
          <EditorEventContextProvider>
            <GroupDefinitionsContextProvider initialGroupDefinitions={[]}>
              <FieldDefinitionsContextProvider initialFieldDefinitions={fieldDefinitions}>
                <SearchConditionsContextProvider>
                  <XliffUnitSelectionContextProvider>
                    <CatResultsContextProvider>
                      <div className="App">
                        <Routes onInitializeJob={setJob} onLogOut={logOut} />
                      </div>
                    </CatResultsContextProvider>
                  </XliffUnitSelectionContextProvider>
                </SearchConditionsContextProvider>
              </FieldDefinitionsContextProvider>
            </GroupDefinitionsContextProvider>
          </EditorEventContextProvider>
        </EditorOptionsContextProvider>
      </AxiosInstanceProvider>
    </LoginUserProvider>
  );
};

export default withAuthenticator(App);
