import { createContext, useContext } from 'react';
import { XliffEditorCommandManager } from '../xliff-editor-command-manager';

const xliffCommandManager = createContext<XliffEditorCommandManager | null>(null);

export const XliffEditorCommandManagerProvider = xliffCommandManager.Provider;

export const XliffEditorCommandManagerConsumer = xliffCommandManager.Consumer;

export const useXliffEditorCommandManager = (): XliffEditorCommandManager | null => {
  return useContext(xliffCommandManager);
};
