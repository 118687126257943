import React from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';

export type Props = DropdownProps & {
  id: string;
  placeholder: string;
  usePlaceholderAsLabel?: boolean;
  helpText?: string;
  helpTextClassName?: string;
  pFieldClassName?: string;
};

export const ComboBox: React.FC<Props> = ({
  id,
  placeholder,
  helpText,
  helpTextClassName = '',
  usePlaceholderAsLabel,
  pFieldClassName = '',
  ...otherProps
}) => {
  let helpElement;
  if (helpText) {
    helpElement = <small className={`${helpTextClassName}`}>{helpText}</small>;
  } else {
    helpElement = <React.Fragment />;
  }

  if (usePlaceholderAsLabel) {
    return (
      <div className={`p-field ${pFieldClassName}`}>
        <label htmlFor={id}>{placeholder}</label>
        <Dropdown id={id} {...otherProps} />
        {helpElement}
      </div>
    );
  }
  return (
    <div className={`p-field ${pFieldClassName}`}>
      <Dropdown id={id} placeholder={placeholder} {...otherProps} />
      {helpElement}
    </div>
  );
};
